import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { CameraEpics, cameraReducer } from "./controller/camera.slice";
import { farmReducer } from "./controller/farm.slice";
import { guidelineReducer } from "./controller/guideline.slice";
import { LoginEpics, loginReducer } from "./controller/login.slice";

// import { FolioEpics, folioReducer } from "./controller/cashier/folio/folio.slice";
// import { roomRackReducer } from "./controller/frontdesk/roomrack.slice";

// import { HotelConfigEpic, hotelConfigReducer } from "./controller/hotelconfig.slice";
// import { ReservationEpics, rsvnReducer } from "./controller/reservation.slice";
// import { TraceEpics, traceReducer } from "./controller/trace.slice";
// import { hotelReducer, HotelEpics } from "./controller/hotel.slice";
const rootReducer = combineReducers({
    // frontdesk: frontdeskReducer,
    // booking: bookingReducer,
    login: loginReducer,
    guideline: guidelineReducer,
    camera: cameraReducer,
    farm: farmReducer
});

export const rootEpic = combineEpics(
    ...LoginEpics,
    ...CameraEpics
    // ...BookingEpics,
    // //...ReservationEpics,
    // ...HotelEpics,
    // ...CompanyEpics,
    // //...HotelConfigEpic,
    // ...RoomPlanEpics,
    // ...FrontDeskEpics,
    // //...FolioEpics,
    // //...TraceEpics
);
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;