/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const createFoodConsume = (data: any) => {
    return fetcher.post(`${CFG_BASE_URL}/foodConsume`, data);
}

export const getList = (data: any) => {
    const { farmId } = data;
    return fetcher.get(`${CFG_BASE_URL}/foodConsume`, { farmId });
}

export const getDetail = (foodConsumeId: string) => {
    return fetcher.get(`${CFG_BASE_URL}/foodConsume/${foodConsumeId}`);
}

export const getDetailPharmaceutical = (foodConsumeId: string) => {
    return fetcher.get(`${CFG_BASE_URL}/pharmaceuticalConsume/${foodConsumeId}`);
}

export const removeFoodConsume = (foodConsumeId: string) => {
    return fetcher.delete(`${CFG_BASE_URL}/foodConsume/${foodConsumeId}`);
}

export const removePharmaceuticalConsume = (foodConsumeId: string) => {
    return fetcher.delete(`${CFG_BASE_URL}/pharmaceuticalConsume/${foodConsumeId}`);
}