/* eslint-disable @typescript-eslint/no-explicit-any */
import { Steps, Popover } from 'antd';
import React, { FC, ReactChild, ReactFragment, ReactPortal } from 'react';

const { Step } = Steps;

export interface IStep {
    title: string,
    description: string
}

export interface ICustomStepProps {
    stepProps: {
        current: number,
        lstSteps: IStep[]
    }
}

const customDot = (dot: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined, { status, index }: any) => (
    <Popover
        content={
            <span>
                Bước {index} status: {status}
            </span>
        }
    >
        {dot}
    </Popover>
);

const CustomSteps: FC<ICustomStepProps> = ({ stepProps }: ICustomStepProps) => {
    const { current, lstSteps } = stepProps;
    return (
        <div style={{ width: 230, marginLeft: 75 }}>
            <Steps size="small" current={current} progressDot={customDot}>
                {lstSteps.map((item, i) => {
                    return (
                        <Step title={item.title} key={i} description={item.description} />
                    )
                })}
            </Steps>
        </div>)
};

export default CustomSteps;