/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFood } from '@type/food';
import { IFarmData } from '@type/farm';
import { IFoodSupplierData } from '@type/food-supplier';
import { IPen } from '@type/pen'

export interface IFoodConsume extends IFood {
    quantity: number
    totalOrder: number
    farm: IFarmData
    foodSupplier: IFoodSupplierData
}

export enum EStepStatus {
    NOT_COMPLETE,
    COMPLETE,
}

export interface IConsumeSupplier extends IFoodSupplierData {
    foodItems: any
    totalAvailableFood?: number
}

export interface IFoodItemConsume {
    food: IFood
    farm: IFarmData
    foodSupplier: IFoodSupplierData
    id: string
    quantity: number
}

export interface IFarmFoodConsume {
    name: string
    date: string
    farmId: string
    userId: string
    farmFoodConsume: Array<{
        id: string
        quantity: number
        totalOrder: number
        food: IFood
        farm: IFarmData
        foodSupplier: IFoodSupplierData
    }>
}

export interface IPenFoodConsume {
    name: string
    date: string
    penId: string
    penName: string
    penFoodConsumeItems: IFarmFoodConsume['farmFoodConsume']
    penPharmaceuticalConsumeItems?: any
}

interface IFoodSupplierConsume extends IFoodSupplierData {
    totalAvailableFood: number
    foodItems: IFoodItemConsume[]
}
export interface IFoodConsumeContext {
    supplier: {
        foodSupplierData: IFoodSupplierConsume,
        setSupData: (data: IConsumeSupplier) => void,
        exportFood: (food: IFoodConsume, pen: IPen, quantity: number) => void
        setType: (type: string) => void
    },
    foodConsume: {
        penFoodConsume: any,
        farmFoodConsume: any,
    },
}


export interface IPenConsumeStep {
    setStepStatus: (status: EStepStatus, index: number) => void
}
export interface ISupplierStep extends IPenConsumeStep {
    next: () => void
}

export interface IFinalStep extends IPenConsumeStep {
    prev: () => void
    save: (data: IFoodConsumeContext['foodConsume'], values: { name: string, date: string }) => void
}