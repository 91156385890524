/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const apiEvent = {
    getAll(farmId: string, params: any) {
        return fetcher.post(`${CFG_BASE_URL}/events/farmId/${farmId}`, params);
    },
    createEvent(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/events/`, payload);
    },
    updateEvent(eventId: string, payload: any) {
        return fetcher.put(`${CFG_BASE_URL}/events/${eventId}`, payload);
    },
    deleteEvent(eventId: string) {
        return fetcher.delete(`${CFG_BASE_URL}/events/${eventId}`);
    }
};