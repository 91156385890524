/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, useEffect } from 'react';
import { FoodConsumeContext } from '@components/food-consume/food-consume-context';

import { message, notification, Table } from 'antd';
import { supplierFoodConsumeDetail, penListColumns, supplierFoodConsumeDetailPharmaceutical } from './food-step-columns';

import farmServices from '@services/farm';

import { apiPen } from '@api/pen';

import * as inventoryApi from '@api/inventory';

import { EStepStatus, IPenConsumeStep, IFoodConsume, IFoodConsumeContext } from '@type/food-consume';
import { IPen } from '@type/pen';
import { useDispatchRoot } from '@src/src/app/redux/store';
import { setNotEnoughFood } from '@src/src/app/redux/controller/farm.slice';
import axios from 'axios';

const PenConsumeStep: FC<IPenConsumeStep> = ({ setStepStatus }: IPenConsumeStep) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const dispatch = useDispatchRoot();

    const { supplier, foodConsume } = React.useContext(FoodConsumeContext) as IFoodConsumeContext;


    const [pens, setPens] = React.useState<IPen[]>([]);
    const [lstQuantityFood, setLstQuantityFood] = React.useState<any[]>([]);


    React.useEffect(() => {
        getPharmaceuticalInventory();
    }, []);

    const getPharmaceuticalInventory = async () => {
        console.log(supplier.foodSupplierData);

        await axios.get(`https://sit.api.pigman.com.vn/pharmaceuticalInventory?farmId=${currentFarm?.id}&pharmaceuticalSupplierId=${supplier.foodSupplierData.id}&size=1000`)
            .then(async (res: any) => {
                console.log(res.data.data);
                supplier.setSupData({
                    ...supplier.foodSupplierData,
                    foodItems: res.data.data.items,
                    totalAvailableFood: res.data.data.total,
                });

                if (res.data.data.total === 0) {
                    notification.error({
                        message: 'Không có thuốc/vacxin nào',
                    } as any);
                    return;
                }

                const req = {
                    offset: 0,
                    size: 200,
                    farmId: currentFarm.id,
                }

                await axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
                    .then((res: any) => {
                        console.log(res.data.data.items);
                        setPens(res.data.data.items);
                    })


            })
            .catch((err: any) => {
                console.log(err);
            });
    }
    useEffect(() => {
        let flat = false;
        if (lstQuantityFood.length > 0) {
            supplier.foodSupplierData.foodItems.map((v: any) => {
                let quantity = 0;
                console.log(v);
                lstQuantityFood.map((v1: any) => {

                    if (v.pharmaceutical.id === v1.pharmaceutical.pharmaceutical.id) {
                        quantity += parseInt(v1.quantity);
                        if (quantity > v.quantity) {
                            notification.error({
                                message: `Số lượng thức ăn ${v.pharmaceutical.name} không đủ`,
                            });
                            setStepStatus(EStepStatus.NOT_COMPLETE, 1);
                            flat = true;
                            return;
                        }
                    }
                });
            });
            if (!flat) {
                setStepStatus(EStepStatus.COMPLETE, 1);
            }
        }
        // if (lstQuantityFood.length > 0) {
        //     setStepStatus(EStepStatus.COMPLETE, 1);
        // }

    }, [lstQuantityFood]);

    const getDataExportFood = (food: IFoodConsume, pen: IPen) => (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        console.log(food);
        console.log(pen);

        // if (e.target.value === '' || e.target.value === '0' || parseInt(e.target.value, 10) === 0) {
        //     handleSetValueFood(food, pen, e.target.value);
        //     return;
        // }
        handleSetValueFood(food, pen, e.target.value);

        supplier.exportFood(food, pen, +e.target.value);

    };

    const handleSetValueFood = (food: IFoodConsume, pen: IPen, quantity: any) => {
        quantity = parseInt(quantity);
        const existingItem = lstQuantityFood.find((item) => (item.pharmaceutical.id === food.id && item.pen.id === pen.id));

        console.log(existingItem);

        if (existingItem) {
            // If the item exists, increase its quantity by 1
            const updatedItems = lstQuantityFood.map((item) =>
                (item.pharmaceutical.id === food.id && item.pen.id === pen.id) ? { ...item, quantity: quantity } : item
            );
            setLstQuantityFood(updatedItems);
        } else {
            // If the item does not exist, add it to the array with quantity 1
            const newItem = {
                pharmaceutical: food,
                pen: pen,
                quantity: quantity,
            };
            setLstQuantityFood([...lstQuantityFood, newItem]);
        }
    }
    return (
        <div>
            <div id="detail-supplier">
                <div id="supplier-information">
                    <h2 className="consume-title"> Thông tin hãng sản xuất </h2>

                    <div className="information">
                        <label htmlFor="supplier-name">Tên: </label>
                        <span id="supplier-name"> {supplier.foodSupplierData.name} </span>
                    </div>

                    <div className="information">
                        <label htmlFor="supplier-address">Địa chỉ: </label>
                        <span id="supplier-address"> {supplier.foodSupplierData.address} </span>
                    </div>

                    {/* <div className="information">
                        <label htmlFor="total-food">Tổng số lương thực: </label>
                        <span id="total-food"> {supplier.foodSupplierData.totalAvailableFood} </span>
                    </div> */}
                </div>

                <div id="food-detail">
                    <h2 className="consume-title center-text"> Thông tin thuốc / vắc xin </h2>

                    <div className="wrapper-food">
                        <Table
                            dataSource={supplier.foodSupplierData.foodItems}
                            columns={supplierFoodConsumeDetailPharmaceutical}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>

            <Table
                dataSource={pens}
                columns={penListColumns(supplier.foodSupplierData.foodItems, getDataExportFood)}
                pagination={false}
                className="mt-24"
                locale={{ emptyText: 'Không có dữ liệu' }}
            />
            {/* <div className="wrapper-action">
                <button className="btn" >
                    Lùi lại
                </button>
                <button className="btn baka" type="submit">
                    Lưu
                </button>

            </div> */}
        </div>
    );
};

export default PenConsumeStep;
