/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const apiEventType = {
    getAll(params: any) {
        return fetcher.get(`${CFG_BASE_URL}/eventTypes/allEvent`, params);
    },
    createEventType(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/events/`, payload);
    }
};