import { IStep } from "./customStep";

export enum AllStepsProcess {
    Welcome,
    CreateANewFarm,
    SelectFarm,
    ChooseDefaultFarm,
    DropdownFarm,
    CreateANewPen,
    FillInNewPenForm,
    AddNewCamera,
    FillInNewCameraForm,
    SelectPenToImportPigs,
    ImportPigs,
    CompleteUpdatePigsToPen,
    GoToActivePens,
    None,
    NoneInsideFillInNewCameraForm,
    NoneInSelectCam
}

export const stepProcessData: IStep[] = [
    { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' },
    { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' },
    { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' }, { title: '', description: '' }
]
