import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllStepsProcess } from "../../components/common/guidelines/guideline.types";

interface GuidelineState {
    currentProcess: AllStepsProcess;
    isShowGuideline: boolean;
}

const initialState: GuidelineState = {
    currentProcess: AllStepsProcess.Welcome,
    isShowGuideline: false
}

const guidelineSlice = createSlice({
    name: 'guideline',
    initialState: initialState,
    reducers: {
        setCurrentProcess(state, action: PayloadAction<AllStepsProcess>) {
            state.currentProcess = action.payload
        },
        setIsShowGuideline(state, action: PayloadAction<boolean>) {
            state.isShowGuideline = action.payload
        },
    }
})

export const {
    // getDepartmentRequest,
    setCurrentProcess,
    setIsShowGuideline
} = guidelineSlice.actions
export const guidelineReducer = guidelineSlice.reducer