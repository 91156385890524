import { Breadcrumb, Button, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Select, Slider, Space, Spin, Table, TableColumnsType, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.tagpigs.scss'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { set } from 'date-fns';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import notification, { NotificationPlacement } from 'antd/lib/notification';
import { Context } from '../context/context';
import Search from 'antd/lib/input/Search';
import { log } from 'console';
import moment from 'moment';
import farmService from '@services/farm';
import { ArrowLeftOutlined, BellOutlined, SearchOutlined, UserOutlined, SettingOutlined, LogoutOutlined, CaretDownOutlined } from '@ant-design/icons'
import { ColumnsType, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useHistory } from 'react-router-dom';

// Kiểu dữ liệu của thẻ lợn
interface DataItem {
    penId: string;
    batteryLevel: number;
    manufacturer: string;
    warrantyPeriod: number;
    tagId: number;
    createdAt: string;
    id: string;
}

// // Các cột của bảng
// const columns: ColumnsType<DataItem> = [
//     {
//         title: 'STT',
//         dataIndex: 'ordinalNumber',
//         key: 'ordinalNumber',
//     },
//     {
//         title: 'Tên thẻ',
//         dataIndex: 'tagId',
//         key: 'tagId',
//     },
//     {
//         title: 'Sản xuất',
//         dataIndex: 'manufacturer',
//         key: 'manufacturer',
//     },
//     {
//         title: 'Ngày nhập',
//         dataIndex: 'createdAt',
//         key: 'createdAt',
//     },
//     {
//         title: 'Bảo hành',
//         dataIndex: 'warrantyPeriod',
//         key: 'warrantyPeriod',
//     },
//     {
//         title: '% pin',
//         dataIndex: 'batteryLevel',
//         key: 'batteryLevel',
//     },
//     {
//         title: 'Tên chuồng',
//         dataIndex: 'penId',
//         key: 'penId',
//     },
//     {
//         key: 'operation',
//         title: 'Thao tác',
//         dataIndex: 'operation',
//     },
// ];

// Slider marks
const marks: SliderMarks = {
    30: '0°C',
    50: {
        style: {
            color: '#f50',
        },
        label: <strong>50°C</strong>,
    },
};

const TagPigs = () => {

    const { Option } = Select;
    const [dataTagPigs, setDataTagPigs] = React.useState<any[]>([]); // Dữ liệu thẻ lợn
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [editPigs, setEditPigs] = useState<DataItem>(); // thẻ lợn đang được chỉnh sửa
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số thẻ lợn trên một trang
    const [isShowAddModal, setIsShowAddModal] = useState(false); // Trạng thái hiển thị modal thêm thẻ lợn
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Trạng thái hiển thị modal xóa thẻ lợn
    const [form] = Form.useForm(); // Form thêm thẻ lợn
    const [idDeleteTagPigs, setIdDeleteTagPigs] = useState<string>(); // Id thẻ lợn đang được xóa
    const [offset, setOffset] = useState(0); // Vị trí bắt đầu lấy dữ liệu
    const [size, setSize] = useState(10);
    const [total, setTotal] = useState(0); // Tổng số thẻ lợn
    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const currentFarm = farmService.getCurrentFarm();
    const context = React.useContext(Context);
    const history = useHistory();
    const [deletePen, setDeletePen] = useState<any>(); // Danh sách chuồng
    const columns: ColumnsType<DataItem> = [
        {
            title: 'STT',
            dataIndex: 'ordinalNumber',
            key: 'ordinalNumber',
        },
        {
            title: 'THỜI GIAN ĐO',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'TÊN CHUỒNG',
            dataIndex: 'penId',
            key: 'penId',
            sorter: (a, b) => a.penId.localeCompare(b.penId),
        },
        {
            title: 'TAG',
            dataIndex: 'tagId',
            key: 'tagId',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'NHIỆT ĐỘ (°C)',
            dataIndex: 'temperature',
            key: 'temperature',
        },
        {
            title: 'MỨC PIN(V)',
            dataIndex: 'batteryLevel',
            key: 'batteryLevel',
        },

        // {
        //     title: 'OFFSET (°C)',
        //     dataIndex: 'offset',
        //     key: 'offset',
        // },
        {
            title: 'TIÊM PHÒNG',
            dataIndex: 'injection',
            key: 'injection',
        },

        {
            title: 'TRẠNG THÁI',
            dataIndex: 'status',
            key: 'status',
        },

        // {
        //     key: 'operation',
        //     title: 'Thao tác',
        //     dataIndex: 'operation',
        // },
    ];

    const sortPenById = (id: string) => {
        const pen = lstPen?.find(pen => pen.id === id);
        if (pen?.name) return pen?.name;
        return 'Chưa xác định';
    }
    // Hàm thực hiện gán giá trị nhiệt độ cho thẻ lợn
    const handleTemperatureChange = (value: any) => {
        setTemperature(value);
    };

    // Hàm thực hiện khi nhấn nút thêm thẻ lợn
    const showAddModal = () => {
        // Gán thẻ lợn đang được chỉnh sửa là undefined
        setEditPigs(undefined);
        // setTemperature([37, 39]);
        // Gán giá trị mặc định cho form
        form.setFieldsValue({
            tagId: null,
            manufacturer: null,
            createdAt: null,
            warrantyPeriod: null,
            batteryLevel: null,
            penId: null,
        });

        // Hiển thị modal thêm thẻ lợn
        setIsShowAddModal(true);
    };

    // Hàm thực hiện khi nhấn nút xác nhận
    const handleOk = () => {
        setIsShowAddModal(false);
    };

    // Hàm thực hiện khi nhấn nút hủy
    const handleCancel = () => {
        setIsShowAddModal(false);
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // Hàm thực hiện khi nhấn nút edit trên bảng
    const handleEdit = (record: DataItem) => {
        console.log(record);

        // Gán thẻ lợn đang được chỉnh sửa là record
        setEditPigs(record);
        // Gán giá trị cho form là giá trị của thẻ lợn đang được chỉnh sửa
        form.setFieldsValue({
            tagId: record.tagId,
            manufacturer: record.manufacturer,
            createdAt: moment(record.createdAt),
            warrantyPeriod: record.warrantyPeriod,
            batteryLevel: record.batteryLevel,
            penId: (record.penId === "000000000000000000000000" || !record.penId) ? null : record.penId,

        });
        // Hiển thị modal thêm/sửa thẻ lợn
        setIsShowAddModal(true);
    };

    // Hàm thực hiện khi xóa thẻ lợn
    const handleDelete = (record: DataItem) => {
        setIsShowDeleteModal(true);
        setIdDeleteTagPigs(record.id);

    };

    // Hàm thực hiện khi nhấn nút xác nhận trên modal thêm/sửa thẻ lợn
    const onFinish = (values: any) => {
        console.log('Success:', values);
        // Hiển thị loading
        setLoading(true);

        // Nếu thẻ lợn đang được chỉnh sửa thì gọi api sửa thẻ lợn
        if (editPigs) {

            let req = {}
            if (values.penId) {
                req = {
                    farmId: currentFarm.id,
                    tagId: values.tagId === 0 ? 1 : values.tagId,
                    penId: values.penId,
                    manufacturer: values.manufacturer,
                    warrantyPeriod: parseInt(values.warrantyPeriod),
                    createdAt: new Date(),
                    additionalProp1: {}
                }
            }
            else {
                req = {
                    farmId: currentFarm.id,
                    tagId: values.tagId === 0 ? 1 : values.tagId,
                    manufacturer: values.manufacturer,
                    warrantyPeriod: parseInt(values.warrantyPeriod),
                    createdAt: new Date(),
                    additionalProp1: {}
                }
            }
            console.log(req);
            // Gọi api sửa thẻ lợn
            axios.put('https://sit.api.pigman.com.vn/temperatureTag/' + editPigs.id, req)
                .then(response => {
                    setTimeout(() => {
                        console.log(response);
                        // Hiển thị thông báo sửa thành công
                        notification['success']({
                            message: 'Sửa thẻ lợn thành công',
                            description:
                                'Đã sửa thẻ lợn vào cơ sở dữ liệu',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt modal thêm/sửa thẻ lợn
                        setIsShowAddModal(false);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            // Lấy ra giá trị đã nhập trong form
            let req = {};
            if (values.penId) {
                req = {
                    farmId: currentFarm.id,
                    startTagId: values.startTagId,
                    endTagId: values.endTagId,
                    manufacturer: values.manufacturer,
                    warrantyPeriod: values.warrantyPeriod,
                    penId: values.penId ? values.penId : null,
                    createdAt: new Date(),
                    additionalProp1: {}
                }
            }
            else {
                req = {
                    farmId: currentFarm.id,
                    startTagId: values.startTagId,
                    endTagId: values.endTagId,
                    manufacturer: values.manufacturer,
                    warrantyPeriod: values.warrantyPeriod,
                    createdAt: new Date(),
                    additionalProp1: {}
                }
            }
            console.log(req);
            // Gọi api thêm thẻ lợn
            axios.post('https://sit.api.pigman.com.vn/temperatureTag/import-range', req)
                .then(response => {
                    console.log(response);
                    setTimeout(() => {
                        const failedLst = response.data.data.failed;
                        const successLst = response.data.data.success;

                        if (successLst.length <= 0) {
                            const lstFailed = failedLst.join(', ')
                            // Hiển thị thông báo thêm thành công
                            notification['error']({
                                message: 'Thêm thẻ lợn không thành công',
                                description:
                                    `Các thẻ lợn: {${lstFailed}} đã tồn tại trong cơ sở dữ liệu`,
                            });
                        }
                        if (failedLst.length <= 0) {
                            const lstSuccess = successLst.join(', ')
                            // Hiển thị thông báo thêm thành công
                            notification['success']({
                                message: 'Thêm thẻ lợn thành công',
                                description:
                                    `Đã thêm các thẻ lợn: {${lstSuccess}} vào cơ sở dữ liệu`,
                            });
                        }
                        if (failedLst.length > 0 && successLst.length > 0) {
                            const lstFailed = failedLst.join(', ')
                            const lstSuccess = successLst.join(', ')

                            // Hiển thị thông báo thêm thành công
                            notification['success']({
                                message: 'Thêm thẻ lợn thành công',
                                description:
                                    `Đã thêm các thẻ lợn: {${lstSuccess}} vào cơ sở dữ liệu và các thẻ lợn: {${lstFailed}} đã tồn tại trong cơ sở dữ liệu`,
                            });
                        }
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt modal thêm/sửa thẻ lợn
                        setIsShowAddModal(false);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                });
        }
    };

    // lấy dữ liệu từ api
    const fetchData = async (name: string) => {
        // Gọi api lấy dữ liệu
        setLoading(true);
        axios.get(name ? `https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?tagSearch=${name}&offset=${(currentPage - 1) * size}&size=${size}` : `https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?offset=${(currentPage - 1) * size}&size=${size}`)
            .then(req => {
                console.log(req);
                if (req.data.data.items.length > 0) {
                    setTotal(req.data.data.total);

                    // Gán dữ liệu lấy được vào biến data
                    const data = req.data.data.items.map((item: any, index: number) => {
                        const numberIndex = (currentPage - 1) * size + index + 1;
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        // const tmpCreatedAt = new Date(item.createdAt).toLocaleDateString('en-GB');
                        // Format thời gian thành ngày tháng năm giờ phút giây
                        const formattedDate = moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss');

                        const parseTemp = parseFloat(item.currentTemperature);
                        const parseOffset = (item.offset !== null && item.offset !== undefined && !isNaN(item.offset)) ? item.offset : 0; parseFloat(item.offset);

                        // Kiểm tra nếu mức độ pin là số nguyên, thì giữ nguyên, còn ko thì làm tròn đến chữ số thập phân thứ 2
                        const tmpBatteryLevel = item.batteryLevel % 1 === 0 ? `${item.batteryLevel}` : `${item.batteryLevel.toFixed(2)}`;
                        const tmpTemperature = (parseTemp + parseOffset) % 1 === 0 ? `${parseTemp + parseOffset}` : `${(parseTemp + parseOffset).toFixed(2)}`;
                        // Đổi giá trị tagId từ 1 10 100 thành 0 010 100
                        const tmpTagId = item.tagId;
                        return {
                            ordinalNumber: numberIndex,
                            tagId: item.name ? item.name : `Heo ${tmpTagId}`,
                            id: tmpTagId,
                            temperature: `${tmpTemperature}`,
                            createdAt: formattedDate,
                            // offset: `${0}`,
                            warrantyPeriod: item.warrantyPeriod,
                            batteryLevel: `${tmpBatteryLevel}`,
                            penId: (item.penId !== "000000000000000000000000" || item.penId) ? sortPenById(item.penId) : "Trống",
                            status: parseFloat(tmpTemperature) > 39 ? 'Cảnh báo' : 'Bình thường',
                            injection: item.vaccinated ? 'Đã tiêm' : 'Chưa tiêm',
                            // operation:
                            //     <Space>
                            //         <Tooltip title="Sửa">
                            //             <EditOutlined
                            //                 onClick={() => handleEdit(item)}
                            //             />
                            //         </Tooltip>
                            //         <Tooltip title="Xóa">
                            //             <DeleteOutlined title='Xóa' onClick={() => handleDelete(item)} />
                            //         </Tooltip>
                            //     </Space>
                        }
                    });
                    // Gán dữ liệu vào biến dataTagPigs
                    setDataTagPigs(data);
                }
                else {
                    setDataTagPigs([]);
                }
                setLoading(false);
            }

            )
    };

    // Hàm thực hiện lấy dữ liệu chuồng
    const loadLstPen = async () => {
        axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${currentFarm.id}?offset=${offset}&size=${size}`)
            .then(res => {
                console.log(res.data.data);

                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    tmp.push({
                        id: item.id,
                        name: item.name
                    })
                })
                setLstPen(tmp);
            })
    }
    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        loadLstPen();
    }, [context.changeLocation.isChangeLocation, currentFarm.id]);

    useEffect(() => {
        fetchData('');
    }, [lstPen]);

    useEffect(() => {
        fetchData('');
    }, [context.changeLocation.isChangeLocation, currentFarm.id]);

    useEffect(() => {
        fetchData('');
    }, [currentPage]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: total,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện hủy xóa thẻ lợn
    const handleCancelDeleteModal = () => {
        setIsShowDeleteModal(false);
    }

    // Hàm thực hiện xóa thẻ lợn
    const handleDeleteSubmit = () => {
        // Hiển thị loading
        setLoading(true);

        // Gọi api xóa thẻ lợn
        axios.delete('https://sit.api.pigman.com.vn/temperatureTag/{id}?id=' + idDeleteTagPigs)
            .then(() => {
                setTimeout(() => {
                    setIsShowDeleteModal(false);
                    // Hiển thị thông báo xóa thành công
                    notification['success']({
                        message: 'Xóa thẻ lợn thành công',
                        description:
                            'Đã xóa thẻ lợn khỏi cơ sở dữ liệu',
                    });
                    // Gọi hàm fetchData để lấy lại dữ liệu
                    fetchData('');
                    // Tắt loading
                    setLoading(false);
                }, 1000);
            });
    }

    // Hàm thực hiện tìm kiếm bệnh theo tên
    const onSearch = (value: string) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        setTimeout(() => {
            // Load dữ liệu
            fetchData(value);
            // Tắt loading
            setLoading(false);
        }, 1000);
    }

    return (
        <>
            <div className='tag-pig-main'>
                <Breadcrumb >
                    <Breadcrumb.Item>
                        <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách Tag</Breadcrumb.Item>
                </Breadcrumb>
                <div className='button-tag-pig'>
                    <Search placeholder="Tìm kiếm thẻ lợn theo mã thẻ" onSearch={onSearch} enterButton allowClear />
                    {/* <Button onClick={showAddModal}>Thêm thẻ</Button> */}
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-tag-pig'>
                        <Table
                            dataSource={dataTagPigs}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                            locale={{
                                triggerDesc: 'Sắp xếp theo thứ tự giảm dần',
                                triggerAsc: 'Sắp xếp theo thứ tự tăng dần',
                                cancelSort: 'Hủy sắp xếp',
                            }}
                        />
                    </div>
                </Spin>

            </div>
            <Modal
                visible={isShowAddModal}
                title={editPigs !== undefined ? 'Chỉnh sửa thẻ' : 'Tạo thẻ'}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={true}
                footer={false}
            >
                {editPigs !== undefined ?
                    <Form layout="horizontal" onFinish={onFinish} form={form}>
                        <Form.Item
                            name="tagId"
                            label={(
                                <span>
                                    Mã thẻ
                                </span>
                            )}
                            rules={[{ required: true, message: 'Vui lòng nhập mã thẻ' }]} >
                            <Input type='number' min={1} placeholder='Nhập mã thẻ' disabled />
                        </Form.Item>
                        <Form.Item
                            name="manufacturer"
                            label={(
                                <span>
                                    Nhãn hiệu <span style={{ color: 'red' }}>*</span>
                                </span>
                            )}
                            rules={[{ required: true, message: 'Vui lòng nhập nhãn hiệu' }]}>
                            <Input placeholder='Nhập nhãn hiệu' />
                        </Form.Item>
                        {/* <Form.Item name="createdAt" label={(
                            <span>
                                Ngày nhập <span style={{ color: 'red' }}>*</span>
                            </span>
                        )} rules={[{ required: true, message: 'Vui lòng nhập ngày nhập' }]}>
                            <DatePicker format={'DD/MM/yyyy'} placeholder='Nhập ngày nhập thẻ' />
                        </Form.Item>
                        <Form.Item name="warrantyPeriod" label={(
                            <span>
                                Thời gian bảo hành <span style={{ color: 'red' }}>*</span>
                            </span>
                        )} rules={[{ required: true, message: 'Vui lòng nhập hạn bảo hành' }]}>
                            <Input type='number' placeholder='Thời gian bảo hành (tháng)' />
                        </Form.Item> */}
                        <Form.Item name="penId" label="Tên chuồng"  >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                suffixIcon={<CaretDownOutlined rev={undefined} />}
                                // onChange={handleChangeValueSelect}
                                placeholder="Chọn tên chuồng"
                            >
                                {lstPen?.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>

                        </Form.Item>
                        <div className='action-btn'>
                            <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                                Hủy bỏ
                            </Button>
                            <Button className='summit-btn' type="primary" htmlType="submit">
                                Xác nhận
                            </Button>

                        </div>
                    </Form> :
                    <Form layout="horizontal" onFinish={onFinish} form={form}>
                        <div className='tag-box'>
                            <Form.Item name="startTagId" label={(
                                <span>
                                    Mã thẻ <span style={{ color: 'red' }}>*</span>
                                </span>
                            )} rules={[{ required: true, message: 'Vui lòng nhập mã thẻ' }]} >
                                <Input type='number' placeholder='Nhập mã thẻ' min={0} step={1} pattern="\d*" />
                            </Form.Item>
                            <Form.Item className='endTagId' name="endTagId" label={(
                                <span>
                                    Đến <span style={{ color: 'red' }}>*</span>
                                </span>
                            )}
                                dependencies={['startTagId']}
                                rules={[
                                    { required: true, message: 'Vui lòng nhập mã kết thúc' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || parseInt(getFieldValue('startTagId')) <= parseInt(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Mã thẻ kết thúc lớn hơn mã thẻ bắt đầu'));
                                        },
                                    }),

                                ]}>
                                <Input type='number' placeholder='Nhập mã thẻ' />
                            </Form.Item>
                        </div>
                        <Form.Item name="manufacturer" label={(
                            <span>
                                Nhãn hiệu <span style={{ color: 'red' }}>*</span>
                            </span>
                        )} rules={[{ required: true, message: 'Vui lòng nhập nhãn hiệu' }]}>
                            <Input placeholder='Nhập nhãn hiệu' />
                        </Form.Item>
                        {/* <Form.Item name="createdAt" label={(
                            <span>
                                Ngày sản xuất <span style={{ color: 'red' }}>*</span>
                            </span>
                        )} rules={[{ required: true, message: 'Vui lòng nhập ngày nhập' }]}>
                            <DatePicker format={'DD/MM/yyyy'} placeholder='Nhập ngày sản xuất' />
                        </Form.Item>
                        <Form.Item name="warrantyPeriod" label={(
                            <span>
                                Thời gian bảo hành <span style={{ color: 'red' }}>*</span>
                            </span>
                        )} rules={[{ required: true, message: 'Vui lòng nhập thời gian bảo hành' }]}>
                            <Input type='number' placeholder='Nhập thời gian bảo hành (tháng)' />
                        </Form.Item> */}
                        <Form.Item name="penId" label="Tên chuồng"  >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                suffixIcon={<CaretDownOutlined rev={undefined} />}
                                // onChange={handleChangeValueSelect}
                                placeholder="Chọn tên chuồng"
                            >
                                {lstPen?.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>

                        </Form.Item>
                        <div className='action-btn'>
                            <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                                Hủy bỏ
                            </Button>
                            <Button className='summit-btn' type="primary" htmlType="submit">
                                Xác nhận
                            </Button>

                        </div>
                    </Form>
                }

            </Modal>
            <Modal
                visible={isShowDeleteModal}
                title='Xóa thẻ lợn'
                onCancel={handleCancelDeleteModal}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                <div>Bạn có chắc chắn muốn xóa thẻ nhiệt này?</div>
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancelDeleteModal}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleDeleteSubmit}>
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default TagPigs