/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import './food-order.scss';

import * as foodOrderApi from '@api/food-order';
import * as orderItemApi from '@api/food-order-item';

import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';
import { UtilCollections } from '@utils/collections';

import { detailColumns } from './food-order-columns';

import { IFoodConsumeDetailComponent } from '@type/food-order';

const FoodOrderDetail: FC<IFoodConsumeDetailComponent> = ({ foodOrderId }: IFoodConsumeDetailComponent) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const initialDataDefaultValue = {
        name: '',
        foodSupplierId: '',
        date: moment(new Date()).format(DATE_FORMAT),
        foodOrderItems: [],
        foodSupplierName: '',
        farmId: currentFarm.id,
        totalPrice: 0,
        orderedTotalPrice: 0,
    };

    const [initialData, setInitialData] = React.useState<any>(initialDataDefaultValue);
    const [orderedItems, setOrderedItems] = React.useState<any>([]);

    React.useEffect(() => {
        (async () => {
            const detail = (await getOrderDetail()) as any;
            const listOrderItems = (await getOrderItemsList()) as any;
            setInitialData({
                name: detail.name,
                date: UtilCollections.convertDateToDMY(UtilCollections.formatClientDate(detail.date.split('T')[0])),
                foodSupplierId: detail.foodSupplier.id,
                foodSupplierName: detail.foodSupplier.name,
                orderedTotalPrice: detail.totalPrice,
                totalPrice: 0,
            });
            setOrderedItems(listOrderItems.items);
        })();

        return () => {
            setInitialData(initialDataDefaultValue);
        };
    }, []);

    const getOrderDetail = () => foodOrderApi.getOrderDetail(foodOrderId);

    const getOrderItemsList = async () => orderItemApi.getOrderItems({ foodOrderId, offset: 0, size: 200 });

    return (
        <div className="log-form" style={{ width: '80%' }}>
            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Tên người nhập
                </label>

                <div className="mt-12" id="name">
                    {initialData.name}
                </div>
            </div>

            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Ngày
                </label>

                <div className="mt-12" id="name">
                    {initialData.date}
                </div>
            </div>

            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Nhà cung cấp
                </label>

                <div className="mt-12" id="name">
                    {initialData.foodSupplierName}
                </div>
            </div>

            <div className="log-field">
                <label className="log-label">Thức ăn đã đặt</label>
                <div className="control has-icon-right">
                    <Table
                        columns={detailColumns}
                        dataSource={orderedItems}
                        pagination={false}
                        size="small"
                        locale={{ emptyText: 'Không có thức ăn' }}
                    // footer={() => `Tổng giá: ${UtilCollections.VNDCurrencyFormat(initialData.orderedTotalPrice)}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default FoodOrderDetail;
