/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState } from 'react';
import { message, notification } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { RenderValidationError } from '@components/errors/validation';

import { apiUser } from '@api/user';
import { ILoginUser } from '@components/auth/auth.types';

import eyeShow from '@assets/eyeShow.png';
import eyeHidden from '@assets/eyeHidden.png';

import * as Yup from 'yup';

const initialRegisterInput = {
    email: '',
    password: '',
    passwordConfirmation: '',
    username: '',
    fullName: '',
    phone: '',
};

const Register: FC = () => {
    const [isSuccess, setIsSuccess] = useState(false);

    const [isShowPass, setIsShowPass] = useState(false);

    const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);

    const doRegister = async (values: any, actions: any): Promise<void> => {
        try {
            values.username = values.email;
            (await apiUser.register(values)) as ILoginUser;

            setIsSuccess(true);
        } catch (err: any) {
            if (typeof err === 'string') {
                notification.error({
                    message: err,
                } as any);
            } else {
                notification.error({
                    message: 'Đã xảy ra lỗi'
                } as any);
            }

            if (err.status === 500) {
                notification.error({
                    message: 'Lỗi hệ thống!',
                } as any);
            }
        }
    };

    const schema = Yup.object().shape({
        email: Yup.string().email('Không đúng định dạng email').required('Trường này bắt buộc'),
        password: Yup.string().min(6, 'Tối thiểu 6 kí tự').required('Trường này bắt buộc'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Không trùng với mật khẩu')
            .required('Trường này bắt buộc'),
        phone: Yup.string()
            .required('Trường này bắt buộc')
            .matches(/^\d+$/, 'Chỉ chấp nhận số')
            .matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, 'Số điện thoại không đúng định dạng'),
        fullName: Yup.string().required('Trường này bắt buộc'),
    });

    return (
        <div className="wrapper-form">
            {!isSuccess && (
                <Formik initialValues={initialRegisterInput} onSubmit={doRegister} validationSchema={schema}>
                    {({ errors, touched, isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="auth-field__col-2">
                                <div className="auth-field">
                                    <div className="control has-icon-right">
                                        <Field
                                            name="email"
                                            className={`auth-input`}
                                            type={'text'}
                                            id="email"
                                            placeholder={'Email'}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="email" />
                                    </div>
                                </div>

                                <div className="auth-field">
                                    <div className="control has-icon-right">
                                        <Field
                                            name="fullName"
                                            className={`auth-input`}
                                            type={'text'}
                                            id="fullName"
                                            placeholder={'Họ và tên'}
                                        />
                                        <ErrorMessage component={RenderValidationError} name="fullName" />
                                    </div>
                                </div>
                            </div>

                            <div className="auth-field">
                                <div className="control has-icon-right">
                                    <Field
                                        name="phone"
                                        className={`auth-input`}
                                        type={'text'}
                                        id="phone"
                                        placeholder={'Số điện thoại'}
                                    />
                                    <ErrorMessage component={RenderValidationError} name="phone" />
                                </div>
                            </div>

                            <div className="auth-field">
                                <div className="control has-icon-right">
                                    <Field
                                        name="password"
                                        className={`auth-input`}
                                        type={isShowPass ? 'text' : 'password'}
                                        id="password"
                                        placeholder={'Mật khẩu'}
                                    />
                                    <img style={{ position: 'absolute', marginTop: '-35px', right: '13%' }} onClick={() => setIsShowPass(!isShowPass)} id='auth-modal-eye' src={isShowPass ? eyeShow : eyeHidden} alt='eye' />
                                    <ErrorMessage component={RenderValidationError} name="password" />
                                </div>
                            </div>

                            <div className="auth-field">
                                <div className="control has-icon-right">
                                    <Field
                                        name="passwordConfirmation"
                                        className={`auth-input`}
                                        type={isShowPassConfirm ? 'text' : 'password'}
                                        id="passwordConfirmation"
                                        placeholder={'Xác nhận mật khẩu'}
                                    />
                                    <img style={{ position: 'absolute', marginTop: '-35px', right: '13%' }} onClick={() => setIsShowPassConfirm(!isShowPassConfirm)} id='auth-modal-eye' src={isShowPassConfirm ? eyeShow : eyeHidden} alt='eye' />
                                    <ErrorMessage component={RenderValidationError} name="passwordConfirmation" />
                                </div>
                            </div>

                            <div className="auth-field">
                                <span />
                                <button
                                    type="submit"
                                    className={`btn auth-btn is-primary ${isSubmitting ? 'is-loading' : ''}`}
                                    disabled={isSubmitting}>
                                    Đăng ký
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {isSuccess && (
                <div className={'registerSuccess'}>
                    <div>Chúc mừng bạn đã đăng ký tài khoản thành công</div>
                    <div>Hãy kiểm tra Mail của bạn ngay nhé!</div>
                </div>
            )}
        </div>
    );
};

export default Register;
