/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { Table } from 'antd';
import './food-order.scss';

import * as foodOrderApi from '@api/food-order';
import * as orderItemApi from '@api/food-order-item';

import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';
import { UtilCollections } from '@utils/collections';

import { detailColumns, detailColumnsPharmaceutical } from '../../food-order/food-order-columns';

import { IFoodConsumeDetailComponent } from '@type/food-order';
import axios from 'axios';

const PharmaceuticalOrderDetail: FC<IFoodConsumeDetailComponent> = ({ foodOrderId }: IFoodConsumeDetailComponent) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const initialDataDefaultValue = {
        name: '',
        pharmaceuticalSupplierId: '',
        date: moment(new Date()).format(DATE_FORMAT),
        pharmaceuticalOrderItems: [],
        pharmaceuticalSupplierName: '',
        farmId: currentFarm.id,
        totalPrice: 0,
        orderedTotalPrice: 0,
    };

    const [initialData, setInitialData] = React.useState<any>(initialDataDefaultValue);

    const [orderDetail, setOrderDetail] = React.useState<any>();
    const [listOrderItems, setListOrderItems] = React.useState<any>([]);

    React.useEffect(() => {
        getOrderDetail();
        getOrderItemsList();
    }, []);

    useEffect(() => {
        if (!orderDetail) return;
        setInitialData({
            name: orderDetail.name,
            date: UtilCollections.convertDateToDMY(UtilCollections.formatClientDate(orderDetail.date.split('T')[0])),
            foodSupplierId: orderDetail.pharmaceuticalSupplier.id,
            foodSupplierName: orderDetail.pharmaceuticalSupplier.name,
            orderedTotalPrice: orderDetail.totalPrice,
            totalPrice: 0,
        });
    }, [orderDetail]);

    const getOrderDetail = async () => {
        console.log(foodOrderId);

        await axios.get(`https://sit.api.pigman.com.vn/pharmaceuticalOrder/${foodOrderId}`)
            .then((res) => {
                console.log(res.data.data);
                setOrderDetail(res.data.data);
            })
    }

    const getOrderItemsList = async () => {
        const req = {
            offset: 0,
            size: 1000,
            pharmaceuticalOrderId: foodOrderId,
        }
        await axios.post(`https://sit.api.pigman.com.vn/pharmaceuticalOrderItem/pharmaceuticalOrder`, req)
            .then((res) => {
                console.log(res.data.data.items);
                setListOrderItems(res.data.data.items);
            })
    }

    // const getOrderDetail = () => foodOrderApi.getOrderDetail(foodOrderId);

    // const getOrderItemsList = async () => orderItemApi.getOrderItems({ foodOrderId, offset: 0, size: 200 });

    return (
        <div className="log-form" style={{ width: '80%' }}>
            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Tên người nhập
                </label>

                <div className="mt-12" id="name">
                    {initialData.name}
                </div>
            </div>

            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Ngày
                </label>

                <div className="mt-12" id="name">
                    {initialData.date}
                </div>
            </div>

            <div className="log-field">
                <label htmlFor="" className="log-label">
                    Nhà cung cấp
                </label>

                <div className="mt-12" id="name">
                    {initialData.foodSupplierName}
                </div>
            </div>

            <div className="log-field">
                <label className="log-label">Thức ăn đã đặt</label>
                <div className="control has-icon-right">
                    <Table
                        columns={detailColumnsPharmaceutical}
                        dataSource={listOrderItems}
                        pagination={false}
                        size="small"
                        locale={{ emptyText: 'Không có thức ăn' }}
                    // footer={() => `Tổng giá: ${UtilCollections.VNDCurrencyFormat(initialData.orderedTotalPrice)}`}
                    />
                </div>
            </div>
        </div>
    );
};

export default PharmaceuticalOrderDetail;
