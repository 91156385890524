import React, { FC } from "react";
import pig from '@assets/pig.png';
import './pig.scss';

const Pig: FC<{ isOpacityStyle: boolean, className: string }> = ({ className = '' }: { isOpacityStyle: boolean, className: string }) => {
    return (
        <div id="pig-wrapper" className={className}>
            <img src={pig} alt="pig" />
        </div>
    )
}

export default Pig;