/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const apiFarm = {
    getAll(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/farms/onwerId`, payload);
    },
    createFarm(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/farms/`, payload);
    },
    deleteFarm(farmId: string) {
        return fetcher.delete(`${CFG_BASE_URL}/farms/${farmId}`);
    },
    updateFarm(farmId: any, payload: any) {
        return fetcher.put(`${CFG_BASE_URL}/farms/${farmId}`, payload);
    },
    getAllPigs(farmId: string) {
        return fetcher.get(`${CFG_BASE_URL}/farms/getAllPigs/${farmId}`);
    },
    getPigInAndOut(farmId: string, year: number) {
        return fetcher.post(`${CFG_BASE_URL}/farms/getTotalPig`, { year, farmId } as any);
    }
};