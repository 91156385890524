import { Breadcrumb, Button, ConfigProvider, Form, FormInstance, Input, Modal, Select, Slider, Space, Spin, Table, TableColumnsType, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.sickpigs.scss'
import { DeleteOutlined, EditOutlined, FlagOutlined, CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import { set } from 'date-fns';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import notification, { NotificationPlacement } from 'antd/lib/notification';
import { Context } from '../context/context';
import Search from 'antd/lib/input/Search';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import { setShowModalAddSickPig } from '../../redux/controller/farm.slice';
import { AiOutlineEye } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import userServices from '@services/user';
import * as XLSX from 'xlsx';

interface symptoms {
    id: string,
    name: string
}

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    id?: string,
    fromAge: number,
    toAge: number,
    symptoms?: symptoms[],
    metaData: string,
    name: string,
    lowerBoundTemperature: number,
    upperBoundTemperature: number,
    ageDescription: string,
    warning?: boolean
    definition?: string
    description?: string
    prevention?: string
}

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'ordinalNumber',
        key: 'ordinalNumber',
    },
    {
        title: 'Tên bệnh',
        dataIndex: 'diseaseName',
        key: 'diseaseName',
    },
    {
        title: 'Triệu chứng',
        dataIndex: 'symptom',
        key: 'symptom',
    },
    {
        title: 'Độ tuổi',
        dataIndex: 'ageDescription',
        key: 'ageDescription',
    },
    {
        title: 'Nhiệt độ',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    // {
    //     title: 'Định nghĩa',
    //     dataIndex: 'definition',
    //     key: 'definition',
    // },
    // {
    //     title: 'Mô tả',
    //     dataIndex: 'description',
    //     key: 'description',
    // },
    // {
    //     title: 'Phòng ngừa',
    //     dataIndex: 'prevention',
    //     key: 'prevention',
    // },
    {
        key: 'operation',
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];

// Slider marks
const marks: SliderMarks = {
    30: '30°C',
    50: {
        style: {
            color: '#f50',
        },
        label: <strong>50°C</strong>,
    },
};

const SickPigs = () => {

    const { Option } = Select;

    const [dataSickPigs, setDataSickPigs] = React.useState<any[]>([]); // Dữ liệu bệnh lợn
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [editPigs, setEditPigs] = useState<DataItem>(); // Bệnh lợn đang được chỉnh sửa
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [isShowAddModal, setIsShowAddModal] = useState(false); // Trạng thái hiển thị modal thêm bệnh lợn
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Trạng thái hiển thị modal xóa bệnh lợn
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [idDeleteSickPigs, setIdDeleteSickPigs] = useState<string>(); // Id bệnh lợn đang được xóa
    const [warningSickPigs, setWarningSickPigs] = useState<DataItem>(); // Id bệnh lợn đang được cảnh báo
    const [isShowWarningModal, setIsShowWarningModal] = useState(false); // Trạng thái hiển thị modal cảnh báo
    const dispatch = useDispatchRoot()
    const [lstSymptom, setLstSymptom] = useState<any[]>([]); // Danh sách triệu chứng
    const { showModalAddSickPig } = useSelectorRoot(state => state.farm)
    const [isShowViewModal, setIsShowViewModal] = useState(false); // Trạng thái hiển thị modal xem bệnh lợn
    const history = useHistory();
    const user = userServices.get();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (isShowAddModal) {
            setShowModal(true)
        }
        else
            setShowModal(false)

    }, [isShowAddModal, temperature])

    useEffect(() => {
        if (showModalAddSickPig) {
            showAddModal()
        }
    }, [showModalAddSickPig])

    // Hàm thực hiện gán giá trị nhiệt độ cho bệnh lợn
    const handleTemperatureChange = (value: any) => {
        setTemperature(value);
    };

    // Hàm thực hiện khi nhấn nút thêm bệnh lợn
    const showAddModal = () => {
        // Gán bệnh lợn đang được chỉnh sửa là undefined
        setEditPigs(undefined);
        setTemperature([37, 39]);
        // Gán giá trị mặc định cho form
        form.setFieldsValue({
            diseaseName: null,
            symptom: [],
            ageDescription: null,
            temperature: [37, 39],
            definition: null,
            description: null,
            prevention: null,
        });

        // Hiển thị modal thêm bệnh lợn
        setIsShowAddModal(true);
    };

    // Hàm thực hiện khi nhấn nút xác nhận
    const handleOk = () => {
        setIsShowAddModal(false);
        dispatch(setShowModalAddSickPig(false));
    };

    const handleOkView = () => {
        setIsShowViewModal(false);
    }

    // Hàm thực hiện khi nhấn nút hủy
    const handleCancel = () => {
        form && form.resetFields();
        setIsShowAddModal(false);
        dispatch(setShowModalAddSickPig(false));
    };

    const handleCancelView = () => {
        setIsShowViewModal(false);
    }

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };



    // Hàm thực hiện khi nhấn nút edit trên bảng
    const handleEdit = (record: DataItem) => {
        // Gán bệnh lợn đang được chỉnh sửa là record
        console.log(record);

        setEditPigs(record);
        const tmpLowerBoundTemperature = record.lowerBoundTemperature ? record.lowerBoundTemperature : 37;
        const tmpUpperBoundTemperature = record.upperBoundTemperature ? record.upperBoundTemperature : 39;
        const tmp: any[] = []
        record.symptoms?.map((item: symptoms) => {
            console.log(item);
            tmp.push(item.id)
        });
        // console.log(tmp);
        console.log(tmpLowerBoundTemperature, tmpUpperBoundTemperature);
        setLowerTemperature(tmpLowerBoundTemperature);
        setUpperTemperature(tmpUpperBoundTemperature);
        setTemperature([tmpLowerBoundTemperature, tmpUpperBoundTemperature]);
        // Gán giá trị cho form là giá trị của bệnh lợn đang được chỉnh sửa
        form.setFieldsValue({
            diseaseName: record.name,
            symptom: tmp,
            ageDescription: record.ageDescription,
            temperature: [tmpLowerBoundTemperature, tmpUpperBoundTemperature],
            definition: record.definition,
            description: record.description,
            prevention: record.prevention,
        });
        setTimeout(() => {
            setIsShowAddModal(true);
        }, 1000);
        // Hiển thị modal thêm/sửa bệnh lợn
    };

    const handleView = (record: DataItem) => {
        // Gán bệnh lợn đang được chỉnh sửa là record
        console.log(record);

        setEditPigs(record);
        const tmpLowerBoundTemperature = record.lowerBoundTemperature ? record.lowerBoundTemperature : 37;
        const tmpUpperBoundTemperature = record.upperBoundTemperature ? record.upperBoundTemperature : 39;
        const tmp: any[] = []
        record.symptoms?.map((item: symptoms) => {
            console.log(item);
            tmp.push(
                <>
                    <p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p> <br />
                </>
            )
        });
        // console.log(tmp);

        setTemperature([tmpLowerBoundTemperature, tmpUpperBoundTemperature]);
        // Gán giá trị cho form là giá trị của bệnh lợn đang được chỉnh sửa
        form.setFieldsValue({
            diseaseName: record.name,
            symptom: tmp,
            ageDescription: record.ageDescription,
            temperature: [tmpLowerBoundTemperature, tmpUpperBoundTemperature],
            definition: record.definition,
            description: record.description,
            prevention: record.prevention,
        });

        // Hiển thị modal thêm/sửa bệnh lợn
        setIsShowViewModal(true);
    }

    // Hàm thực hiện khi xóa bệnh lợn
    const handleDelete = (record: DataItem) => {
        setIsShowDeleteModal(true);
        setIdDeleteSickPigs(record.id);

    };

    // Hàm thực hiện cảnh báo
    const handleWarning = (record: DataItem) => {
        setIsShowWarningModal(true);
        setWarningSickPigs(record);
    }

    // Hàm thực hiện khi nhấn nút xác nhận trên modal thêm/sửa bệnh lợn
    const onFinish = (values: any) => {
        // Lấy ra giá trị đã nhập trong form
        console.log(values);
        console.log(temperature);
        const req = {
            name: values.diseaseName,
            fromAge: 0,
            toAge: 0,
            ageDescription: values.ageDescription,
            symptomIds: values.symptom,
            lowerBoundTemperature: temperature[0],
            upperBoundTemperature: temperature[1],
            definition: values.definition,
            description: values.description,
            prevention: values.prevention,
            metaData: "",
            additionalProp1: {},
        }
        // Hiển thị loading
        setLoading(true);
        console.log(req);

        // Nếu bệnh lợn đang được chỉnh sửa thì gọi api sửa bệnh lợn
        if (editPigs) {
            // Gọi api sửa bệnh lợn
            axios.put('https://sit.api.pigman.com.vn/disease/' + editPigs.id, req)
                .then(response => {
                    setTimeout(() => {
                        // Hiển thị thông báo sửa thành công
                        notification['success']({
                            message: 'Sửa bệnh lợn thành công',
                            description:
                                'Đã sửa bệnh lợn vào cơ sở dữ liệu',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt modal thêm/sửa bệnh lợn
                        setIsShowAddModal(false);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            // Gọi api thêm bệnh lợn
            axios.post('https://sit.api.pigman.com.vn/disease', req)
                .then(response => {
                    console.log(response);

                    setTimeout(() => {
                        // Hiển thị thông báo thêm thành công
                        notification['success']({
                            message: 'Thêm bệnh lợn thành công',
                            description:
                                'Đã thêm bệnh lợn vào cơ sở dữ liệu',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt modal thêm/sửa bệnh lợn
                        setIsShowAddModal(false);
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                });
        }

    };

    // lấy dữ liệu từ api
    const fetchData = async (name: string) => {
        // Gọi api lấy dữ liệu
        axios.get(name ? `https://sit.api.pigman.com.vn/disease?name=${name}&offset=0&size=1000` : `https://sit.api.pigman.com.vn/disease?offset=0&size=1000`)
            .then(req => {
                // console.log(req.data.data.items);
                if (req.data.statusCode === "OK") {
                    // Gán dữ liệu lấy được vào biến data
                    const data = req.data.data.items.map((item: any, index: number) => {
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        const symptomsNameLst = item.symptoms?.map((item: any) => {
                            return item.name.charAt(0).toUpperCase() + item.name.slice(1)
                        })
                        console.log(symptomsNameLst);

                        const symptomsName = "\"" + symptomsNameLst?.join("\". \"") + "\"";
                        const temperatureTmp = item.lowerBoundTemperature ? `${item.lowerBoundTemperature}°C - ${item.upperBoundTemperature}°C` : "37°C - 39°C";
                        const ageDescriptionTmp = item.ageDescription ? item.ageDescription : "Ở mọi lứa tuổi đều có thể mắc";
                        return {
                            ordinalNumber: index + 1,
                            diseaseName: item.name,
                            symptom: symptomsName,
                            ageDescription: ageDescriptionTmp,
                            temperature: temperatureTmp,
                            // definition: item.definition,
                            // description: item.description,
                            // prevention: item.prevention,
                            operation:
                                <Space key={index} style={{ alignItems: 'end' }}>
                                    <Tooltip title="Xem">
                                        <AiOutlineEye style={{ fontSize: 18 }} onClick={() => handleView(item)} />
                                    </Tooltip>
                                    {user.role === "ADMIN" &&
                                        <Tooltip title="Sửa">
                                            <EditOutlined onClick={() => handleEdit(item)} rev={undefined} />
                                        </Tooltip>
                                    }
                                    {user.role === "ADMIN" &&
                                        <Tooltip title="Xóa">
                                            <DeleteOutlined onClick={() => handleDelete(item)} rev={undefined} />
                                        </Tooltip>
                                    }
                                    <Tooltip title="Cảnh báo">
                                        <FlagOutlined className={item.warning ? 'warning' : ''} onClick={() => handleWarning(item)} rev={undefined} />
                                    </Tooltip>
                                </Space>
                        }
                    });
                    // Gán dữ liệu vào biến dataSickPigs
                    setDataSickPigs(data);
                }
                else {
                    // Hiển thị thông báo thêm thành công
                    notification['error']({
                        message: 'Tìm kiếm không thành công',
                        description:
                            'Không có bệnh lợn nào được tìm thấy',
                    });
                }
            }
            )
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        fetchData('');
        getLstSymptom();
    }, []);

    const getLstSymptom = async () => {
        axios.get(`https://sit.api.pigman.com.vn/symptom`).then(req => {
            console.log(req.data.data);
            const tmp = req.data.data.map((item: any) => {
                item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1)
                return item;
            });
            console.log(tmp);

            setLstSymptom(tmp);
        });
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataSickPigs.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const handleCancelDeleteModal = () => {
        setIsShowDeleteModal(false);
    }
    const handleCancelWarningModal = () => {
        setIsShowWarningModal(false);
    }
    const handleDeleteSubmit = () => {
        // Hiển thị loading
        setLoading(true);

        // Gọi api xóa bệnh lợn
        axios.delete('https://sit.api.pigman.com.vn/disease/' + idDeleteSickPigs)
            .then(() => {
                setTimeout(() => {
                    setIsShowDeleteModal(false);
                    // Hiển thị thông báo xóa thành công
                    notification['success']({
                        message: 'Xóa bệnh lợn thành công',
                        description:
                            'Đã xóa bệnh lợn khỏi cơ sở dữ liệu',
                    });
                    // Gọi hàm fetchData để lấy lại dữ liệu
                    fetchData('');
                    // Tắt loading
                    setLoading(false);
                }, 1000);
            });
    }

    const handleWarningSubmit = () => {
        // Hiển thị loading
        setLoading(true);

        if (warningSickPigs?.warning === true) {
            // Gọi api xóa bệnh lợn
            axios.post(`https://sit.api.pigman.com.vn/disease/warning?diseaseId=${warningSickPigs.id}&warning=false`)
                .then(() => {
                    setTimeout(() => {
                        setIsShowWarningModal(false);
                        // Hiển thị thông báo xóa thành công
                        notification['success']({
                            message: 'Hủy cảnh báo thành công',
                            description:
                                'Đã hủy cảnh báo bệnh lợn khỏi cơ sở dữ liệu',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                });
        }
        else {
            // Gọi api xóa bệnh lợn
            axios.post(`https://sit.api.pigman.com.vn/disease/warning?diseaseId=${warningSickPigs?.id}&warning=true`)
                .then(() => {
                    setTimeout(() => {
                        setIsShowWarningModal(false);
                        // Hiển thị thông báo xóa thành công
                        notification['success']({
                            message: 'Thêm cảnh báo thành công',
                            description:
                                'Đã thêm cảnh báo bệnh lợn trong cơ sở dữ liệu',
                        });
                        // Gọi hàm fetchData để lấy lại dữ liệu
                        fetchData('');
                        // Tắt loading
                        setLoading(false);
                    }, 1000);
                });
        }
    }
    // Hàm thực hiện tìm kiếm bệnh theo tên
    const onSearch = (value: string) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        setTimeout(() => {
            // Load dữ liệu
            fetchData(value);
            // Tắt loading
            setLoading(false);
        }, 1000);
    }

    const handleChangeSelectSymptom = (value: any) => {
        console.log(value);

    }

    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <p>Không phải triệu chứng bạn cần tìm ?</p>
            <Button onClick={handleAddSymptom}>Thêm triệu chứng</Button>
        </div>
    );

    const handleAddSymptom = () => {
        const tmp = document.getElementsByClassName('ant-select-selection-search-input') as HTMLCollectionOf<HTMLInputElement>;
        console.log(tmp[0].value);
        const req = {
            name: tmp[0].value,
            additionalProp1: {},
        }
        axios.post(`https://sit.api.pigman.com.vn/symptom`, req).then((res) => {
            console.log(res);
            notification['success']({
                message: 'Thêm triệu chứng thành công',
                description: 'Vui lòng chọn lại triệu chứng'
            });
            getLstSymptom();
        });
    }

    const handleKeyPress = (e: any) => {
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode === 44 || charCode === 45 || charCode === 101) {
            e.preventDefault();
        }
    };

    const [error, setError] = useState<any>('');
    const [lowerTemperature, setLowerTemperature] = useState<any>('');
    const [upperTemperature, setUpperTemperature] = useState<any>('');
    const handleTemperatureLowerBoundChange = (e: any) => {
        // console.log('value', value.target.value);
        let value = parseFloat(e.target.value);
        console.log(value);

        if (value < 0) value = 0;
        if (value > 50) value = 50;

        if (value > temperature[1]) {
            setError('Nhiệt độ không hợp lệ');
            setLowerTemperature(value);
            // notification.error({
            //     message: 'Nhiệt độ không hợp lệ',
            //     description: 'Nhiệt độ phải nhỏ hơn nhiệt độ trên',
            // });
        }
        else {
            setLowerTemperature(value);
            // setTemperature([value, temperature[1]]);
            setError('');
        }
    }


    const handleTemperatureUpperBoundChange = (e: any) => {
        // console.log('value', value.target.value);
        let value = parseFloat(e.target.value);
        console.log(value);

        if (value < 0) value = 0;
        if (value > 50) value = 50;

        if (value < temperature[0]) {
            setError('Nhiệt độ không hợp lệ');
            setUpperTemperature(value);
            // notification.error({
            //     message: 'Nhiệt độ không hợp lệ',
            //     description: 'Nhiệt độ phải lớn hơn nhiệt độ dưới',
            // });
        }
        else {
            setUpperTemperature(value);
            // setTemperature([temperature[0], value]);
            setError('');
        }
    }

    useEffect(() => {
        console.log(lowerTemperature, upperTemperature);

        if (lowerTemperature !== '' && upperTemperature !== '') {
            setTemperature([lowerTemperature, upperTemperature]);
        }
    }, [lowerTemperature, upperTemperature])
    const exportToExcel = () => {
        // Map over sortData to create a new array with the desired column names
        const dataWithVietnameseKeys = dataSickPigs.map((item: any) => ({
            'STT': item.ordinalNumber,
            'Tên bệnh': item.diseaseName,
            'Triệu chứng': item.symptom,
            'Độ tuổi': item.ageDescription,
            'Nhiệt độ': item.temperature,
            // 'Định nghĩa': item.definition,
            // 'Mô tả': item.description,
            // 'Phòng ngừa': item.prevention,
        }));

        const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
        ws['!cols'] = [
            { width: 5 },
            { width: 50 },
            { width: 50 },
            { width: 30 },
            { width: 30 },
            // { width: 30 },
            // { width: 30 },
            // { width: 30 },
        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Danh sach benh cua Lon.xlsx");
    };
    return (
        <>
            <div className='sick-pig-main' style={{ paddingTop: 0 }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <span>Bệnh lợn</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách bệnh của lợn</Breadcrumb.Item>
                </Breadcrumb>
                <div className='button-sick-pig'>
                    <Search placeholder="Tìm kiếm bệnh lợn theo tên" onSearch={onSearch} enterButton allowClear />
                    {user.role === 'ADMIN' &&
                        <Button onClick={showAddModal}>Thêm bệnh lợn</Button>
                    }
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-sick-pig'>
                        <Table
                            dataSource={dataSickPigs}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                        <div className='button-excel'>
                            <Button disabled={(dataSickPigs && dataSickPigs.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                        </div>
                    </div>
                </Spin>
            </div>
            <div className='modal-view-pig'>
                <Modal
                    className='modal-add-sick-pig modal-view'
                    visible={isShowViewModal}
                    title={'Xem bệnh lợn'}
                    onCancel={handleCancelView}
                    onOk={handleOkView}
                    maskClosable={true}
                    footer={false}
                    width={1000}
                >

                    <Form layout="vertical" form={form}>
                        <Form.Item name="diseaseName" label="Tên bệnh" rules={[{ required: true, message: 'Vui lòng nhập tên bệnh' }]}>
                            <div>{form.getFieldValue('diseaseName')}</div>
                        </Form.Item>
                        <ConfigProvider renderEmpty={customizeRenderEmpty}>
                            <Form.Item name="symptom" label="Triệu chứng" rules={[{ required: true, message: 'Vui lòng nhập biểu hiện' }]}>
                                {form.getFieldValue('symptom')?.map((item: any, index: any) => {
                                    return (
                                        <>
                                            <ul className='ui-view-modal'>
                                                <li key={index}>{item}</li>
                                            </ul>
                                        </>
                                        // <p key={index}>{item}</p>
                                    )
                                })}
                            </Form.Item>
                        </ConfigProvider>
                        <Form.Item name="ageDescription" label="Độ tuổi" rules={[{ required: true, message: 'Vui lòng nhập độ tuổi' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('ageDescription')}</div>
                        </Form.Item>
                        <Form.Item label="Nhiệt độ" name='temperature' rules={[{ required: true, message: 'Vui lòng chọn nhiệt độ' }]}>
                            {/* <Slider disabled={true} defaultValue={[37, 39]} min={30} max={50} range marks={marks} onChange={handleTemperatureChange} /> */}
                            {temperature?.map((item: any, index: any) => {

                                return (
                                    <>
                                        {index === 0 ? <span key={index}>Từ {item}°C </span> : <span key={index}>Đến {item}°C</span>}
                                    </>
                                )
                            })}
                        </Form.Item>
                        <Form.Item name="definition" label="Định nghĩa về loại bệnh" rules={[{ required: true, message: 'Vui lòng nhập định nghĩa về loại bệnh đó' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('definition')}</div>
                        </Form.Item>
                        <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('description')}</div>
                        </Form.Item>
                        <Form.Item name="prevention" label="Phòng ngừa" rules={[{ required: true, message: 'Vui lòng nhập phòng ngừa' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('prevention')}</div>
                        </Form.Item>
                        {/* <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div> */}
                    </Form>
                </Modal>
            </div>
            <Modal
                className='modal-add-sick-pig'
                visible={showModal}
                title={editPigs !== undefined ? 'Sửa bệnh lợn' : 'Thêm bệnh lợn'}
                onCancel={handleCancel}
                onOk={handleOk}
                maskClosable={true}
                footer={false}
            >

                <Form layout="horizontal" onFinish={onFinish} form={form}>
                    <Form.Item name="diseaseName" label="Tên bệnh" rules={[{ required: true, message: 'Vui lòng nhập tên bệnh' }]}>
                        <Input placeholder='Nhập tên bệnh' />
                    </Form.Item>
                    <ConfigProvider renderEmpty={customizeRenderEmpty}>
                        <Form.Item name="symptom" label="Triệu chứng" rules={[{ required: true, message: 'Vui lòng nhập biểu hiện' }]}>
                            {/* <TextArea
                            placeholder="Nhập triệu chứng (vd: sốt, ốm, bỏ ăn...)"
                            autoSize={{ minRows: 6, maxRows: 10 }}
                        /> */}
                            <Select
                                mode="multiple"
                                showSearch
                                // allowClear
                                // style={{ width: '100%' }}
                                placeholder="Vui lòng chọn triệu chứng"
                                // defaultValue={['a10', 'c12']}
                                onChange={handleChangeSelectSymptom}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                suffixIcon={<CaretDownOutlined rev={undefined} />}

                            >
                                {lstSymptom?.map((item, index) => (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </ConfigProvider>
                    <Form.Item name="ageDescription" label="Độ tuổi" rules={[{ required: true, message: 'Vui lòng nhập độ tuổi' }]}>
                        <Input placeholder='Nhập độ tuổi' />
                    </Form.Item>
                    <Form.Item label="Nhiệt độ " name="temperature" rules={[{ required: true, message: 'Vui lòng chọn nhiệt độ' }]}>
                        {/* <Slider defaultValue={[37, 39]} min={30} max={50} range marks={marks} onChange={handleTemperatureChange} /> */}
                        <div className='temp-lst'>
                            <div style={{ position: 'relative' }}>
                                <Input
                                    type='number'
                                    onKeyPress={handleKeyPress}
                                    defaultValue={temperature[0] ? temperature[0] : 37}
                                    value={temperature[0]}
                                    onChange={handleTemperatureLowerBoundChange}
                                    onBlur={handleTemperatureLowerBoundChange}
                                />
                                <span style={{ position: 'absolute', right: '15px', top: '17%' }}>°C</span>
                            </div>
                            <div> - </div>
                            <div style={{ position: 'relative' }}>
                                <Input
                                    type='number'
                                    onKeyPress={handleKeyPress}
                                    defaultValue={temperature[1] ? temperature[1] : 39}
                                    value={temperature[1]}
                                    onChange={handleTemperatureUpperBoundChange}
                                    onBlur={handleTemperatureUpperBoundChange}
                                />
                                <span style={{ position: 'absolute', right: '15px', top: '17%' }}>°C</span>
                            </div>
                        </div>
                        {error && <div className='error'>{error}</div>}

                    </Form.Item>
                    <Form.Item name="definition" label="Định nghĩa về loại bệnh" rules={[{ required: true, message: 'Vui lòng nhập định nghĩa về loại bệnh đó' }]}>
                        <TextArea placeholder='Nhập định nghĩa' autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                    <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}>
                        <TextArea placeholder='Nhập mô tả' autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                    <Form.Item name="prevention" label="Phòng ngừa" rules={[{ required: true, message: 'Vui lòng nhập phòng ngừa' }]}>
                        <TextArea placeholder='Nhập phòng ngừa' autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                    <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit" disabled={error ? true : false}>
                            Cập nhật
                        </Button>

                    </div>
                </Form>
            </Modal>
            <Modal
                visible={isShowDeleteModal}
                title='Xóa bệnh lợn'
                onCancel={handleCancelDeleteModal}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                <div>Bạn có chắc chắn muốn xóa bệnh lợn này?</div>
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancelDeleteModal}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleDeleteSubmit}>
                        Đồng ý
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={isShowWarningModal}
                title='Cảnh báo bệnh lợn'
                onCancel={handleCancelWarningModal}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                {warningSickPigs?.warning === true ?
                    <div>Bạn có chắc chắn muốn xóa cảnh báo bệnh lợn này?</div>
                    :
                    <div>Bạn có chắc chắn muốn thêm cảnh báo bệnh lợn này?</div>
                }
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancelWarningModal}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleWarningSubmit}>
                        Xác nhận
                    </Button>
                </div>
            </Modal >
        </>
    )
}

export default SickPigs