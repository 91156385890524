/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'
import { ICam } from '@type/cam';

export const createCam = (input: ICam) => {
    return fetcher.post(`${CFG_BASE_URL}/camera`, input as any)
}

export const updateCam = (camId: any, input: ICam) => {
    return fetcher.put(`${CFG_BASE_URL}/camera/${camId}`, input as any)
}

export const removeCam = (camId: string) => {
    return fetcher.delete(`${CFG_BASE_URL}/camera/${camId}`);
}

export const getAll = (pendId: string) => {
    return fetcher.get(`${CFG_BASE_URL}/camera/pen?penId=${pendId}`);
}