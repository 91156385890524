import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';

import './style.gatewayfarm.scss';
import { Breadcrumb, Button, Select, Spin, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import userServices from '@services/user';
import * as XLSX from 'xlsx';
import { apiPen } from '@src/src/app/api/pen';
import farmService from '@services/farm';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'penName',
    },
    {
        title: 'Nhiệt độ (°C)',
        dataIndex: 'temperature',
    },
    {
        title: 'Độ ẩm (%)',
        dataIndex: 'humidity',
    },
    {
        title: 'Áp suất (hPa)',
        dataIndex: 'pressure',
    },
    {
        title: 'Thời gian đo',
        dataIndex: 'time',
    },
    {
        title: 'Gateway',
        dataIndex: 'gatewayCode',
    }
];
const Option = Select.Option;
const GatewayPen: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstFarm, setLstFarm] = useState<any>([]);
    const [farmId, setFarmId] = useState<any>(null);
    const user = userServices.get();
    const currentFarm = farmService.getCurrentFarm();
    const [pens, setPens] = useState<any>([]); // Danh sách chuồng nuôi [
    const { penID } = useParams<any>();
    const history = useHistory();
    const [penName, setPenName] = useState<any>(''); // Tên chuồng nuôi [

    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user

    useEffect(() => {
        console.log(penID);
        getAllPens();
    }, []);


    useEffect(() => {
        const penName = pens?.find((item: any) => item.gatewayCode === penID)?.name;
        penID && setPenName(penName);
        setLoading(true);
        fetchData(1);
    }, [pens]);

    const fetchData = async (page: any) => {
        // Mở loading
        setLoading(true);
        console.log(page);
        const url = `https://sit.api.pigman.com.vn/farms/environmentData/getEnvironmentData?gatewayCode=${penID}&page=${(page - 1)}&limit=${pageSize}`

        console.log(url);

        await axios.get(url)
            .then((response) => {
                console.log(response.data);
                let count = pageSize * (page - 1);
                setTotalRecords(response.data.data.total)
                setData(
                    response.data.data.items.map((obj: any) => {
                        count += 1;
                        // Lấy ra tên trang trại theo gatewayCode của mảng trang trại
                        if (!lstFarm) return;
                        const penName = pens?.find((item: any) => item.gatewayCode === obj.gateway)?.name;
                        return {
                            key: count, // STT (cột đầu tiên)
                            penName: penName || 'Không xác định', // Tên trang trại
                            gatewayCode: obj.gateway,
                            temperature: obj.temperature,
                            humidity: obj.humidity,
                            pressure: obj.pressure,
                            time: moment(obj.time).format('DD/MM/YYYY HH:mm:ss'),
                            gateway: obj.gateway,
                        };
                    }),
                );
            })
            .then(() => {
                setLoading(false);
            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }


    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);

        // Gán giá trị trang hiện tại và số bản ghi trên một trang ngay lập tức
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);

        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
            fetchData(page);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalRecords,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };


    const handleChangeFarmList = (value: any) => {
        setFarmId(value);
        setCurrentPage(1);
    }
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter.toLowerCase()));
    const exportToExcel = async () => {
        const url = `https://sit.api.pigman.com.vn/farms/environmentData/getEnvironmentData?gatewayCode=${penID}&page=${0}&limit=${10000}`

        await axios.get(url)
            .then((response) => {

                const data = response.data.data.items.map((obj: any, index: any) => {
                    // Lấy ra tên trang trại theo gatewayCode của mảng trang trại
                    if (!lstFarm) return;
                    const penName = pens?.find((item: any) => item.gatewayCode === obj.gateway)?.name;
                    return {
                        key: index + 1, // STT (cột đầu tiên)
                        penName: penName || 'Không xác định', // Tên trang trại
                        gatewayCode: obj.gateway,
                        temperature: obj.temperature,
                        humidity: obj.humidity,
                        pressure: obj.pressure,
                        time: moment(obj.time).format('DD/MM/YYYY HH:mm:ss'),
                    };
                })

                // Map over sortData to create a new array with the desired column names
                const dataWithVietnameseKeys = data.map((item: any) => ({
                    'STT': item.key,
                    'Tên chuồng': item.penName,
                    'Nhiệt độ (°C)': item.temperature,
                    'Độ ẩm (%)': item.humidity,
                    'Áp suất (hPa)': item.pressure,
                    'Thời gian đo': item.time,
                }));

                const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
                ws['!cols'] = [
                    { width: 5 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                ];
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

                // Chuyển đổi currentFarm.name từ có dấu thành không dấu
                const str = currentFarm.name;
                const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                // Chuyển đổi penSelected.name từ có dấu thành không dấu
                const str2 = penName;
                const str3 = str2?.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                XLSX.writeFile(wb, `Danh sach moi truong chuong nuoi cua Lon_${str1}_${str3}.xlsx`);
            })
            .then(() => {
                setLoading(false);
            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));

    };

    const getAllPens = async (isDelete = false) => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm?.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };
        console.log(params);

        await apiPen
            .getAllPen(params)
            .then((res: any) => {
                console.log(res.items);
                setPens(res.items);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <div className="farmList-body">
                <div className='main-breadcrumb'>
                    <Breadcrumb >
                        <Breadcrumb.Item>
                            <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span onClick={() => history.push(`/quan-ly-chuong`)}>Chuồng nuôi</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <span >Tên chuồng: {penName}</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Môi trường chuồng nuôi</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="button-farmList">
                    <p className="farmList-h2">Môi trường chuồng nuôi</p>
                    <div className="search-and-sort">
                        {/* <Search placeholder="Tìm kiếm theo trang trại" onSearch={onSearch}
                            onChange={(event) => setFilter(event.target.value)}
                            enterButton
                            allowClear
                        /> */}
                        {/* <Select
                            className='select-behaviour-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                            onChange={handleChangeFarmList}
                            placeholder="Lọc theo trang trại"
                        >
                            {lstFarm?.map((item: any, index: any) => (
                                <Option key={index} value={item.gatewayCode} title={item.name}>{item.name}</Option>
                            ))}
                        </Select> */}
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className="table-farmList">
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        <div className='button-excel'>
                            <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default GatewayPen;
