/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC } from "react";
import './doubleArrow.scss';
import leftArrow from '@assets/leftArrow.png';
import downArrow from '@assets/downArrow.png';
import upArrow from '@assets/upArrow.png';
import rightArrow from '@assets/rightArrow.png';
import { ArrowDirection } from "./doubleArrow.types";

export interface DoubleArrowStyle {
    top: number,
    left: number,
    zIndex?: number
}

export interface IDoubleArrowDetail {
    doubleArrowDetailProps: {
        visible?: boolean,
        doubleArrowStyle?: DoubleArrowStyle,
        direction?: ArrowDirection
    }
}

const DoubleArrow: FC<IDoubleArrowDetail> = ({ doubleArrowDetailProps }: IDoubleArrowDetail) => {
    const { visible, doubleArrowStyle, direction } = doubleArrowDetailProps;
    const onSelectedChange = (value: any) => {
        console.log(value);
    }

    const calculateOffsetLeft = (): number => {
        let offsetLeft = doubleArrowStyle?.left ?? 1;
        const width = 420;
        const padding = 10;
        if (direction === 'leftArrow') {
            offsetLeft -= width / 2 + padding * 4;
        }
        else if (direction === 'downArrow') {
            offsetLeft -= padding;
        }
        else if (direction === 'rightArrow') {
            offsetLeft += (width + padding) / 2;
        }
        else {
            offsetLeft -= padding;
        }
        return offsetLeft;
    }

    const calculateOffsetTop = (): number => {
        let offsetTop = doubleArrowStyle?.top ?? 1;
        const height = 240;
        const padding = 10;
        if (direction === 'leftArrow') {
            offsetTop += height / 2 - 10;
        }
        else if (direction === 'downArrow') {
            offsetTop += height + padding;
        }
        else if (direction === 'rightArrow') {
            offsetTop += height / 2;
        }
        else {
            offsetTop -= padding;
        }
        return offsetTop;
    }

    return (
        <div className={`main-arrow`} style={{ top: calculateOffsetTop(), left: calculateOffsetLeft(), visibility: visible ? 'visible' : 'hidden' }}>
            <div className={`${direction !== 'leftArrow' ? 'hidden-arrow' : 'left-arrow'}`}>
                <img className='leftArrow' src={leftArrow} alt='leftArrow' />
                <img className='leftArrow' src={leftArrow} alt='leftArrow' />
            </div>
            <div className={`${direction !== 'upArrow' ? 'hidden-arrow' : 'up-arrow'}`}>
                <img className='upArrow' src={upArrow} alt='upArrow' />
                <img className='upArrow' src={upArrow} alt='upArrow' />
            </div>
            <div className={`${direction !== 'rightArrow' ? 'hidden-arrow' : 'right-arrow'}`}>
                <img className='rightArrow' src={rightArrow} alt='rightArrow' />
                <img className='rightArrow' src={rightArrow} alt='rightArrow' />
            </div>
            <div className={`${direction !== 'downArrow' ? 'hidden-arrow' : 'down-arrow'}`}>
                <img className='downArrow' src={downArrow} alt='downArrow' />
                <img className='downArrow' src={downArrow} alt='downArrow' />
            </div>
        </div>
    )
};

export default DoubleArrow;