/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { IGetCameraManage } from '@src/src/common/models/camera-model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from "rxjs/operators";
import { CFG_BASE_URL } from '../../constants/config';
import HttpClient from "../http-client";
export default class CameraAPI {
    static host = CFG_BASE_URL;

    static getListCamera(param: string): Observable<IGetCameraManage[] | []> {
        return HttpClient.get(`${CameraAPI.host}/`).pipe(
            map((res) => res as IGetCameraManage[] || [])
        );
    }
}