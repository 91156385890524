import { CFG_SIZE_PAGINATION } from '@constants/config';

const calculateNumberPage = (totalRecords: number): number => {
    if (totalRecords <= 0) return 1;
    return Math.ceil(totalRecords / CFG_SIZE_PAGINATION);
}

const calculateOffset = (pageNumber: number): number => {
    return (CFG_SIZE_PAGINATION * (pageNumber - 1));
}

export const UtilPage = {
    calculateNumberPage,
    calculateOffset
}