/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from '@api/sender';
import { CFG_BASE_URL } from '@constants/config'
import { IBehaviourSearchInput } from '@type/behaviour';

export const searchBeHaviour = ({ pigAIId, penId, startDate, endDate }: IBehaviourSearchInput) => {
    return fetcher.get(`${CFG_BASE_URL}/behaviours/pigAIId/${pigAIId}`, { penId, startDate, endDate });
};

export const getBeHaviourStatistic = (penId: string, params: any) => {
    return fetcher.get(`${CFG_BASE_URL}/behaviours/statistic/${penId}`, params);
};

export const getBeHavioursOfAPen = (payload: any) => {
    return fetcher.post(`${CFG_BASE_URL}/behaviours/behaviourInPen`, payload);
};