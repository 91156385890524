/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'
import { ISearchWithName } from '@type/common';

export const apiFood = {
    getAll(params: any) {
        return fetcher.get(`${CFG_BASE_URL}/food`, params);
    },
    createFood(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/food`, payload);
    },
    deleteFood(foodId: string) {
        return fetcher.delete(`${CFG_BASE_URL}/food/${foodId}`);
    },
    updateFood(foodId: any, payload: any) {
        return fetcher.put(`${CFG_BASE_URL}/food/${foodId}`, payload);
    },
};

export const getFoods = (offset: ISearchWithName) => {
    return fetcher.get(`${CFG_BASE_URL}/food`, offset);
}
