/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, FC } from 'react';

export const Context = createContext({ changeLocation: { isChangeLocation: false, onChangeLocation: () => { console.log(1) } } });

const ContextProvider: FC = ({ children }: any) => {
    const [isChangeLocation, setIsChangeLocation] = React.useState<boolean>(false);

    const onChangeLocation = () => {
        setIsChangeLocation(!isChangeLocation);
    }

    return (
        <Context.Provider
            value={{ changeLocation: { isChangeLocation, onChangeLocation } }}
        >
            {children}
        </Context.Provider >
    )
}

export default ContextProvider;