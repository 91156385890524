/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from 'react';
import Header from '@components/common/header/header';
import Footer from '@components/common/footer/footer';
import { message, notification } from 'antd';
import userServices from '@services/user';
import ContextProvider from '@components/context/context';

type ChildProps = {
    children: FC
    isLoginRequired: boolean
    routesProps: any
};

const ClientLayout: FC<ChildProps> = ({ children, isLoginRequired, routesProps }: ChildProps) => {
    const [isUpdateLocation, setIsUpdateLocation] = React.useState<boolean>();

    React.useEffect(() => {
        if (isLoginRequired && !userServices.get()) {
            notification.error({
                message: 'Bạn chưa đăng nhập',
            } as any);
            routesProps.history.push('/');
        }
    }, [])

    // React.useEffect(() => { console.log(1) }, [context.changeLocation.isChangeLocation]);

    const updateLocation = () => {
        setIsUpdateLocation(!isUpdateLocation);
    }

    return (
        <ContextProvider>
            <div id='body'>
                <Header updateLocation={updateLocation} />
                {children}
                <Footer />
            </div>
        </ContextProvider>
    );
};

export default ClientLayout;
