import { DeleteOutlined, EditOutlined, FlagOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Select, Space, Spin, Table, Tooltip, DatePicker, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import notification from 'antd/lib/notification';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './style.report.scss';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
import { saveAs } from 'file-saver';
import farmServices from '@services/farm';
import moment from 'moment';
import { Context } from '@components/context/context';
import { CSVLink } from 'react-csv';
import userServices from '@services/user';
import * as XLSX from 'xlsx';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    tagId: number;
    penId: string;
    farmId: string;
    temperature: number;
    timestamp: string;
    id: string;
}

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'so_thu_tu',
        key: 'so_thu_tu',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'ten_chuong',
        key: 'ten_chuong',
    },
    {
        title: 'Mã tag',
        dataIndex: 'ma_tag',
        key: 'ma_tag',
    },
    {
        title: 'Mã thẻ',
        dataIndex: 'ma_the',
        key: 'ma_the',
    },
    {
        title: 'Thời gian bất thường',
        dataIndex: 'thoi_gian_bat_thuong',
        key: 'thoi_gian_bat_thuong',
    },
    {
        title: 'Nhiệt độ (°C)',
        dataIndex: 'nhiet_do',
        key: 'nhiet_do',
    },

];

const ReportTemPig = () => {
    const { Option } = Select;

    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const farm = farmServices.getCurrentFarm() as any;

    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const [lstTemp, setLstTemp] = useState<any[]>([]); // Danh sách nhiệt độ
    const [penSelected, setPenSelected] = useState<any>(null); // Chuồng đang được chọn
    const [startTime, setStartTime] = useState<any>(null); // Thời gian bắt đầu
    const [endTime, setEndTime] = useState<any>(null); // Thời gian kết thúc
    const [data, setData] = useState<DataItem[]>([]); // Dữ liệu bệnh lợn
    const [dataUnusual, setDataUnusual] = useState<any[]>([]); // Dữ liệu bệnh lợn
    const context = React.useContext(Context);
    const [dataExcel, setDataExcel] = useState<any[]>([]); // Dữ liệu xuất excel
    const user = userServices.get();
    const currentFarm = farmServices.getCurrentFarm() as any;

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // lấy dữ liệu từ api
    const fetchData = async () => {
        setLoading(true);
        await axios.get(`https://sit.api.pigman.com.vn/AbnormalTemperature?farmId=${farm.id}&size=10000&offset=0`)
            .then(res => {
                console.log(res.data.data);
                setData(res.data.data);
            })
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        loadLstPen();
        getTagByTagId();
    }, [context.changeLocation.isChangeLocation]);

    useEffect(() => {
        if (lstPen.length > 0 && lstTemp.length > 0) {
            fetchData();
        }
    }, [lstPen, lstTemp]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện lấy dữ liệu chuồng
    const loadLstPen = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${farm.id}?size=1000`)
            .then(res => {
                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    tmp.push({
                        id: item.id,
                        name: item.name
                    })
                })
                console.log(tmp);
                setLstPen(tmp);
            })
    }
    useEffect(() => {
        if (data.length > 0) {
            // Gán dữ liệu lấy được vào biến data

            // Sắp xếp dữ liệu theo thời gian bắt đầu
            const sortData = data.sort((a: any, b: any) => {
                return moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf();
            });
            const tmpData = sortData.map((item: DataItem, index: number) => {
                const penName = lstPen.find((pen: any) => pen.id === item.penId)?.name;
                let tagName = lstTemp.find((tag: any) => tag.tagId === item.tagId)?.name;
                tagName = tagName ? tagName : ('Heo ' + item.tagId);
                return {
                    so_thu_tu: index + 1,
                    ten_chuong: penName || 'Chưa xác định',
                    ma_tag: tagName,
                    ma_the: item.tagId,
                    thoi_gian_bat_thuong: moment(item.timestamp).format('DD/MM/YYYY HH:mm:ss'),
                    nhiet_do: item.temperature,
                }
            });
            // Gán dữ liệu vào biến dataTagPigs
            setDataUnusual(tmpData);
            setLoading(false);
            // const headerExcel = [
            //     'STT', 'Tên chuồng', 'Mã lợn của lợn', 'Thời gian bất thường', 'Biểu hiện bất thường'
            // ]

            // const bodyExcel = data.map((item: DataItem, index: number) => {
            //     return [
            //         index + 1,
            //         item.pen?.name || 'Chưa xác định',
            //         item.pigAIId,
            //         moment(item.startTime).format('DD/MM/YYYY') + " - " + moment(item.endTime).format('DD/MM/YYYY'),
            //         'Đi: ' + Math.round(item.runningRate) + '% Đứng: ' + Math.round(item.standingRate) + '% Nằm: ' + Math.round(item.liedownRate) + '% Ăn: ' + Math.round(item.eatingRate) + '%',
            //     ]
            // });

            // setDataExcel([headerExcel, ...bodyExcel]);
        }
        else {
            setDataUnusual([]);
            setLoading(false);
        }

    }, [data])
    const exportToExcel = () => {
        // Map over sortData to create a new array with the desired column names
        const dataWithVietnameseKeys = dataUnusual.map((item: any) => ({
            'STT': item.so_thu_tu,
            'Tên chuồng': item.ten_chuong,
            'Mã tag': item.ma_tag,
            'Mã thẻ': item.ma_the,
            'Thời gian bất thường': item.thoi_gian_bat_thuong,
            'Nhiệt độ (°C)': item.nhiet_do,
        }));

        const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
        ws['!cols'] = [
            { width: 5 },
            { width: 25 },
            { width: 25 },
            { width: 10 },
            { width: 25 },
            { width: 25 },
        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Chuyển đổi currentFarm.name từ có dấu thành không dấu
        const str = currentFarm.name;
        const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        XLSX.writeFile(wb, `Danh sach lon bat thuong nhiet do_${str1}.xlsx`);
    };

    const getTagByTagId = async () => {
        try {
            const res = await axios.get(`https://sit.api.pigman.com.vn/temperatureTag/farm/${currentFarm.id}?offset=0&size=1000`);
            console.log(res.data.data.items);
            setLstTemp(res.data.data.items);
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className='report-pig-main unusual-pig'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Báo cáo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn bất thường</Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn bất thường nhiệt độ</Breadcrumb.Item>
                </Breadcrumb>
                <div className='button-report-pig'>
                    {/* <Search placeholder="Tìm kiếm bệnh lợn theo tên" onSearch={onSearch} enterButton allowClear /> */}
                    {/* <div className='number-report-pig'>Số con: {data.length}</div> */}
                    <div className='search-report-pig'>
                        {/* <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} onChange={handleChangeDate} /> */}
                        {/* <Select
                            className='select-report-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={handleChangePenValue}
                            placeholder="Chọn tên chuồng"
                        >
                            {lstPen?.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select> */}
                        {/* <Button >Tìm kiếm</Button> */}
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-report-pig'>
                        <Table
                            dataSource={dataUnusual}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                    </div>
                </Spin>
                <div className='button-export'>
                    {/* <CSVLink data={dataExcel} headers={['Danh sách lợn bất thường']} filename={'báo cáo lợn bất thường.csv'}>
                        <Button type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </CSVLink> */}
                    <div className='button-excel'>
                        <Button disabled={(dataUnusual && dataUnusual.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportTemPig