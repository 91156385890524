/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
const handleKeyPress = (e: any) => {
    const charCode = e.which ? e.which : e.keyCode;

    if (charCode === 44 || charCode === 46 || charCode === 101) {
        e.preventDefault();
    }
};
export const supplierStepColumns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
        key: 'address',
    },
    // {
    //     title: 'Số lượng thức ăn',
    //     dataIndex: 'totalAvailableFood',
    //     key: 'totalAvailableFood',
    // },
];

export const supplierFoodConsumeDetail = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(value: any, item: any, index: any) {
            return <span> {value.food?.name} </span>;
        },
    },
    {
        title: 'Cân nặng',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span> {v.food?.foodType?.name} </span>;
        },
    },
    {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Đơn vị',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span> {v.food?.foodUnit?.name} </span>;
        },
    },
];
export const supplierFoodConsumeDetailPharmaceutical = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(value: any, item: any, index: any) {
            return <span> {value.pharmaceutical?.name} </span>;
        },
    },
    {
        title: 'Loại thuốc/vắc xin',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span> {v.pharmaceutical?.pharmaceuticalType?.name} </span>;
        },
    },
    {
        title: 'Số lượng còn trong kho',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Đơn vị',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span> {v.pharmaceutical?.pharmaceuticalUnit?.name} </span>;
        },
    },
];
export const penListColumns = (orderItems: any, exportFood: any) => {
    return [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: function renderIndex(value: any, item: any, index: any) {
                return ++index;
            },
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Diện tích (m2)',
            dataIndex: 'area',
            key: 'area',
        },
        // {
        //     title: 'Số lượng',
        //     dataIndex: 'capacity',
        //     key: 'capacity',
        // },
        {
            title: 'Cân nặng',
            key: 'index',
            render: function renderWeightType(value: any, item: any, index: any) {
                return value.weightType.name;
            },
        },
        {
            title: 'Xuất thức ăn',
            key: 'index',
            render: function renderExportFood(value: any, item: any, index: any) {
                return orderItems.map((v: any, i: number) => {
                    // const max = v.totalOrder ? v.quantity - v.totalOrder : v.quantity;
                    // console.log({ quantity: v.quantity, total: v.totalOrder, max });

                    if (!v.pharmaceutical) return;
                    return (
                        <div
                            className="ordered-input"
                            key={i}
                            style={{ display: 'flex', marginBottom: '6px', justifyContent: 'space-between' }}>
                            <span>{v.pharmaceutical?.name}</span> &#160;
                            <div className="ordered-input__change-order">
                                <input
                                    type="number"
                                    min={0}
                                    // max={v.quantity}
                                    onChange={exportFood(v, value)}
                                    onKeyPress={handleKeyPress}
                                />

                                {/* <span>/</span> */}
                                <span> {v.pharmaceutical?.pharmaceuticalUnit?.name}</span>
                            </div>
                        </div>
                    );
                });
            },
        },
    ];
};

export const consumeFood = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Tổng số thức ăn',
        dataIndex: 'quantity',
        key: 'quantity',
    },
];

export const penConsumeFood = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        dataIndex: 'penName',
        key: 'penName',
    },
    {
        title: 'Tổng số thức ăn',
        dataIndex: 'index',
        key: 'index',
        children: [
            {
                title: 'Tên thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.food?.name}</p>;
                    });
                },
            },
            {
                title: 'Số lượng',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.quantity}</p>;
                    });
                },
            },
            {
                title: 'Loại thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.food?.foodType?.name}</p>;
                    });
                },
            },
        ],
    },
];
export const penConsumePharmaceutical = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        dataIndex: 'penName',
        key: 'penName',
    },
    {
        title: 'Tổng số thuốc/vắc xin',
        dataIndex: 'index',
        key: 'index',
        children: [
            {
                title: 'Tên thuốc/vắc xin',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.pharmaceutical?.name}</p>;
                    });
                },
            },
            {
                title: 'Số lượng',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.quantity}</p>;
                    });
                },
            },
            {
                title: 'Loại thuốc/vắc xin',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItems.map((v: any, i: number) => {
                        return <p key={i}>{v.pharmaceutical?.pharmaceuticalType?.name}</p>;
                    });
                },
            },
        ],
    },
];
export const penConsumeFoodDetail = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'name',
        key: 'name',
        render: function renderPenName(value: any, item: any, index: any) {
            if (!item.pen) return 'Không xác định';
            return item?.pen?.name;
        }
    },
    {
        title: 'Tổng số thức ăn',
        dataIndex: 'index',
        key: 'index',
        children: [
            {
                title: 'Tên thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.food?.name}</p>;
                    });
                },
            },
            {
                title: 'Số lượng',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.quantity}</p>;
                    });
                },
            },
            {
                title: 'Loại thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penFoodConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.food?.foodType?.name}</p>;
                    });
                },
            },
        ],
    },
];
export const penConsumePharmaceuticalDetail = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'name',
        key: 'name',
        render: function renderPenName(value: any, item: any, index: any) {
            if (!item.pen) return 'Không xác định';
            return item?.pen?.name;
        }
    },
    {
        title: 'Tổng số thức ăn',
        dataIndex: 'index',
        key: 'index',
        children: [
            {
                title: 'Tên thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.pharmaceutical?.name}</p>;
                    });
                },
            },
            {
                title: 'Số lượng',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.quantity}</p>;
                    });
                },
            },
            {
                title: 'Loại thức ăn',
                dataIndex: 'index',
                key: 'index',
                render: function renderPenFoodConsume(value: any, item: any, index: any) {
                    return item.penPharmaceuticalConsumeItem.items.map((v: any, i: number) => {
                        return <p key={i}>{v.pharmaceutical?.pharmaceuticalType?.name}</p>;
                    });
                },
            },
        ],
    },
];
