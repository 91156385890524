import { DeleteOutlined, EditOutlined, FlagOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Select, Space, Spin, Table, Tooltip, DatePicker, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import notification from 'antd/lib/notification';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './style.report.scss';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
import { saveAs } from 'file-saver';
import farmServices from '@services/farm';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { Context } from '@components/context/context';
import { CSVLink } from 'react-csv';
import userServices from '@services/user';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    farmId: string,
    foodName: string,
    supplier: string,
    quantity: number,
    unit: string,
    useDue: string,
    note: string,
}


const ReportFoodInventory = () => {
    const [dataSickPigs, setDataSickPigs] = React.useState<any[]>([]); // Dữ liệu bệnh lợn
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [editPigs, setEditPigs] = useState<DataItem>(); // Bệnh lợn đang được chỉnh sửa
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [isShowAddModal, setIsShowAddModal] = useState(false); // Trạng thái hiển thị modal thêm bệnh lợn
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Trạng thái hiển thị modal xóa bệnh lợn
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [idDeleteSickPigs, setIdDeleteSickPigs] = useState<string>(); // Id bệnh lợn đang được xóa
    const [warningSickPigs, setWarningSickPigs] = useState<DataItem>(); // Id bệnh lợn đang được cảnh báo
    const [isShowWarningModal, setIsShowWarningModal] = useState(false); // Trạng thái hiển thị modal cảnh báo
    const farm = farmServices.getCurrentFarm() as any;
    const [dataFoodImport, setDataFoodImport] = useState<DataItem[]>([]); // Dữ liệu thức ăn nhập kho
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const context = React.useContext(Context);
    const [dataExcel, setDataExcel] = useState<any[]>([]); // Dữ liệu xuất excel
    const user = userServices.get();

    // Các cột của bảng
    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Tên loại thức ăn',
            dataIndex: 'foodName',
            key: 'foodName',
        },
        {
            title: 'Nhà cung cấp',
            dataIndex: 'supplier',
            key: 'supplier',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Đơn vị',
            dataIndex: 'unit',
            key: 'unit',
        },
    ];

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // lấy dữ liệu từ api
    const fetchData = async () => {
        axios.get(`https://sit.api.pigman.com.vn/report/foodInventory?farmId=${farm.id}`).then((res) => {
            console.log(res.data.data);

            const data = res.data.data.map((item: DataItem, index: any) => {
                return {
                    key: index + 1,
                    foodName: item.foodName,
                    supplier: item.supplier,
                    quantity: item.quantity,
                    unit: item.unit,
                    useDue: item.useDue,
                    note: item.note,
                };
            });

            setDataFoodImport(data);

            // Gán dữ liệu cho biến dataExcel
            const headerExcel = [
                'STT',
                'Tên thức ăn',
                'Nhà cung cấp',
                'Số lượng',
                'Đơn vị',
            ]

            const bodyExcel = data.map((item: DataItem, index: number) => {
                return [
                    index + 1,
                    item.foodName,
                    item.supplier,
                    item.quantity,
                    item.unit,
                ]
            });

            setDataExcel([headerExcel, ...bodyExcel]);
        });
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        fetchData();
    }, [context.changeLocation.isChangeLocation]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataFoodImport.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };


    const onSearch = (value: string) => {
        console.log(value);

    }

    const exportToExcel = async () => {
        try {
            const base64String = await axios.get(`https://sit.api.pigman.com.vn/report/reportFile?userId=${user.id}&farmId=${farm.id}&reportType=foodInventory&size=1000`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                })
            // use fs to convert the buffer to a file
            console.log(base64String);
            const binaryData = atob(base64String);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                view[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const str = farm.name;
            const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            saveAs(blob, `Bao cao thuc an ton kho_${str1}.xlsx`);
        } catch (error) {
            console.log(error);
        }
    };

    const sortData = filter.length === 0 ? dataFoodImport : dataFoodImport.filter((item: DataItem) => item.foodName.toLowerCase().includes(filter));

    return (
        <>
            <div className='report-pig-main food-pig'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Báo cáo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Thống kê thức ăn tồn kho</Breadcrumb.Item>

                </Breadcrumb>
                <div className='button-report-pig'>
                    {/* <Search placeholder="Tìm kiếm bệnh lợn theo tên" onSearch={onSearch} enterButton allowClear /> */}
                    {/* <div className='number-report-pig'>Số con: {dataFoodImport.length}</div> */}
                    <div className='search-report-pig'>
                        {/* <DatePicker style={{ width: 150 }} placeholder='Ngày nhập kho' onChange={handleChangeDate} /> */}
                        <Search
                            className='search-userList'
                            placeholder="Tìm kiếm theo tên thức ăn"
                            onChange={(event) => setFilter(event.target.value)}
                            onSearch={onSearch}
                            enterButton
                            allowClear

                        />
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-report-pig'>
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                    </div>
                </Spin>
                <div className='button-export'>
                    {/* <CSVLink data={dataExcel} headers={['Thống kê thức ăn tồn kho']} filename={'Thống kê thức ăn tồn kho.csv'}>
                        <Button type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </CSVLink> */}
                    <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>

                </div>
            </div>
        </>
    )
}

export default ReportFoodInventory