import React from 'react';
import './style.policy.scss'
const Policy = () => {
    return (
        <div className='privacy-policy-main'>
            <div className="header">
                <div className="title">Chính sách bảo mật</div>
            </div>
            <div className="body">
                <div className="title">
                    Tổng quan
                </div>
                <div className="content">
                    <p>
                        Cảm ơn bạn đã truy cập Trang web Pigman. Pigman là sản phẩm của đề tài “Nghiên cứu giải pháp công nghệ 4.0 trong giám sát hành vi, sức khỏe của lợn nhằm phòng chống và cảnh báo sớm dịch bệnh” được Trung tâm Nghiên cứu và chuyển giao công nghệ, Viện Hàn Lâm Khoa học và Công nghệ Việt Nam thực hiện.                     </p>
                    <p>
                        Chính sách bảo mật này để được tạo ra cung cấp cho Người dùng Pigman thông tin về cách Pigman thu thập và xử lý thông tin cá nhân khi Người dùng Pigman sử dụng các dịch vụ của phần mềm. Chính sách quyền riêng tư này được đưa vào và là một phần của Điều khoản và điều kiện của Pigman, điều chỉnh việc bạn sử dụng Trang web nói chung.                    </p>

                    <p>Tuyên bố về quyền riêng tư này chỉ bao gồm Pigman. Nó không bao gồm các trang web liên kết đến hoặc từ trang web này.</p>
                    <p>Pigman có thể sửa đổi nội dung của chính sách bằng cách đăng một bản sửa đổi lên hệ thống của Pigman, phiên bản sửa đổi có hiệu lực kể từ thời điểm đăng tải. Nếu Người dùng Pigman tiếp tục sử dụng Dịch vụ có nghĩa là Người dùng Pigman chấp nhận và chắc chắn đồng ý tuân theo Điều khoản sử dụng mới nhất được cập nhật. </p>
                </div>
                <div className="sub-title">1.Thông tin Pigman cần người dùng cung cấp</div>
                <div className="content">Khi Người dùng Pigman thực hiện đăng ký tạo tài khoản người dùng/sử dụng các dịch vụ của Pigman, người dùng có thể cung cấp cho Pigman thông tin nhận dạng hoặc các liên hệ (thông tin cá nhân), ví dụ như:</div>
                <ul style={{ marginLeft: 30 }}>
                    <li>•	Tên đầy đủ, địa chỉ email, số điện thoại</li>
                    <li>•	Tên trang trại, địa chỉ trang trại, quy mô</li>
                    <li>•	Tên camera</li>
                    <li>•	Link Stream URL</li>
                </ul>
                <div className="sub-title">2. Pigman sử dụng thông tin của Người dùng cho mục đích gì?</div>
                <div className="content">Pigman có thể sử dụng thông tin cá nhân của Người dùng Pigman:</div>
                <ul style={{ marginLeft: 30 }}>
                    <li>•	Để cung cấp các tính năng và chức năng của Pigman cho Người dùng</li>
                    <li>•	Để xác định thông tin nhận dạng hoặc các liên hệ</li>
                    <li>•	Để cải thiện, nâng cao và điều chỉnh các chức năng của Pigman cho phù hợp với Người dùng</li>
                    <li>•	Để thực hiện các hành động cụ thể mà Người dùng Pigman có thể yêu cầu.</li>
                    <li>•	Cho mục đích thống kê theo cách tổng hợp và ẩn danh.</li>
                </ul>
                <div className="sub-title">3. Pigman bảo vệ và lưu giữ thông tin cá nhân của Người dùng như thế nào?</div>
                <div className="content">
                    Pigman lưu giữ và xử lý thông tin cá nhân của người dùng trên máy chủ, Pigman bảo vệ nó bằng các biện pháp bảo vệ vật lý, điện tử bao gồm: tường lửa, mã hóa dữ liệu và thủ tục áp dụng theo quy định của luật bảo mật thông tin.                 </div>
                <div className="sub-title">4.	Pigman giữ dữ liệu của Người dùng trong bao lâu?</div>
                <div className="content">
                    Pigman lưu trữ dữ liệu của Người dùng trong toàn bộ thời gian hoạt động của tài khoản mà Người dùng đã đăng ký sử dụng. Khi thực hiện Xóa tài khoản, các dữ liệu của Người dùng cũng sẽ bị xóa khỏi Pigman.                    </div>
                <div className="sub-title">5.	Người dùng có thể truy cập dữ liệu cá nhân của mình như thế nào, xác minh tính chính xác của dữ liệu đó, nếu cần có thể sửa hoặc yêu cầu xóa dữ liệu đó?</div>
                <div className="content">
                    Người dùng Pigman là đơn vị duy nhất sở hữu và có trách nhiệm quản lý một cách an toàn các thông tin nhận biết, tài khoản quản trị, mật khẩu, nội dung website hay những thông tin khác liên quan đến tài khoản, website của mình. Người dùng Pigman có thể đăng nhập vào tài khoản của mình để chỉnh sửa thông tin.
                </div>
                <div className="content">
                    Người dùng Pigman cũng có thể xóa tài khoản của mình. Sau khi xóa tài khoản, Pigman sẽ xóa tài khoản của Người dùng khỏi máy chủ Trang web Pigman.                </div>
                <div className="content">
                    Trong trường hợp có vấn đề với quyền truy cập cá nhân vào hệ thống, người dùng có thể liên hệ với bộ xử lý quản lý trang web.                </div>
                <div className="sub-title">6.	Chúng tôi chia sẻ thông tin của Người dùng như thế nào?</div>
                <div className="content">
                    Pigman không thể tự chia sẻ thông tin cá nhân của Người dùng khi chưa được sự đồng ý.                </div>
                <div className="sub-title">7.	Bảo mật thông tin cá nhân của Người dùng</div>
                <div className="content">
                    Pigman thực hiện tất cả các bước hợp lý để bảo vệ tính bảo mật và tính toàn vẹn của tất cả thông tin cá nhân được cung cấp cho Pigman.
                </div>
            </div>
        </div >
    );
};

export default Policy;
