import React, { FC, useEffect, useState } from 'react';

// import DoughnutChart from '../chart/DoughnutChart';

import farm from '@assets/profile-page-farm.png';
import pig from '@assets/profile-page-pig.png';
import pigsty from '@assets/profile-page-pigsty.png';
import user from '@assets/profile-page-user.png';
import temp from '@assets/profile-temperature.png'
import beha from '@assets/profile-behaviour.png'

import './style.profile.scss';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

interface DataAdmin {
    ascendFarm: number,
    ascendPen: number,
    ascendPig: number,
    ascendUser: number,
    countFarm: number,
    countPen: number,
    countPig: number,
    countUser: number,
    countDieses: number,
    countModels: number,
    temperatureTagRecords: number,
    behaviourRecords: number,
    countPigAI: number
}

const exampleArr = [
    {
        name: 'Tổng số người dùng',
        number: 365,
        miniNoti: 'Tăng 8% so với tháng trước',
        className: 'overview-body-item-1 link',
        picture: user,
    },
    {
        name: 'Tổng số trang trại',
        number: 136,
        miniNoti: 'Giảm 8% so với tháng trước',
        className: 'overview-body-item-2 link',
        picture: farm,
    },
    {
        name: 'Tổng số chuồng',
        number: 586,
        miniNoti: 'Giảm 8% so với tháng trước',
        className: 'overview-body-item-3',
        picture: pigsty,
    },
    {
        name: 'Tổng số lợn',
        number: 1589,
        miniNoti: 'Giảm 8% so với tháng trước',
        className: 'overview-body-item-4',
        picture: pig,
    },
];

const Overview: FC = () => {

    const [dataAdmin, setDataAdmin] = useState<DataAdmin>();
    const [objectData, setObjectData] = useState<any[]>([]);
    const history = useHistory();
    useEffect(() => {
        getAllDataAdmin();
    }, []);

    useEffect(() => {
        setData();
    }, [dataAdmin]);

    const getAllDataAdmin = async () => {
        axios.get('https://sit.api.pigman.com.vn/admin')
            .then((res) => {
                setDataAdmin(res.data.data);
            })
    }

    const setData = () => {
        if (!dataAdmin) return;
        const tmp = [
            {
                name: 'CSDL người dùng',
                number: dataAdmin?.countUser,
                miniNoti: dataAdmin?.ascendUser > 0 ? `Tăng ${dataAdmin?.ascendUser}% so với tháng trước` : `Giảm ${dataAdmin?.ascendUser}% so với tháng trước`,
                className: 'overview-body-item-1',
                picture: user,
                link: '/profile/quan-ly-nguoi-dung',
            },
            {
                name: 'CSDL trang trại',
                number: dataAdmin?.countFarm,
                miniNoti: dataAdmin?.ascendFarm > 0 ? `Tăng ${dataAdmin?.ascendFarm}% so với tháng trước` : `Giảm ${dataAdmin?.ascendFarm}% so với tháng trước`,
                className: 'overview-body-item-2',
                picture: farm,
                link: '/profile/quan-ly-tat-ca-trang-trai',
            },
            {
                name: 'CSDL bệnh lợn',
                number: dataAdmin?.countDieses,
                miniNoti: dataAdmin?.ascendPen > 0 ? `Tăng ${dataAdmin?.ascendPen}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPen}% so với tháng trước`,
                className: 'overview-body-item-3',
                picture: pigsty,
                link: '/profile/quan-ly-benh-lon',
            },
            {
                name: 'CSDL cá thể lợn',
                number: dataAdmin?.countPigAI,
                miniNoti: dataAdmin?.ascendPig > 0 ? `Tăng ${dataAdmin?.ascendPig}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPig}% so với tháng trước`,
                className: 'overview-body-item-4',
                picture: pig,
                link: '/profile/ca-the-lon',
            },
            {
                name: 'CSDL thân nhiệt lợn',
                number: dataAdmin?.temperatureTagRecords,
                miniNoti: dataAdmin?.ascendPig > 0 ? `Tăng ${dataAdmin?.ascendPig}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPig}% so với tháng trước`,
                className: 'overview-body-item-5',
                picture: farm,
                link: '/profile/the-nhiet',
            },
            {
                name: 'CSDL hành vi lợn',
                number: dataAdmin?.behaviourRecords,
                miniNoti: dataAdmin?.ascendPig > 0 ? `Tăng ${dataAdmin?.ascendPig}% so với tháng trước` : `Giảm ${dataAdmin?.ascendPig}% so với tháng trước`,
                className: 'overview-body-item-6',
                picture: pig,
                link: '/profile/hanh-vi',
            },
        ]

        console.log(tmp);
        // Change number 1.000 10.000 to 10,000 100,000
        tmp.map((item: any) => {
            // Check if number is 10.300 to 10300 and 10.000 to 10000
            if (item.number.toString().includes('.')) {
                item.number = item.number.toString().replace('.', '');
            }
            item.number = item.number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        })
        console.log(tmp);

        setObjectData(tmp);
    }

    const handleChangeLink = (item: any) => () => {
        if (item.link) {
            history.push(item.link);
        }
    }

    return (
        <>
            <div className='overview-body'>
                {objectData.map((item) => {
                    return (
                        <div className={item.className} key={item.name} onClick={handleChangeLink(item)} >
                            <div className='overview-body-text'>
                                <div className='overview-body-text-small'>{item.name}</div>
                                <div className='overview-body-text-large'>{item.number}</div>
                                {/* <div className='overview-body-text-small'>
                                    {item.miniNoti.includes('Tăng')
                                        ? <ArrowUpOutlined style={{ paddingRight: '10px' }} />
                                        : <ArrowDownOutlined style={{ paddingRight: '10px' }} />}
                                    {item.miniNoti}
                                </div> */}
                            </div>
                            <img src={item.picture} alt='picture' style={{ maxWidth: '40%' }} />
                        </div>
                    );
                })}
            </div>

            {/* <div style={{ display: 'flex' }}>
                <DoughnutChart headerText='TỔNG USER' conclusionText='Tăng 8% so với tháng trước' centerText='568' subText='User' />
                <DoughnutChart headerText='TỔNG KHO' conclusionText='Tăng 5% so với tháng trước' centerText='18' subText='Trang trại' />
            </div> */}
        </>
    );
};

export default Overview;
