/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const SYSTEM_CONSTANTS = {
    CAMERA_STREAM: {
        STREAMING_CAMERA: (cameraId: string): string => `${cameraId}/streaming`,
        RTC_CAMERA: (clientUuid: string) => `camera/clientUuid/${clientUuid}/rtcStreaming`,
        RTC_CAMERA_NONE_AI: (clientUuid: string) => `camera/clientUuid/${clientUuid}/rtcStreamingNoneAI`,
        ANALYSIS_CAMERA: (cameraId: string) => `camera/${cameraId}/analyse`
    }
}

export default SYSTEM_CONSTANTS