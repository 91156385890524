/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const apiFoodSupplier = {
    getAll(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/foodSuppliers/farm`, payload);
    },
    createFoodSupplier(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/foodSuppliers`, payload);
    },
    deleteFoodSupplier(foodSupplierId: string) {
        return fetcher.delete(`${CFG_BASE_URL}/foodSuppliers/${foodSupplierId}`);
    },
    updateFoodSupplier(foodSupplierId: any, payload: any) {
        return fetcher.put(`${CFG_BASE_URL}/foodSuppliers/${foodSupplierId}`, payload);
    },
};