/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const createPenFoodConsume = (data: any) => {
    return fetcher.post(`${CFG_BASE_URL}/penFoodConsume`, data);
}
export const createPenPharmaceuticalConsume = (data: any) => {
    return fetcher.post(`${CFG_BASE_URL}/penPharmaceuticalConsume`, data);
}