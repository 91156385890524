interface IInitialMenu {
    name: string
    path: string
    isLoginRequired: boolean
    moreChildren?: IInitialMenu[]
}

export interface IMenu extends IInitialMenu {
    children?: IInitialMenu[]
}

export enum EAuthFormType {
    Login,
    Register,
}