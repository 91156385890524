/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import './pig.scss';

import EditIcon from '@material-ui/icons/Edit';
import Pagination from '@material-ui/lab/Pagination';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PageHeader from '@components/common/page-header/page-header';
// import Modal from '@material-ui/core/Modal';
import {
    EnhancedTableHead,
    initialDataList,
    useStyles,
    stableSort,
    getComparator,
    StyledTableRow,
    EnhancedTableToolbar,
    StyledTableCell,
} from '@src/src/app/components/pigs/pig-helper';
import { Order } from '@components/pigs/pig.types';
import ModalCustom, { useToggleModal } from '@elements/modal/modal';
import ConfirmIdentity from "@components/pigs/confirmIdentity";
import * as pigApi from '@api/pig';
import DetailPig from '@components/pigs/detail';
import EditPig from '@components/pigs/identity';
import userServices from '@services/user';
import { ICommonListResponse, IPagination } from '@type/common';
import { message, notification } from "antd";
import farmServices from '@services/farm';
import { UtilPage } from '@utils/page';

import { Context } from '@components/context/context';
import ModalHeader from "../common/modal/modal-header";
import Modal from '@material-ui/core/Modal';
import { format } from "date-fns";

const PigList: FC = () => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState('barnId');
    const [selected, setSelected] = React.useState<string[]>([]);
    const [detailRow, setDetailRow] = React.useState<any>();
    const [editPigData, setEditPigData] = React.useState<any>();
    const [isListUpdated, setIsListUpdated] = React.useState<boolean>(false);

    const [isShowModal, toggleModal] = useToggleModal();
    const [isShowEditModal, toggleEditModal] = useToggleModal();
    // const [isShowDetailModal, toggleDetailModal] = useToggleModal();

    const [isShowDetailModal, setIsShowModalDetail] = useState(false);

    const [data, setData] = React.useState<ICommonListResponse>({ items: [], total: 0 });
    const [dataNotUpdate, setDataNotUpdate] = React.useState<ICommonListResponse>({ items: [], total: 0 });

    const [searchInput, setSearchInput] = React.useState<IPagination>({ offset: 0, size: 10 });
    const context = React.useContext(Context);
    const history = useHistory();

    const user = userServices.get();
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    React.useEffect(() => {
        getListUnUpdateIdentity();
        getListUpdatedIdentity();
    }, [isListUpdated, context.changeLocation.isChangeLocation, searchInput, history]);

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        const { size } = searchInput;

        const offset = value === 1 ? 0 : (size * value - size);

        setSearchInput({ ...searchInput, offset });
    };

    const formatTime = (date: Date): string => {
        return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    }

    const getListUpdatedIdentity = async () => {
        try {
            const listPigs: any = await pigApi.listPigByFarmId({ farmId: currentFarm.id, ...searchInput, isIdentityConfirm: true });
            console.log(listPigs);

            setData(listPigs);
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Đã xảy ra lỗi',
            } as any);
        }
    }

    const getListUnUpdateIdentity = async () => {
        try {
            const listUnIdentity: any = await pigApi.listPigByFarmId({ farmId: currentFarm.id, ...searchInput, isIdentityConfirm: false });
            setDataNotUpdate(listUnIdentity);
        } catch (err) {
            console.log(err);
            notification.error({
                message: 'Đã xảy ra lỗi',
            } as any);

        }
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = initialDataList.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const onChoosePigDetail = (pigDetail: any) => (e: any) => {
        setIsShowModalDetail(true);
        setDetailRow(pigDetail);
    };

    const handleClosePigDetail = () => {
        setIsShowModalDetail(false);
    }

    const onToggleEdit = (pigDetail: any) => (e: any) => {
        toggleEditModal(true)();
        setEditPigData({ ...pigDetail, user });
    }

    const onUpdateList = () => {
        setIsListUpdated(!isListUpdated);
    }

    const isSelected = (barnCode: any) => selected.indexOf(barnCode) !== -1;

    return (
        <>
            {dataNotUpdate.total > 0 && (
                <div id="pig-banner">
                    <div id='pig-banner__content' className="main-content">
                        <h1> {dataNotUpdate.total} con chưa xác nhận danh tính </h1>

                        <span className='btn' id='identity-btn' onClick={toggleModal(true)}>Xác nhận danh tính</span>
                    </div>
                </div>
            )}

            <div className='main-content'>
                <div className={`${classes.root}`}>
                    <Paper className={classes.paper} elevation={0}>
                        <PageHeader pageTile="" />
                        <EnhancedTableToolbar numSelected={selected.length} page={data.total} totalPig={data.total} />
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    classes={classes}
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={data.total}
                                />
                                <TableBody>
                                    {data ? (
                                        <>
                                            {stableSort(data.items, getComparator(order, orderBy))
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row: any, index) => {
                                                    const isItemSelected = isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <StyledTableRow
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            {/* <StyledTableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    className={classes.checkbox}
                                                                    onClick={(event) => handleClick(event, row.id)}
                                                                />
                                                            </StyledTableCell> */}
                                                            <StyledTableCell component="th" id={labelId} scope="row" padding="none">
                                                                {row.pigAIId}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">{row.pen?.name}</StyledTableCell>
                                                            <StyledTableCell align="right">{row.weightType ? row.weightType.name : ''}</StyledTableCell>
                                                            <StyledTableCell
                                                                align="right"
                                                                className={row.healthStatus && row.healthStatus?.name === "Bất thường" ? classes.warning : classes.normal}
                                                            >
                                                                {row.healthStatus ? row.healthStatus.name : ''}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">{row.event ? row.event.startDate : formatTime(row.createdDate)}</StyledTableCell>
                                                            <StyledTableCell align="right">
                                                                <Tooltip title="Xem lợn">
                                                                    <IconButton aria-label="rollback" onClick={onChoosePigDetail(row)} >
                                                                        <VisibilityIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Sửa">
                                                                    <IconButton aria-label="edit" onClick={onToggleEdit(row)}>
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            {/* {emptyRows > 0 && (
                                                <StyledTableRow style={{ height: 53 * emptyRows }}>
                                                    <StyledTableCell colSpan={6} />
                                                </StyledTableRow>
                                            )} */}
                                        </>
                                    ) : ''}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination count={UtilPage.calculateNumberPage(data.total)} className={classes.tablePagination} onChange={handleChangePage} />

                        {(isShowDetailModal) && (
                            // <div className={classes.modalContentCreate}>
                            //     <ModalHeader title='Chi tiết cá thể' closeButton={handleClosePigDetail} />
                            //     <DetailPig
                            //         dataRenderProps={{ pigId: selectedPig.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                            //     />
                            // {/* <Modal title={'Chi tiết'} toggleModal={toggleDetailModal} isShowModal={isShowDetailModal} >
                            //     <DetailPig
                            //         dataRenderProps={{ pigId: selectedPig.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                            //     />
                            // </Modal> */}
                            // </div>
                            <Modal
                                open={isShowDetailModal}
                                onClose={handleClosePigDetail}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                className={classes.modal}
                            >
                                <div className={classes.modalContentCreate}>
                                    <ModalHeader title='Chi tiết lợn' closeButton={handleClosePigDetail} />
                                    <DetailPig
                                        dataRenderProps={{ pigId: detailRow.id, pigAIId: '', user: { ...user, defaultFarm: currentFarm } }}
                                    />
                                </div>
                            </Modal>
                        )}

                        {isShowEditModal && (
                            <EditPig
                                modalRenderProps={{ isOpen: isShowEditModal, toggleModal: toggleEditModal }}
                                editData={editPigData}
                                onUpdateList={onUpdateList}
                            />
                        )}
                    </Paper>
                </div>
            </div>

            {isShowModal && (<ConfirmIdentity toggleModal={toggleModal} isShowModal={isShowModal} user={{ ...user, defaultFarm: currentFarm }} onUpdateList={onUpdateList} unUpdateIdentityList={dataNotUpdate.items} />)}
        </>
    )
};

export default PigList;
