import React, { useEffect, useState } from 'react'
import './style.sickpigs.scss'
import Search from 'antd/lib/input/Search';
import { Breadcrumb, Button, ConfigProvider, Form, Modal, Select, Space, Spin, Table, Tooltip, Transfer, notification } from 'antd'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import { TransferDirection } from 'antd/lib/transfer';
import { AnyNaptrRecord } from 'dns';
import { AiOutlineEye } from 'react-icons/ai';


interface symptoms {
    id: string,
    name: string
}
interface DataItem {
    id?: string,
    fromAge: number,
    toAge: number,
    symptoms?: symptoms[],
    metaData: string,
    name: string,
    lowerBoundTemperature: number,
    upperBoundTemperature: number,
    ageDescription: string,
    warning?: boolean
    definition?: string
    description?: string
    prevention?: string
    match?: number
}
// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'ordinalNumber',
        key: 'ordinalNumber',
    },
    {
        title: 'Tên bệnh',
        dataIndex: 'diseaseName',
        key: 'diseaseName',
    },
    {
        title: 'Triệu chứng',
        dataIndex: 'symptom',
        key: 'symptom',
    },
    {
        title: 'Độ tuổi',
        dataIndex: 'ageDescription',
        key: 'ageDescription',
    },
    {
        title: 'Nhiệt độ',
        dataIndex: 'temperature',
        key: 'temperature',
    },
    {
        title: 'Nguy cơ mắc bệnh',
        dataIndex: 'match',
        key: 'match',
    },
    // {
    //     title: 'Định nghĩa',
    //     dataIndex: 'definition',
    //     key: 'definition',
    // },
    // {
    //     title: 'Mô tả',
    //     dataIndex: 'description',
    //     key: 'description',
    // },
    // {
    //     title: 'Phòng ngừa',
    //     dataIndex: 'prevention',
    //     key: 'prevention',
    // },
    {
        key: 'operation',
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];


const SickPigsDiagnosis = () => {
    const [lstSymptom, setLstSymptom] = useState<any[]>([]); // Danh sách triệu chứng
    const { Option } = Select;
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstSelectedSymptom, setLstSelectedSymptom] = useState<any[]>([]); // Danh sách triệu chứng được chọn
    const [dataSickPigsDiagnosis, setDataSickPigsDiagnosis] = useState<any[]>([]); // Danh sách bệnh lợn được chẩn đoán
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const history = useHistory();
    const [editPigs, setEditPigs] = useState<DataItem>(); // Bệnh lợn đang được chỉnh sửa
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [isShowViewModal, setIsShowViewModal] = useState(false); // Trạng thái hiển thị modal xem bệnh lợn

    useEffect(() => {
        getLstSymptom();
    }, []);

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const getLstSymptom = async () => {
        axios.get(`https://sit.api.pigman.com.vn/symptom`).then(req => {
            console.log(req.data.data);
            const tmp = req.data.data.map((item: any) => {
                item.name = item.name.charAt(0).toUpperCase() + item.name.slice(1)
                return item;
            });
            console.log(tmp);

            setLstSymptom(tmp);
        });
    }

    const handleChangeSelectSymptom = (value: any) => {
        setLstSelectedSymptom(value);
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataSickPigsDiagnosis.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // State to keep track of selected items
    const [targetKeys, setTargetKeys] = useState([]);

    // Callback function when items are moved between lists
    const handleChange = (nextTargetKeys: any) => {
        console.log(nextTargetKeys);

        setTargetKeys(nextTargetKeys);

    };

    const handleClickDianosis = () => {
        const res = {
            symptomIds: targetKeys,
            additionalProp1: {},
        }
        if (targetKeys.length <= 0) return;
        axios.post(`https://sit.api.pigman.com.vn/disease/diagnose`, res)
            .then(req => {
                if (req.data.statusCode !== 'diseaseNotFound') {
                    setLoading(true);
                    // Gán dữ liệu lấy được vào biến data
                    const data = req.data.data.items.map((item: any, index: number) => {
                        // Chuyển đổi dữ liệu lấy được thành dạng dữ liệu của bảng
                        const symptomsNameLst = item.symptoms?.map((item: any) => {
                            return item.name.charAt(0).toUpperCase() + item.name.slice(1)
                        })
                        const symptomsName = "\"" + symptomsNameLst?.join("\". \"") + "\"";
                        const temperatureTmp = item.lowerBoundTemperature ? `${item.lowerBoundTemperature}°C - ${item.upperBoundTemperature}°C` : "37°C - 39°C";
                        const ageDescriptionTmp = item.ageDescription ? item.ageDescription : "Ở mọi lứa tuổi đều có thể mắc";
                        let matchTmp = ''
                        if (item.match < 50)
                            matchTmp = 'Thấp'
                        else if (item.match >= 50 && item.match < 80)
                            matchTmp = 'Trung bình'
                        else
                            matchTmp = 'Cao'

                        return {
                            ordinalNumber: index + 1,
                            diseaseName: item.name,
                            symptom: symptomsName,
                            ageDescription: ageDescriptionTmp,
                            temperature: temperatureTmp,
                            match: (
                                <b>{matchTmp}</b>
                            ),
                            operation:
                                <Space key={index} style={{ alignItems: 'end' }}>
                                    <Tooltip title="Xem">
                                        <AiOutlineEye style={{ fontSize: 18 }} onClick={() => handleView(item)} />
                                    </Tooltip>
                                </Space>
                        }
                    });
                    setTimeout(() => {
                        // Tắt loading
                        setLoading(false);
                        setDataSickPigsDiagnosis(data);
                    }, 1000);
                    // Gán dữ liệu vào biến dataSickPigs
                }
                else {
                    notification.error({
                        message: 'Chẩn đoán thất bại',
                        description: 'Vui lòng chọn lại triệu chứng phù hợp',
                    })
                    setDataSickPigsDiagnosis([]);
                }
            })
    }

    // Function to filter items that have already been selected
    const filterOption = (inputValue: string, option: any) =>
        option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

    useEffect(() => {
        const inputElement = document.getElementsByClassName('ant-transfer-list-search') as HTMLCollectionOf<HTMLInputElement>;
        inputElement[0].placeholder = 'Nhập tên triệu chứng';
        inputElement[1].placeholder = 'Nhập tên triệu chứng';

    }, []);

    const handleView = (record: DataItem) => {
        // Gán bệnh lợn đang được chỉnh sửa là record
        console.log(record);

        setEditPigs(record);
        const tmpLowerBoundTemperature = record.lowerBoundTemperature ? record.lowerBoundTemperature : 37;
        const tmpUpperBoundTemperature = record.upperBoundTemperature ? record.upperBoundTemperature : 39;
        const tmp: any[] = []
        record.symptoms?.map((item: symptoms) => {
            console.log(item);
            tmp.push(
                <>
                    <p>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</p> <br />
                </>
            )
        });
        console.log(tmp);

        setTemperature([tmpLowerBoundTemperature, tmpUpperBoundTemperature]);
        // Gán giá trị cho form là giá trị của bệnh lợn đang được chỉnh sửa
        form.setFieldsValue({
            diseaseName: record.name,
            symptom: tmp,
            ageDescription: record.ageDescription,
            temperature: [tmpLowerBoundTemperature, tmpUpperBoundTemperature],
            definition: record.definition,
            description: record.description,
            prevention: record.prevention,
        });

        // Hiển thị modal thêm/sửa bệnh lợn
        setIsShowViewModal(true);
    }

    const handleCancelView = () => {
        setIsShowViewModal(false);
    }

    const handleOkView = () => {
        setIsShowViewModal(false);
    }

    return (
        <div className='sick-pig-main' style={{ paddingTop: 0 }}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <span >Bệnh lợn</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Chẩn đoán bệnh</Breadcrumb.Item>
            </Breadcrumb>
            <div className='button-sick-pig dianosis'>
                <Transfer
                    titles={['Danh sách triệu chứng', 'Triệu chứng đã chọn']}
                    dataSource={lstSymptom.map((item) => ({ ...item, key: item.id }))}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    render={(item) => item.name}
                    showSearch
                    filterOption={filterOption}
                />
            </div>
            <div className='button-dianosis'>
                <Button onClick={handleClickDianosis}>Chẩn đoán</Button>
            </div>
            <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                {dataSickPigsDiagnosis.length > 0 &&
                    <div className='table-sick-pig'>
                        <Table
                            dataSource={dataSickPigsDiagnosis}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                    </div>
                }
            </Spin>
            <div className='modal-view-pig'>
                <Modal
                    className='modal-add-sick-pig modal-view'
                    visible={isShowViewModal}
                    title={'Xem bệnh lợn'}
                    onCancel={handleCancelView}
                    onOk={handleOkView}
                    maskClosable={true}
                    footer={false}
                    width={1000}
                >

                    <Form layout="vertical" form={form}>
                        <Form.Item name="diseaseName" label="Tên bệnh" rules={[{ required: true, message: 'Vui lòng nhập tên bệnh' }]}>
                            <div>{form.getFieldValue('diseaseName')}</div>
                        </Form.Item>
                        <Form.Item name="symptom" label="Triệu chứng" rules={[{ required: true, message: 'Vui lòng nhập biểu hiện' }]}>
                            {form.getFieldValue('symptom')?.map((item: any, index: any) => {
                                return (
                                    <>
                                        <ul className='ui-view-modal'>
                                            <li key={index}>{item}</li>
                                        </ul>
                                    </>
                                    // <p key={index}>{item}</p>
                                )
                            })}
                        </Form.Item>
                        <Form.Item name="ageDescription" label="Độ tuổi" rules={[{ required: true, message: 'Vui lòng nhập độ tuổi' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('ageDescription')}</div>
                        </Form.Item>
                        <Form.Item name="temperature" label="Nhiệt độ" rules={[{ required: true, message: 'Vui lòng chọn nhiệt độ' }]}>
                            {/* <Slider disabled={true} defaultValue={[37, 39]} min={30} max={50} range marks={marks} onChange={handleTemperatureChange} /> */}
                            {form.getFieldValue('temperature')?.map((item: any, index: any) => {

                                return (
                                    <>
                                        {index === 0 ? <span key={index}>Từ {item}°C </span> : <span key={index}>Đến {item}°C</span>}
                                    </>
                                )
                            })}
                        </Form.Item>
                        <Form.Item name="definition" label="Định nghĩa" rules={[{ required: true, message: 'Vui lòng nhập định nghĩa' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('definition')}</div>
                        </Form.Item>
                        <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng nhập mô tả' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('description')}</div>
                        </Form.Item>
                        <Form.Item name="prevention" label="Phòng ngừa" rules={[{ required: true, message: 'Vui lòng nhập phòng ngừa' }]}>
                            {/* <TextArea disabled={true} autoSize={{ minRows: 3, maxRows: 5 }} /> */}
                            <div>{form?.getFieldValue('prevention')}</div>
                        </Form.Item>
                        {/* <div className='action-btn'>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsShowAddModal(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit">
                            Xác nhận
                        </Button>

                    </div> */}
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default SickPigsDiagnosis
