
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'
import { ISearchOrderItems } from '@type/food-order-item';

export const getOrderItems = (data: ISearchOrderItems) => {
    return fetcher.post(`${CFG_BASE_URL}/foodOrderItem/foodOrder`, data as any);
}

export const removeOrderItem = (itemId: string) => {
    return fetcher.delete(`${CFG_BASE_URL}/foodOrderItem/${itemId}`);
}

export const updateItemQuantity = (itemId: string, data: { quantity: number, foodId: string, foodOrderId: string }) => {
    return fetcher.put(`${CFG_BASE_URL}/foodOrderItem/${itemId}`, data as any)
}