/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import moment from 'moment';
import './food-order.scss';

import { IFood } from '@type/food';
import { IFoodOrderItems, EModalType, IInitialOrderInput, IFoodOrderModal } from '@type/food-order';
import * as foodApi from '@api/food';
import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';

import FoodOrderCreate from '@components/food-order/create';
import FoodOrderUpdate from '@components/food-order/update';
import FoodOrderDetail from '@components/food-order/detail';
import axios from 'axios';
import PharmaceuticalOrderCreate from './PharmaceuticalOrderCreate';
import PharmaceuticalOrderDetail from './PharmaceuticalOrderDetail';
import PharmaceuticalOrderUpdate from './PharmaceuticalOrderUpdate';

enum EOrderType {
    PUT_ON_ORDERED,
    REMOVE_FROM_ORDERED,
}

const PharmaceuticalOrderModal: FC<IFoodOrderModal> = ({
    toggleModal,
    foodOrderId,
    modalType,
    refreshAfterAction,
    supplierId,
}: IFoodOrderModal) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const initialDataDefaultValue: IInitialOrderInput = {
        name: '',
        pharmaceuticalSupplierId: supplierId,
        date: moment(new Date()).format(DATE_FORMAT),
        pharmaceuticalOrderItems: [],
        pharmaceuticalSupplierName: '',
        farmId: currentFarm.id,
        totalPrice: 0,
        orderedTotalPrice: 0,
    };

    const [initialData, setInitialData] = React.useState<IInitialOrderInput>(initialDataDefaultValue);
    const [foods, setFoods] = React.useState<IFood[]>([]);
    const [choosingItems, setChoosingItems] = React.useState<IFoodOrderItems[]>([]);
    const [errInputNumber, setErrInputNumber] = React.useState<Record<string, string[]>>({});

    React.useEffect(() => {
        getFood();

        return () => {
            setInitialData(initialDataDefaultValue);
        };
    }, []);

    const getFood = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pharmaceutical?farmId=${currentFarm.id}&size=1000`)
            .then((res) => {
                console.log(res.data.data.items);
                setFoods(res.data.data.items);
            })
        // if (data.items) setFoods(data.items);
    };

    const changeInitialInput = (data: IInitialOrderInput) => {
        setInitialData(data);
    };

    const doOrder = (foodId: string, orderType: EOrderType) => () => {
        console.log('choosingItems', choosingItems);
        console.log(foodId);


        const exists = choosingItems.some((obj) => obj.id === foodId);
        if (exists && orderType === EOrderType.PUT_ON_ORDERED) return;
        let loopList = foods;
        let data!: IFood;

        if (orderType === EOrderType.REMOVE_FROM_ORDERED) loopList = choosingItems;

        for (let i = 0; i < loopList.length; i++) {
            const currentItems = loopList[i];

            if (currentItems.id === foodId) {
                data = currentItems;
                break;
            }
        }

        if (orderType === EOrderType.PUT_ON_ORDERED) {
            setFoods(foods.filter((data) => data.id !== foodId));
            setChoosingItems([...choosingItems, { ...data, quantity: 1 }]);
        }

        if (orderType === EOrderType.REMOVE_FROM_ORDERED) {
            setChoosingItems(choosingItems.filter((data) => data.id !== foodId));

            setFoods([...foods, data]);
        }
    };

    const onChangeInputData = (foodId: string, inputName: string, dataInput?: string | number) => (e: any) => {
        let totalPrice = 0;

        const value = !dataInput && !+e.target.value ? 0 : dataInput || +e?.target?.value || '';

        if (!dataInput && !+e.target.value) {
            const dataError = errInputNumber;

            if (dataError.hasOwnProperty(foodId) && !dataError[foodId].includes(inputName)) {
                dataError[foodId].push(inputName);
            }

            if (!dataError.hasOwnProperty(foodId)) dataError[foodId] = [inputName];

            setErrInputNumber(dataError);
        } else if (
            errInputNumber &&
            errInputNumber.hasOwnProperty(foodId) &&
            errInputNumber[foodId].includes(inputName)
        ) {
            const data = errInputNumber;
            const index = data[foodId].indexOf(inputName);
            if (index > -1) {
                data[foodId] = data[foodId].filter((v: string) => v !== inputName);
            }
        }

        const itemsData = choosingItems.map((data: any) => {
            if (data.id === foodId) {
                data[inputName] = value;
                data.subTotal = data.quantity * data.price || 0;
            }

            totalPrice += +data.subTotal;
            return data;
        });
        changeInitialInput({ ...initialData, totalPrice });
        setChoosingItems(itemsData);
    };

    const renderModalContent = () => {
        let content = <div />;

        if (modalType === EModalType.CREATE) {
            content = (
                <PharmaceuticalOrderCreate
                    supplierId={supplierId}
                    refreshAfterAction={refreshAfterAction}
                    toggleModal={toggleModal}
                    order={{
                        onChangeInputData,
                        doOrder,
                        choosingItems,
                    }}
                    data={{
                        initialData,
                        changeInitialInput,
                        foods,
                    }}
                    errors={errInputNumber}
                />
            );
        }

        if (modalType === EModalType.UPDATE) {
            content = (
                <PharmaceuticalOrderUpdate
                    foodOrderId={foodOrderId}
                    refreshAfterAction={refreshAfterAction}
                    toggleModal={toggleModal}
                    order={{
                        onChangeInputData,
                        doOrder,
                        choosingItems,
                    }}
                    data={{
                        initialData,
                        changeInitialInput,
                        foods,
                    }}
                    errors={errInputNumber}
                />
            );
        }

        if (modalType === EModalType.DETAIL) {
            content = <PharmaceuticalOrderDetail foodOrderId={foodOrderId} />;
        }

        return content;
    };

    return <div>{renderModalContent()}</div>;
};

export default PharmaceuticalOrderModal;
