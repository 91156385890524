/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { RenderValidationError } from '@components/errors/validation';
import { Table, DatePicker, message, notification, Button } from 'antd';

import { IInitialOrderInput, IUpdateInput } from '@type/food-order';
import { IFoodSupplierData } from '@type/food-supplier';

import { apiFoodSupplier } from '@api/food-supplier';
import * as foodOrderApi from '@api/food-order';
import * as orderItemApi from '@api/food-order-item';

import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';
import { UtilCollections } from '@utils/collections';

import { choosingColumns, updateColumns } from './food-order-columns';

import { IFoodConsumeUpdateComponent, EOrderType, IFoodOrdered } from '@type/food-order';
import axios from 'axios';

const FoodOrderUpdate: FC<IFoodConsumeUpdateComponent> = ({
    order,
    foodOrderId,
    toggleModal,
    refreshAfterAction,
    data,
    errors: inputErrors,
}: IFoodConsumeUpdateComponent) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const { doOrder, onChangeInputData, choosingItems } = order;
    const { changeInitialInput, initialData, foods } = data;

    const [suppliers, setSuppliers] = React.useState<IFoodSupplierData[]>([]);

    const [orderedItems, setOrderedItems] = React.useState<IFoodOrdered[]>([]);
    const [updateOrderedList, setUpdateOrderedList] = React.useState<IFoodOrdered[]>([]);
    const [foodSupplierId, setFoodSupplierId] = React.useState<string>('');
    const [foodLst, setFoodLst] = React.useState<any>(foods);
    const [disableFoodSupplier, setDisableFoodSupplier] = React.useState<any>(false);
    const [orderType, setOrderType] = React.useState<any>('');
    const [lstFoodByOrder, setLstFoodByOrder] = React.useState<any>([]);
    const [date, setDate] = React.useState<any>();
    React.useEffect(() => {
        getSuppliers();
        (async () => {
            const detail = (await getOrderDetail()) as any;
            const listOrderItems = (await getOrderItemsList()) as any;
            console.log(detail);
            setDate(detail.date);
            changeInitialInput({
                name: detail.name,
                date: detail.date,
                foodSupplierId: detail.foodSupplier.id,
                foodSupplierName: detail.foodSupplier.name,
                orderedTotalPrice: detail.totalPrice,
                totalPrice: 0,
            });
            console.log(initialData);

            setOrderedItems(listOrderItems.items);
            setFoodSupplierId(detail.foodSupplier.id);
        })();
    }, []);

    useEffect(() => {
        orderType && setDisableFoodSupplier(true)
    }, [orderType]);

    useEffect(() => {
        getFoodList(null);
    }, [foodSupplierId]);

    useEffect(() => {
        if (lstFoodByOrder.length > 0) {
            const idsToRemove = lstFoodByOrder.map((item: any) => item.food.id);
            const newArrayA = foodLst.filter((item: any) => !idsToRemove.includes(item.id));
            setFoodLst(newArrayA);
        }
    }, [lstFoodByOrder]);

    const getFoodList = async (lstId: any) => {
        if (lstId) {
            if (foodSupplierId) {
                await axios.get(`https://sit.api.pigman.com.vn/food/foodSupplier/${foodSupplierId}?size=1000`).then((res) => {

                    const data = res.data.data.items;
                    const tmp = data.filter((item: any) => !lstId.includes(item.id));
                    console.log(tmp);
                    setFoodLst(tmp);
                    setLstFoodByOrder(orderedItems);
                });
            }
        }
        else {
            if (foodSupplierId) {
                await axios.get(`https://sit.api.pigman.com.vn/food/foodSupplier/${foodSupplierId}?size=1000`).then((res) => {
                    setFoodLst(res.data.data.items);
                    setLstFoodByOrder(orderedItems);
                });
            }
        }
    }

    const getSuppliers = async () => {
        const data = (await apiFoodSupplier.getAll({ size: 200, farmId: currentFarm.id })) as any;
        if (data.items) setSuppliers(data.items);
    };

    const getOrderDetail = () => foodOrderApi.getOrderDetail(foodOrderId);

    const getOrderItemsList = async () => orderItemApi.getOrderItems({ foodOrderId, offset: 0, size: 200 });

    const schema = Yup.object().shape({
        // foodSupplierId: Yup.string().required('Vui lòng chọn nhà cung cấp'),
        // date: Yup.string().required('Trường này bắt buộc'),
        // name: Yup.string().required('Trường này bắt buộc'),
    });

    const doSubmit = async (values: IInitialOrderInput, actions: any) => {
        delete values.foodSupplierName;
        console.log(values);

        await updateOrder(values as any);

    };

    const updateOrder = async (values: IUpdateInput) => {
        const commonDataUpdate = (v: any) => {
            return {
                foodId: v.food ? v.food.id : v.id,
                quantity: v.quantity,
                foodOrderId,
                price: v.price,
                subTotal: v.subTotal,
                expirationDate: v.expirationDate || moment(new Date(), 'YYYY-MM-DD'),
            };
        };
        values.foodOrderItemCreate = choosingItems.map((v: any) => commonDataUpdate(v));
        values.foodOrderItemUpdate = orderedItems.map((v: any) => ({
            ...commonDataUpdate(v),
            id: v.id,
        }));

        values.date = UtilCollections.formatDateSearchBeHaviour(values.date);

        // values.date = (new date(date)).toString();

        // console.log(values.date);

        // values.totalPrice = initialData.totalPrice += initialData.orderedTotalPrice || 0;

        delete initialData.orderedTotalPrice;
        const lstTmp = [...values.foodOrderItemCreate, ...values.foodOrderItemUpdate];
        const lstTmp2 = lstTmp.map((v: any) => {
            return {
                foodId: v.foodId,
                quantity: v.quantity,
            }
        });
        console.log(lstTmp2);
        console.log(orderedItems);


        if (lstTmp2.length === 0) {
            notification.error({
                message: 'Vui lòng chọn thức ăn',
                description: 'Thức ăn không được để trống',
            } as any);
            return;
        }
        else {
            const data = {
                name: values.name,
                date: values.date,
                lst: lstTmp2,
            }
            await axios.put(`https://sit.api.pigman.com.vn/foodOrder/${foodOrderId}`, data)
                .then((res) => {
                    console.log('update success');

                    notification.success({
                        message: 'Cập nhật thành công',
                    } as any);
                    refreshAfterAction();
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    notification.error({
                        message: 'Cập nhật thất bại',
                        description: err.response.data.message,
                    })
                });

            toggleModal(false)();

        }
    };

    const onChangeInputUpdateData = (orderData: any, inputName: string, inputData: string) => (e: any) => {
        const data = { ...orderData, [inputName]: inputData || +e.target.value || '' };
        const orderedListData = updateOrderedList;

        for (let i = 0; i < orderedListData.length; i++) {
            if (updateOrderedList[i].id === orderData.id) {
                orderedListData[i] = data;
                break;
            }

            orderedListData.push(data);
        }

        if (orderedListData.length === 0) orderedListData.push(data);

        setUpdateOrderedList([...orderedListData]);

        let orderedTotalPrice = 0;
        const orderedListUpdate: any = orderedItems.map((data: any) => {
            if (data.id === orderData.id) {
                data[inputName] = inputData || +e?.target?.value || '';
                data.subTotal = data.quantity * data.price || 0;
            }

            orderedTotalPrice += +data.subTotal;

            return data;
        });

        setOrderedItems([...orderedListUpdate]);
        changeInitialInput({ ...initialData, orderedTotalPrice });
    };

    const removeOrderedItem = (itemId: string) => async () => {
        // await orderItemApi.removeOrderItem(itemId);
        setOrderedItems(orderedItems.filter((v: any) => v.id !== itemId));

        // notification.success({
        //     message: 'Xóa thành công',
        // } as any);
    };
    // Define the disabledDate function
    const disabledDate = (current: any) => {
        // Disable dates that are after the current date
        return current && current > moment().endOf('day');
    };

    useEffect(() => {
        const lstFoodId = orderedItems.map((item: any) => item.food.id);

        getFoodList(lstFoodId);
    }, [orderedItems]);

    return (
        <Formik initialValues={initialData} onSubmit={doSubmit} validationSchema={schema} enableReinitialize>
            {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form className="log-form" style={{ width: '80%' }}>
                    <div className="log-field">
                        <label htmlFor="name" className="log-label">
                            Tên <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                name="name"
                                className={`log-input`}
                                type={'text'}
                                id="name"
                                placeholder={'Tên'}
                            // value={initialData.name}
                            />
                            <ErrorMessage component={RenderValidationError} name="name" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="date" className="log-label">
                            Ngày <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            {date &&
                                <>
                                    <DatePicker
                                        name="date"
                                        className={`log-input`}
                                        id="date"
                                        placeholder={'Ngày'}
                                        onChange={(date, dateString) => {
                                            setFieldValue('date', dateString)
                                            setDate(dateString)
                                        }
                                        }
                                        format={DATE_FORMAT}
                                        defaultValue={
                                            moment(
                                                date ? date : new Date(),
                                            )}
                                        disabledDate={disabledDate}

                                    />
                                    <ErrorMessage component={RenderValidationError} name="date" />
                                </>
                            }
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="foodSupplierId" className="log-label">
                            Nhà cung cấp <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                disabled={true}
                                as="select"
                                name="foodSupplierId"
                                className={'log-input'}
                                onChange={(e: any) => {
                                    setFieldValue('foodSupplierId', e.target.value);
                                    setFoodSupplierId(e.target.value);
                                }}>
                                <option value="">Chọn nhà cung cấp</option>
                                {suppliers.map((v, i) => (
                                    <option key={i} value={v.id}>
                                        {v.name}
                                    </option>
                                ))}
                            </Field>

                            <ErrorMessage component={RenderValidationError} name="foodSupplierId" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="orderedItems" className="log-label">
                            Thức ăn <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                disabled={foodLst.length === 0}
                                as="select"
                                name="orderedItems"
                                className={'log-input'}
                                onChange={(e: any) => {
                                    doOrder(e.target.value, EOrderType.PUT_ON_ORDERED)();
                                    setOrderType(e.target.value);

                                }}
                                defaultChecked={''}
                                multiple={false}>
                                {(orderType === '' && foodLst.length > 0) &&
                                    <option value="" >Chọn thức ăn</option>
                                }
                                {foodLst.map((v: any) => {
                                    return (
                                        <option key={v.id} value={v.id}>
                                            {v.name}
                                        </option>
                                    );
                                })}
                                {foodLst.length === 0 && <option value="">Đã hết thức ăn chọn</option>}
                            </Field>

                            <ErrorMessage component={RenderValidationError} name="orderedItems" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label className="log-label">Thức ăn đã chọn</label>
                        <div className="control has-icon-right">
                            <Table
                                columns={choosingColumns(onChangeInputData, doOrder, inputErrors)}
                                dataSource={choosingItems}
                                pagination={false}
                                size="small"
                                locale={{ emptyText: 'Chưa chọn thức ăn' }}
                            // footer={() => `Tổng giá: ${UtilCollections.VNDCurrencyFormat(initialData.totalPrice)}`}
                            />
                        </div>
                    </div>

                    <div className="log-field">
                        <label className="log-label">Thức ăn đã đặt</label>
                        <div className="control has-icon-right">
                            <Table
                                columns={updateColumns(onChangeInputUpdateData, removeOrderedItem)}
                                dataSource={orderedItems}
                                pagination={false}
                                size="small"
                                locale={{ emptyText: 'Không có thức ăn' }}
                            // footer={() =>
                            //     `Tổng giá: ${UtilCollections.VNDCurrencyFormat(initialData.orderedTotalPrice || 0)}`
                            // }
                            />
                        </div>
                    </div>

                    <div className="log-field button-submit">
                        <span />
                        <div >
                            <Button onClick={
                                () => {
                                    toggleModal(false)();
                                }
                            }>
                                Hủy bỏ
                            </Button>
                            <Button className='btn-submit-form' type='primary' htmlType='submit' >
                                Cập nhật
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FoodOrderUpdate;
