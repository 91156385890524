import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import './style.userList.scss';
import { Avatar, Space, Table, Tooltip, Select, Spin, notification, Modal, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { LockOutlined, DeleteOutlined, UnlockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as XLSX from 'xlsx';


// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    // {
    //     title: 'Avatar',
    //     dataIndex: 'avatar',
    // },
    {
        title: 'Họ và tên',
        dataIndex: 'name',
    },
    {
        title: 'Số điện thoại',
        dataIndex: 'phone',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Thời gian tạo',
        dataIndex: 'date',
    },
    // {
    //     title: 'Ngày cập nhật',
    //     dataIndex: 'updateDate',
    // },
    {
        title: 'Thao tác',
        dataIndex: 'operation',
    },
];
const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
};

interface User {
    key: string;
    avatar: JSX.Element;
    name: string;
    number: string;
    address: string;
    email: string;
    lock: number;
    operation: JSX.Element;
}

const UserList: FC = () => {
    const [data, setData] = useState([]);   // danh sách user sau khi fetch
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const history = useHistory();
    const [isVisibleDeleteUser, setIsVisibleDeleteUser] = useState(false);
    const [userDeleteID, setUserDeleteID] = useState('');
    useEffect(() => {
        fetchData('');
    }, []);

    const fetchData = async (value: string) => {
        await axios.get('https://sit.api.pigman.com.vn/admin/users').then((response) => {
            let count = 0;
            setData(
                response.data.data.map((user: any) => {
                    count += 1;
                    const formattedDate = moment(user.createTime).format('HH:MM:SS DD/MM/YYYY ');
                    const formattedUpdateDate = moment(user.updateTime).format('DD/MM/YYYY HH:mm:ss');
                    return {
                        key: count,             // cột STT, cột đầu tiên của bảng
                        id: user.id,
                        // avatar: <Avatar size={30}> {user.fullName[0].toUpperCase()}</Avatar>,
                        name: user.fullName,
                        phone: user.phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3"),
                        address: user.address ? user.address : 'Chưa cập nhật',
                        email: user.email,
                        date: formattedDate ? formattedDate : 'Chưa cập nhật',
                        updateDate: formattedUpdateDate,
                        activityStatus: user.active,
                        operation: (
                            <Space>
                                <div className="text-link" onClick={() => handleClickPen(user.id)}>
                                    Xem trang trại -&#62;{' '}
                                </div>
                                <Tooltip title={user.isBlock ? 'Mở khóa' : 'Khóa'}>
                                    {user.isBlock ? <LockOutlined onClick={() => handleLockUser(user)} rev={undefined} /> : <UnlockOutlined onClick={() => handleLockUser(user)} rev={undefined} />}
                                </Tooltip>
                                {/* <Tooltip title="Xóa">
                                    <DeleteOutlined onClick={() => handleDeleteUser(user)} />
                                </Tooltip> */}
                            </Space>
                        ),
                    };
                }),
            );
        });
    }

    const handleClickPen = (id: string) => {
        history.push(`/profile/quan-ly-trang-trai/${id}`)
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }

    const handleLockUser = async (user: any) => {
        if (user.isBlock) {
            await axios.put(`https://sit.api.pigman.com.vn/admin/user/block/${user.id}`)
                .then((response) => {
                    notification.success({
                        message: 'Thành công',
                        description: 'Mở khóa người dùng thành công',
                    })
                    fetchData('');
                })
                .catch((error) => {
                    notification.error({
                        message: 'Thất bại',
                        description: 'Mở khóa người dùng thất bại',
                    })
                })
        }
        else {
            await axios.put(`https://sit.api.pigman.com.vn/admin/user/block/${user.id}`)
                .then((response) => {
                    notification.success({
                        message: 'Thành công',
                        description: 'Khóa người dùng thành công',
                    })
                    fetchData('');
                })
                .catch((error) => {
                    notification.error({
                        message: 'Thất bại',
                        description: 'Khóa người dùng thất bại',
                    })
                })
        }
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            setLoading(false);

        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = (value: string) => {
        // // Mở loading
        // setLoading(true);
        // // Đẩy lên đầu trang
        // setTimeout(() => {
        //     // Load dữ liệu
        //     fetchData(value);
        //     // Tắt loading
        //     setLoading(false);
        // }, 1000);
    };

    const sortData = filter.length === 0 ? data : data.filter((item: User) => item.email.toString().toLowerCase().includes(filter.toString().toLowerCase()));

    const handleDeleteUser = (user: any) => {
        setIsVisibleDeleteUser(true);
        setUserDeleteID(user.id);
    }

    const handleDeleteSubmit = async () => {
        setIsVisibleDeleteUser(false);
        setLoading(true);
        await axios.delete(`https://sit.api.pigman.com.vn/users/{userId}?userId=${userDeleteID}`)
            .then((response) => {
                setTimeout(() => {
                    notification.success({
                        message: 'Thành công',
                        description: 'Xóa người dùng thành công',
                    });
                    fetchData('');

                    setLoading(false);
                }, 1000);
            })
            .catch((error) => {
                notification.error({
                    message: 'Thất bại',
                    description: 'Xóa người dùng thất bại',
                });
            })
    }
    const exportToExcel = () => {
        // Map over sortData to create a new array with the desired column names
        const dataWithVietnameseKeys = sortData.map((item: any) => ({
            'STT': item.key,
            'Họ và tên': item.name,
            'Số điện thoại': item.phone,
            'Địa chỉ': item.address,
            'Email': item.email,
            'Thời gian tạo': item.date,
            // Add more keys as needed...
        }));

        const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
        ws['!cols'] = [
            { width: 5 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Danh sach nguoi dung.xlsx");
    };

    return (
        <>
            <div className="userList-body">
                <div className="button-userList">
                    <p className="userList-h2">Quản lý người dùng</p>
                    <div className="search-and-sort">
                        <Search
                            className='search-userList'
                            placeholder="Tìm kiếm email của người dùng"
                            onChange={(event) => setFilter(event.target.value)}
                            onSearch={onSearch}
                            enterButton
                            allowClear
                        />

                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">

                    <div className="table-userList">

                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        <div className='button-excel'>
                            <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                        </div>
                    </div>
                </Spin >
                <Modal
                    title="Thông báo"
                    visible={isVisibleDeleteUser}
                    onOk={() => setIsVisibleDeleteUser(false)}
                    onCancel={() => setIsVisibleDeleteUser(false)}
                    footer={false}

                >
                    <p>Bạn có chắc chắn muốn xóa người dùng này?</p>
                    <div className='action-btn' style={{ marginTop: 20 }}>
                        <Button className='cancel-btn' type="primary" onClick={() => setIsVisibleDeleteUser(false)}>
                            Hủy bỏ
                        </Button>
                        <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleDeleteSubmit}>
                            Xác nhận
                        </Button>
                    </div>
                </Modal>

            </div >
        </>
    );
};

export default UserList;
