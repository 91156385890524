/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'

export const apiUser = {
    register(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/users/register`, payload);
    },
    login(payload: any) {
        return fetcher.post(`${CFG_BASE_URL}/users/login`, payload);
    },
    updateUser(userId: string, payload: any) {
        return fetcher.put(`${CFG_BASE_URL}/users/${userId}`, payload);
    }
};
