/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-debugger */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filter, map } from "rxjs/operators";
import { AuthPopupOrigin } from "../../utils/global";
import { RootEpic } from "../../components/common/define-type";

// type MessageLogin = {
//     content: string;
//     errorCode?: number
// }
// type MessageForgot = {
//     ErrorCode?: number,
//     Message: string
// }
interface LoginState {
    authModalOrigin: AuthPopupOrigin;
}

const initState: LoginState = {
    authModalOrigin: AuthPopupOrigin.None
}

const loginSlice = createSlice({
    name: 'login',
    initialState: initState,
    reducers: {
        setAuthPopupOrigin(state, action: PayloadAction<AuthPopupOrigin>) {
            state.authModalOrigin = action.payload
        },
        getAuthPopupOrigin(state, action: PayloadAction<AuthPopupOrigin>) {
            state.authModalOrigin = action.payload
        },
    }
})

const setAuthPopupOrigin$: RootEpic = (action$: any) => action$.pipe(
    filter(getAuthPopupOrigin.match),
    map((re: any) => { return loginSlice.actions.setAuthPopupOrigin(re.payload) })
)


export const LoginEpics = [
    setAuthPopupOrigin$
]
export const {
    // getDepartmentRequest,
    setAuthPopupOrigin,
    getAuthPopupOrigin
} = loginSlice.actions
export const loginReducer = loginSlice.reducer