import { DeleteOutlined, EditOutlined, FlagOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Select, Space, Spin, Table, Tooltip, DatePicker, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import notification from 'antd/lib/notification';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './style.report.scss';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
import { saveAs } from 'file-saver';
import farmServices from '@services/farm';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Context } from '@components/context/context';
import { CSVLink } from 'react-csv';
import userServices from '@services/user';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    farmId: string,
    pharmaceuticalName: string,
    oderedDate: string,
    unit: string,
    quantity: string,
    dueDate: string,
    oderer: string,
    note: string,
    pen: {
        penId: string,
        penName: string,
    },
}


const ReportPharmaceuticalOrder = () => {
    const [dataSickPigs, setDataSickPigs] = React.useState<any[]>([]); // Dữ liệu bệnh lợn
    const [temperature, setTemperature] = useState([37, 39]); // Nhiệt độ
    const [editPigs, setEditPigs] = useState<DataItem>(); // Bệnh lợn đang được chỉnh sửa
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [isShowAddModal, setIsShowAddModal] = useState(false); // Trạng thái hiển thị modal thêm bệnh lợn
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false); // Trạng thái hiển thị modal xóa bệnh lợn
    const [form] = Form.useForm(); // Form thêm bệnh lợn
    const [idDeleteSickPigs, setIdDeleteSickPigs] = useState<string>(); // Id bệnh lợn đang được xóa
    const [warningSickPigs, setWarningSickPigs] = useState<DataItem>(); // Id bệnh lợn đang được cảnh báo
    const [isShowWarningModal, setIsShowWarningModal] = useState(false); // Trạng thái hiển thị modal cảnh báo
    const farm = farmServices.getCurrentFarm() as any;
    const [dataFoodImport, setDataFoodImport] = useState<DataItem[]>([]); // Dữ liệu thức ăn nhập kho
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [startTime, setStartTime] = useState<any>(null); // Thời gian bắt đầu
    const [endTime, setEndTime] = useState<any>(null); // Thời gian kết thúc
    const context = React.useContext(Context);
    const [dataExcel, setDataExcel] = useState<any[]>([]); // Dữ liệu xuất excel
    const user = userServices.get();

    // Các cột của bảng
    const columns: ColumnsType<DataItem> = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Ngày xuất',
            dataIndex: 'oderedDate',
            key: 'oderedDate',
        },
        {
            title: 'Tên người xuất',
            dataIndex: 'oderer',
            key: 'oderer',
        },
        {
            title: 'Tên thuốc/vacxin',
            dataIndex: 'pharmaceuticalName',
            key: 'pharmaceuticalName',
        },
        {
            title: 'Tên chuồng',
            dataIndex: 'pen',
            key: 'pen',
        },
        // {
        //     title: 'Tên nhà cung cấp',
        //     dataIndex: 'foodSupplier',
        //     key: 'foodSupplier',
        // },
        {
            title: 'Quy cách đóng gói',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: 'Số lượng xuất',
            dataIndex: 'quantity',
            key: 'quantity',
            sorter: (a, b) => a.quantity.localeCompare(b.quantity),
        },

    ];

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // lấy dữ liệu từ api
    const fetchData = async () => {
        axios.get(`https://sit.api.pigman.com.vn/report/pharmaceuticalConsume?farmId=${farm.id}`).then((res) => {
            console.log(res.data.data);

            const data = res.data.data.map((item: DataItem, index: any) => {
                return {
                    key: index + 1,
                    oderedDate: moment(item.oderedDate).format('DD/MM/YYYY'),
                    pharmaceuticalName: item.pharmaceuticalName,
                    oderer: item.oderer,
                    pen: item.pen.penName,
                    unit: item.unit,
                    quantity: item.quantity.toString(),

                };
            });

            setDataFoodImport(data);

        });
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        fetchData();
    }, [context.changeLocation.isChangeLocation]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: dataFoodImport.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const onSearch = (value: string) => {
        console.log(value);

    }
    // Hàm thực hiện thay đổi giá trị ngày
    const handleChangeDate = (value: any) => {
        if (value === null) {
            setStartTime(null);
            setEndTime(null);
            return;
        }
        setStartTime(value[0]._d);
        setEndTime(value[1]._d);
    }


    const exportToExcel = async () => {
        try {
            const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
            const tmpEndTime = moment(endTime).format('YYYY-MM-DD');
            console.log(tmpStartTime, tmpEndTime);

            let tmp = ''
            if (startTime)
                tmp += `&startTime=${tmpStartTime}`
            if (endTime)
                tmp += `&endTime=${tmpEndTime}`
            const base64String = await axios.get(`https://sit.api.pigman.com.vn/report/reportFile?userId=${user.id}&farmId=${farm.id}&reportType=pharmaceuticalConsume${tmp}&size=1000`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                })
            // use fs to convert the buffer to a file
            console.log(base64String);
            const binaryData = atob(base64String);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                view[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const str = farm.name;
            const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            saveAs(blob, `Bao cao thuoc/vacxin xuat kho_${str1}.xlsx`);
        } catch (error) {
            console.log(error);
        }
    };


    // Hàm thực hiện khi thay đổi các dữ liệu lọc
    useEffect(() => {
        const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
        const tmpEndTime = moment(endTime).format('YYYY-MM-DD');
        console.log(tmpStartTime, tmpEndTime);

        let tmp = ''
        if (startTime)
            tmp += `&startTime=${tmpStartTime}`
        if (endTime)
            tmp += `&endTime=${tmpEndTime}`
        axios.get(`https://sit.api.pigman.com.vn/report/pharmaceuticalConsume?farmId=${farm.id}` + tmp)
            .then(res => {
                console.log(res.data.data);
                const data = res.data.data.map((item: DataItem, index: any) => {
                    return {
                        key: index + 1,
                        pharmaceuticalName: item.pharmaceuticalName,
                        oderedDate: moment(item.oderedDate).format('DD/MM/YYYY'),
                        pen: item.pen.penName,
                        unit: item.unit,
                        quantity: item.quantity.toString(),
                        // dueDate: moment(item.dueDate).format('DD/MM/YYYY'),
                        oderer: item.oderer
                    };
                });
                setDataFoodImport(data);
            })
    }, [startTime, endTime])
    const sortData = filter.length === 0 ? dataFoodImport : dataFoodImport.filter((item: DataItem) => item.pharmaceuticalName.toLowerCase().includes(filter));

    return (
        <>
            <div className='report-pig-main food-pig'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Báo cáo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Thống kê thuốc/vacxin xuất kho</Breadcrumb.Item>

                </Breadcrumb>
                <div className='button-report-pig'>
                    {/* <Search placeholder="Tìm kiếm bệnh lợn theo tên" onSearch={onSearch} enterButton allowClear /> */}
                    {/* <div className='number-report-pig'>Số con: {dataFoodImport.length}</div> */}
                    <Search
                        className='search-userList'
                        placeholder="Tìm kiếm theo tên thuốc/vacxin"
                        onChange={(event) => setFilter(event.target.value)}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} onChange={handleChangeDate} />
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-report-pig'>
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                            locale={{
                                triggerDesc: 'Sắp xếp theo thứ tự giảm dần',
                                triggerAsc: 'Sắp xếp theo thứ tự tăng dần',
                                cancelSort: 'Hủy sắp xếp',
                            }}
                        />
                    </div>
                </Spin>
                <div className='button-export'>
                    {/* <CSVLink data={dataExcel} headers={['Báo cáo thức ăn nhập kho']} filename={'báo cáo thức ăn nhập kho.csv'}>
                        <Button type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </CSVLink> */}
                    <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>

                </div>
            </div>
        </>
    )
}

export default ReportPharmaceuticalOrder