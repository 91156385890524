/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

class Utils {
    static parseUrl(arg0: { refresh_token: void; client_id: string; grant_type: string; scope: string; orgId: string; }) {
        throw new Error('Method not implemented.');
    }
    static getValueLocalStorage(key: string): any | null {
        const value = localStorage.getItem(key);
        let re = null;
        value && (re = Utils.parseJson(value));
        return re;
    }

    static parseJson(str: string): any | null {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static querySearchToString(filters: any): string {
        const _filters = { ...filters };
        const searchParams = Object.keys(_filters).map(filterKey => {
            if (_filters[filterKey] === undefined || _filters[filterKey] === null) {
                return '';
            }
            return `${filterKey}=${_filters[filterKey]}`;
        }).join('&');
        return searchParams;
    }

    static isNullOrEmpty(value: string): boolean {
        if (value === null || value === undefined || value.trim() === '') {
            return true;
        }
        return false;
    }

    static convertTimeUTCToLocalTime(date: Date): string {
        const myDate = new Date(date).toLocaleDateString('en-US');
        // myDate is 12/28/2021

        const myTime = new Date(date).toLocaleTimeString('en-US');
        return myDate + ' ' + myTime;
    }
}

export default Utils