/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetcher } from "./sender";
import { CFG_BASE_URL } from '../constants/config'
import { IInitialOrderInput } from '@type/food-order';
import { IPagination } from '@type/common';

export const createOrder = (data: IInitialOrderInput) => {
    return fetcher.post(`${CFG_BASE_URL}/foodOrder`, data as any);
}

export const getFoodOrder = (foodSupplierId: string, params: IPagination) => {
    return fetcher.get(`${CFG_BASE_URL}/foodOrder/foodSupplier`, { foodSupplierId, ...params });
}

export const getOrderDetail = (orderId: string) => {
    return fetcher.get(`${CFG_BASE_URL}/foodOrder/${orderId}`);
}

export const removeOrder = (orderId: string) => {
    return fetcher.delete(`${CFG_BASE_URL}/foodOrder/${orderId}`);
}

export const updateOrder = (orderId: string, dataUpdate: any) => {
    return fetcher.put(`${CFG_BASE_URL}/foodOrder/${orderId}`, dataUpdate)
}