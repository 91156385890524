/* eslint-disable @typescript-eslint/no-explicit-any */
import GlobalService from "@src/src/app/utils/global";
import { Button, Checkbox } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import DoubleArrow from "../doubleArrow/doubleArrow";
import { ArrowDirection } from "../doubleArrow/doubleArrow.types";
import CustomSteps, { IStep } from "./customStep";
import './guideline.scss';

interface IGuideLineModalDetail {
    guideLineRenderProps: {
        title: string,
        visible: boolean,
        handleOk?: () => void,
        confirmLoading?: boolean,
        handleCancel: () => void,
        modalText: string,
        currentProcess: number,
        lstSteps: IStep[],
        style?: any,
        isDisableNextBtn?: boolean,
        arrowDirection?: ArrowDirection
    },
}

const GuideLine: FC<IGuideLineModalDetail> = ({ guideLineRenderProps }: IGuideLineModalDetail) => {
    const { title, handleOk, visible, handleCancel, confirmLoading, modalText, currentProcess, lstSteps, style, isDisableNextBtn, arrowDirection } = guideLineRenderProps;
    const maxGuidelineWidth = 420;
    const [loaded, setLoaded] = useState<boolean>(false);
    const onSelectedChange = (value: any) => {
        console.log(value)
        if (value) {
            handleCancel();
        }
    }

    useEffect(() => {
        setTimeout(() => {
            const antModelWrap = document.getElementsByClassName('ant-modal');
            if (antModelWrap != null && antModelWrap.length > 0) {
                console.log(antModelWrap)
                if (antModelWrap[0].parentElement !== null && antModelWrap[0].parentElement !== undefined) {
                    antModelWrap[0].parentElement.style.setProperty('top', style.top + "px");
                    antModelWrap[0].parentElement.style.setProperty('left', style.left + window.innerWidth / 2 - maxGuidelineWidth / 2 + "px");
                }
            }
            setLoaded(true);

            // antModelWrap[0].appendChild(document.getElementsByClassName('ant-modal-mask')[0])
        }, 1000)

    }, [style])
    // React.useEffect(() => {
    //     const offsetLeft = ;
    //     const offsetWidth = guidelineModelContentRef.current?.offsetWidth ? guidelineModelContentRef.current?.offsetWidth / 2 : 1;
    //     const offsetTop = style.top;
    //     setGuidelineStyle({
    //         doubleArrowDetailProps: {
    //             direction: 'downArrow',
    //             doubleArrowStyle: {
    //                 left: offsetLeft,
    //                 top: offsetTop
    //             },
    //             visible: true
    //         }
    //     })
    // }, [guidelineModelContentRef])
    return (
        <div>
            <div>
                <Modal
                    visible={visible}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    style={{ display: loaded ? 'block' : 'none' }}
                    closable={false}
                    footer={null}
                    zIndex={800}
                >
                    <CustomSteps stepProps={{ current: currentProcess, lstSteps: lstSteps }}></CustomSteps>
                    <div className='mainContent'>
                        <h3 id="titleText">{title}</h3>
                        <div id='subContent'>
                            <p id="modalText">{modalText}</p>
                        </div>
                        <div className='footer'>
                            <Checkbox style={{ fontSize: '12px' }} onChange={onSelectedChange}>Không hiển thị lại</Checkbox>
                            <Button type="primary" disabled={isDisableNextBtn ?? false} color={GlobalService.color} onClick={handleOk}>
                                Tiếp tục
                            </Button>
                        </div>
                    </div>

                </Modal>
                {/* <Modal
                    visible={visible}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                    closable={false}
                    style={style}
                    footer={null}
                    zIndex={800}
                >
                    <CustomSteps stepProps={{ current: currentProcess, lstSteps: lstSteps }}></CustomSteps>
                    <div className='mainContent'>
                        <h3 id="titleText">{title}</h3>
                        <div id='subContent'>
                            <p id="modalText">{modalText}</p>
                        </div>
                        <div className='footer'>
                            <Checkbox style={{ fontSize: '12px' }} onChange={onSelectedChange}>Không hiển thị lại</Checkbox>
                            <Button type="primary" disabled={isDisableNextBtn ?? false} color={GlobalService.color} onClick={handleOk}>
                                Tiếp tục
                            </Button>
                        </div>
                    </div>

                </Modal> */}
            </div>
            {visible && <DoubleArrow doubleArrowDetailProps={{
                direction: arrowDirection,
                doubleArrowStyle: { top: style.top, left: style.left + window.innerWidth / 2 },
                visible: true
            }} />}
        </div>
    )
};

export default GuideLine;