import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';

import './style.gatewayfarm.scss';
import { Breadcrumb, Button, Select, Spin, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import userServices from '@services/user';
import * as XLSX from 'xlsx';
import farmService from '@services/farm';
import { apiPen } from '@src/src/app/api/pen';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'TÊN',
        dataIndex: 'gatewayName',
    },
    {
        title: 'MÃ SỐ',
        dataIndex: 'gatewayCode',
    },
    {
        title: 'TÊN CHUỒNG',
        dataIndex: 'penName',
    },
    {
        title: 'THỜI GIAN TRUYỀN GẦN NHẤT',
        dataIndex: 'time',
    },
    // {
    //     title: 'OFFSET NHIỆT ĐỘ',
    //     dataIndex: 'temperature',
    // },
    // {
    //     title: 'OFFSET ĐỘ ẨM',
    //     dataIndex: 'humidity',
    // },
    // {
    //     title: 'OFFSET ÁP SUẤT',
    //     dataIndex: 'pressure',
    // },
    {
        title: 'TRẠNG THÁI',
        dataIndex: 'status',
    }
];
const Option = Select.Option;
const GatewayFarm: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstFarm, setLstFarm] = useState<any>([]);
    const [farmId, setFarmId] = useState<any>(null);
    const user = userServices.get();
    const currentFarm = farmService.getCurrentFarm();
    const [pens, setPens] = useState<any>([]);
    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();
    const history = useHistory();

    useEffect(() => {
        getAllPens();
    }, []);


    useEffect(() => {
        if (!pens) return;
        setLoading(true);
        fetchData(1);
    }, [pens]);

    const fetchData = async (page: any) => {
        // Mở loading
        setLoading(true);
        console.log(page);
        const url = 'https://sit.api.pigman.com.vn/farms/getGateway/all'

        console.log(url);

        await axios.get(url)
            .then((response) => {
                console.log(response.data.data);

                let tmp = response.data.data;

                // Lấy ra 2 mảng mà active = true và active = false
                const activeTrue = tmp.filter((item: any) => item.active === true);
                const activeFalse = tmp.filter((item: any) => item.active === false);

                // Sắp xếp lại mảng activeTrue theo thời gian giảm dần
                activeTrue.sort((a: any, b: any) => {
                    return new Date(b.lastSyncAt).getTime() - new Date(a.lastSyncAt).getTime();
                });

                // Sắp xếp lại mảng activeFalse theo thời gian giảm dần
                activeFalse.sort((a: any, b: any) => {
                    return new Date(b.lastSyncAt).getTime() - new Date(a.lastSyncAt).getTime();
                });

                // Gộp 2 mảng activeTrue và activeFalse lại
                tmp = activeTrue.concat(activeFalse);


                let count = pageSize * (page - 1);
                setTotalRecords(response.data.data.total)
                setData(
                    tmp.map((obj: any) => {
                        count += 1;
                        // Lấy ra tên trang trại theo gatewayCode của mảng trang trại
                        if (!pens) return;

                        const penName = pens?.find((item: any) => item.gatewayCode === obj.code)?.name;
                        return {
                            key: count, // STT (cột đầu tiên)
                            gatewayName: 'Gateway ' + obj.code,
                            gatewayCode: obj.code,
                            penName: penName || 'Không xác định', // Tên trang trại
                            time: moment(obj.lastSyncAt).format('DD/MM/YYYY HH:mm:ss'),
                            temperature: obj.offsetTemp,
                            humidity: obj.offsetHumi,
                            pressure: obj.offsetPress,
                            status: obj.active ? 'Hoạt động' : 'Không hoạt động',
                        };
                    }),
                );
            })
            .then(() => {
                setLoading(false);
            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }


    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);

        // Gán giá trị trang hiện tại và số bản ghi trên một trang ngay lập tức
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);

        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
            fetchData(page);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalRecords,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };

    const getAllPens = async (isDelete = false) => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm?.id,
            name: '',
            area: 0,
            weightTypeId: '',
        };
        console.log(params);

        await apiPen
            .getAllPen(params)
            .then((res: any) => {
                console.log(res.items);
                setPens(res.items);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handleChangeFarmList = (value: any) => {
        setFarmId(value);
        setCurrentPage(1);
    }
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter.toLowerCase()));
    const exportToExcel = () => {
        // Map over sortData to create a new array with the desired column names
        const dataWithVietnameseKeys = sortData.map((item: any) => ({
            'STT': item.key,
            'TÊN': item.gatewayName,
            'MÃ SỐ': item.gatewayCode,
            'TÊN CHUỒNG': item.penName,
            'THỜI GIAN TRUYỀN GẦN NHẤT': item.time,
            'OFFSET NHIỆT ĐỘ': item.temperature,
            'OFFSET ĐỘ ẨM': item.humidity,
            'OFFSET ÁP SUẤT': item.pressure,
            'TRẠNG THÁI': item.status,
        }));

        const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
        ws['!cols'] = [
            { width: 5 },
            { width: 25 },
            { width: 15 },
            { width: 15 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 25 },
            { width: 15 },
        ];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "Danh sach gateway cua Lon.xlsx");
    };
    return (
        <>
            <div className="farmList-body">
                <div className='main-breadcrumb'>
                    <Breadcrumb >
                        <Breadcrumb.Item>
                            <span onClick={() => history.push('/trang-trai')}>Trang trại</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Danh sách Gateway</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="button-farmList">
                    <p className="farmList-h2">Danh sách Gateway</p>
                    <div className="search-and-sort">
                        {/* <Search placeholder="Tìm kiếm theo trang trại" onSearch={onSearch}
                            onChange={(event) => setFilter(event.target.value)}
                            enterButton
                            allowClear
                        /> */}
                        {/* <Select
                            className='select-behaviour-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                            onChange={handleChangeFarmList}
                            placeholder="Lọc theo trang trại"
                        >
                            {lstFarm?.map((item: any, index: any) => (
                                <Option key={index} value={item.gatewayCode} title={item.name}>{item.name}</Option>
                            ))}
                        </Select> */}
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className="table-farmList">
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        {/* <div className='button-excel'>
                            <Button disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                        </div> */}
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default GatewayFarm;
