/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react';
import Modal from '@elements/modal/modal';
import { penConsumeFoodDetail } from './step/food-step-columns';
import { Table } from 'antd';

import * as farmFoodConsumeApi from '@api/farm-food-consume';

import { UtilCollections } from '@utils/collections';

const DetailFoodConsume: FC<any> = ({ foodConsumeId, isShowModal, toggleModal }: any) => {
    const [detailFoodConsume, setDetailFoodConsume] = React.useState<any>();
    const [lstPenFoodConsume, setLstPenFoodConsume] = React.useState<any[]>([]);
    React.useEffect(() => {
        (async () => {
            const data = (await farmFoodConsumeApi.getDetail(foodConsumeId)) as any;

            setDetailFoodConsume(data);
        })();
    }, []);

    useEffect(() => {
        console.log(detailFoodConsume?.lstPenFoodConsume);
        // Filter the array to remove objects where all 'items' have 'quantity' equal to 0
        const filteredData = detailFoodConsume?.lstPenFoodConsume.filter((item: any) => {
            if (item.penFoodConsumeItem && item.penFoodConsumeItem.items) {
                // Check if all 'items' have 'quantity' equal to 0
                const allZeroQuantity = item.penFoodConsumeItem.items.every(
                    (subItem: any) => subItem.quantity === 0
                );

                // Include the item in the filtered array if not all 'items' have 'quantity' equal to 0
                return !allZeroQuantity;
            }

            // Include the item if 'penFoodConsumeItem' or 'items' do not exist
            return true;
        });

        console.log(filteredData);
        setLstPenFoodConsume(filteredData);
    }, [detailFoodConsume]);

    return (
        <Modal title="Chi tiết thông tin xuất kho thức ăn" isShowModal={isShowModal} toggleModal={toggleModal} className={'detail-food-consume'}>
            {detailFoodConsume ? (
                <div>
                    <div id="detail-info">
                        <div className="info">
                            <span className="info-title"> Tên người xuất</span>

                            <span className="info-content"> {detailFoodConsume.name} </span>
                        </div>

                        <div className="info">
                            <span className="info-title">Ngày xuất </span>

                            <span className="info-content">
                                {UtilCollections.convertDateToDMY(
                                    UtilCollections.formatClientDate(detailFoodConsume.date.split('T')[0]),
                                )}
                            </span>
                        </div>

                        {/* <div className="info">
                            <span className="info-title"> Được tạo bởi </span>

                            <span className="info-content"> {detailFoodConsume.user.fullName} </span>
                        </div> */}

                        <div className="info">
                            <span className="info-title"> Số chuồng được phát thức ăn </span>

                            <span className="info-content"> {detailFoodConsume.lstPenFoodConsume.length} </span>
                        </div>
                    </div>

                    <div id="pen-food-consume-detail">
                        <h3>Chi tiết phát thức ăn cho từng chuồng</h3>

                        <Table
                            dataSource={lstPenFoodConsume && lstPenFoodConsume.length > 0 ? lstPenFoodConsume : []}
                            columns={penConsumeFoodDetail}
                            pagination={false}
                            bordered
                        />
                    </div>
                </div>
            ) : (
                <span />
            )}
        </Modal>
    );
};

export default DetailFoodConsume;
