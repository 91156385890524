/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFood } from '@type/food';
import React from 'react';

export interface IFoodOrderCreate {
    name: string;
    date: string;
    foodSupplierId: string;
    foodSupplierName?: string;
    foodOrderItems: Array<{
        foodId: string;
        quantity: number
    }>
}

export interface IFoodOrderUpdate {
    name: string;
    date: string;
    foodSupplierId: string;
    foodOrderItemCreate: Array<{
        foodId: string;
        quantity: number
        foodOrderId: string
    }>;
    foodOrderItemUpdate: Array<{
        foodId: string;
        quantity: number
        foodOrderId: string
        id: string
    }>
    totalPrice: number
    orderedTotalPrice?: number
}

export interface IFoodOrderItems extends IFood {
    quantity: number
    price?: number
    subTotal?: number
    expirationDate?: string
}

export enum EModalType {
    CREATE,
    UPDATE,
    DETAIL
}
export interface IOrderFoodModal {
    toggleModal: any
    foodOrderId: string
    modalType: EModalType
}

export enum EOrderType {
    PUT_ON_ORDERED,
    REMOVE_FROM_ORDERED,
}

export interface IFoodConsumeDetailComponent {
    foodOrderId: string
}

export interface IOrderFood {
    id: string,
    price: number
    quantity: number
    subTotal: number
    expirationDate: string
    foodOrderId?: string
    pharmaceuticalId?: string
}

export interface IFoodOrdered extends IOrderFood {
    food: IFood
}

export interface IInitialOrderInput {
    name: string,
    foodSupplierId?: string,
    date: string,
    foodOrderItems?: Array<Partial<IOrderFood>>,
    foodSupplierName?: string,
    farmId?: string,
    totalPrice?: number,
    orderedTotalPrice?: number,
    pharmaceuticalSupplierId?: string
    pharmaceuticalOrderItems?: Array<Partial<IOrderFood>>
    pharmaceuticalSupplierName?: string
}

export interface IUpdateInput extends IInitialOrderInput {
    foodOrderItemCreate: Array<Omit<IOrderFood, 'id'>>
    foodOrderItemUpdate: Array<IOrderFood>
    pharmaceuticalOrderItemCreate?: Array<Omit<IOrderFood, 'id'>>
    pharmaceuticalOrderItemUpdate?: Array<IOrderFood>
}


export interface IFoodConsumeCreateComponent {
    supplierId: string
    refreshAfterAction: () => void
    toggleModal: (isClose: boolean) => () => void
    order: {
        onChangeInputData: (foodId: string, inputName: string, dataInput?: string | number) => (e: React.ChangeEventHandler<HTMLInputElement>) => void,
        doOrder: (foodId: string, orderType: EOrderType) => () => void,
        choosingItems: IFoodOrderItems[],
    }
    data: {
        initialData: IInitialOrderInput
        changeInitialInput: (data: IInitialOrderInput) => void
        foods: IFood[]
    }
    errors: Record<string, string[]>
}

export interface IFoodConsumeUpdateComponent extends Omit<IFoodConsumeCreateComponent, 'supplierId'> {
    foodOrderId: string,
}

export interface IFoodOrderModal {
    toggleModal: (isClose: boolean) => () => void,
    foodOrderId: string,
    modalType: EModalType,
    refreshAfterAction: () => void,
    supplierId: string
}