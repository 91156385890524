/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { createContext, useEffect } from 'react';
import farmServices from '@services/farm';
import userService from '@services/user';

import {
    IConsumeSupplier,
    IFarmFoodConsume,
    IPenFoodConsume,
    IFoodConsumeContext,
    IFoodConsume,
} from '@type/food-consume';
import { useSelectorRoot } from '../../redux/store';

export const FoodConsumeContext = createContext<IFoodConsumeContext | Record<string, any>>({});

const FoodConsumeProvider = ({ children }: any) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const user = userService.get();
    const { currentStep } = useSelectorRoot(state => state.farm);
    const [typeConsume, setTypeConsume] = React.useState<string>('');

    const [foodSupplierData, setFoodSupplierData] = React.useState<IConsumeSupplier>({
        id: '',
        name: '',
        address: '',
        foodItems: [],
    });

    const [farmFoodConsume, setFarmFoodConsume] = React.useState<IFarmFoodConsume>({
        date: '',
        name: '',
        farmFoodConsume: [],
        farmId: '',
        userId: '',
    });

    const [penFoodConsume, setPenFoodConsume] = React.useState<IPenFoodConsume[]>([]);

    useEffect(() => {
        console.log(currentStep);
        if (currentStep < 2) {
            setEmptyData();
        }

    }, [currentStep]);

    useEffect(() => {
        console.log({ penFoodConsume });

    }, [penFoodConsume]);

    useEffect(() => {
        console.log({ farmFoodConsume });
    }, [farmFoodConsume]);

    const removeItemsWithZeroQuantity = (pen: { penFoodConsumeItems: any[]; }) => ({
        ...pen,
        penFoodConsumeItems: pen.penFoodConsumeItems.filter((item) => item.quantity !== 0),
    });

    const removeItemsWithZeroQuantityPharmaceutical = (pen: { penPharmaceuticalConsumeItems: any[]; }) => ({
        ...pen,
        penPharmaceuticalConsumeItems: pen.penPharmaceuticalConsumeItems.filter((item) => item.quantity !== 0),
    })

    // const validationPenFoodConsume = () => {
    //     const updatedData = penFoodConsume.filter((pen) => {
    //         const updatedPen = removeItemsWithZeroQuantity(pen);
    //         return updatedPen.penFoodConsumeItems.length > 0;
    //     });
    //     setPenFoodConsume(updatedData);
    // }


    const setEmptyData = () => {
        setFarmFoodConsume({
            date: '',
            name: '',
            farmFoodConsume: [],
            farmId: '',
            userId: '',
        });
        setPenFoodConsume([]);
    }

    const setSupData = (data: IConsumeSupplier) => setFoodSupplierData(data);

    const setType = (type: string) => setTypeConsume(type);

    const exportFood = (food: IFoodConsume, pen: any, quantity: number) => {
        console.log({ food, pen });
        const validQuantity = +quantity;

        exportFoodToFarm(food, +quantity);

        exportFoodToPen(food, pen, +quantity);

        updateAvailableQuantity(food.id, validQuantity);
    };

    const exportFoodToFarm = (food: any, quantity: number) => {
        const data = farmFoodConsume;
        let dataSetState = data;

        const dataFarmFoodConsumeItems = {
            ...food,
            quantity,
        };

        const dataFarmFoodConsume: any = {
            name: '',
            date: '',
            farmId: currentFarm.id,
            userId: user.id,
            farmFoodConsume: [],
        };
        console.log(data);
        console.log(dataFarmFoodConsumeItems);
        console.log(dataFarmFoodConsume);

        if (typeConsume === 'pharmaceutical') {
            if (data.farmFoodConsume?.length > 0) {
                let isExist = false;
                for (let i = 0; i < data.farmFoodConsume.length; i++) {
                    const currentItems = data.farmFoodConsume[i];

                    if (currentItems.id === food.id) {
                        if (quantity === 0) {
                            data.farmFoodConsume.splice(i, 1);
                            break;
                        }

                        data.farmFoodConsume[i].quantity = quantity;
                        isExist = true;
                        break;
                    }
                }

                // isExist
                //     ? setFarmFoodConsume(() => {
                //           callBack();
                //           return { ...data };
                //       })
                //     : setFarmFoodConsume(() => {
                //           callBack();
                //           return { ...data, farmFoodConsume: dataFarmFoodConsumeItems };
                //       });

                isExist
                    ? (dataSetState = { ...data })
                    : (dataSetState = { ...data, farmFoodConsume: dataFarmFoodConsumeItems });
            } else {
                dataFarmFoodConsume.farmFoodConsume.push(dataFarmFoodConsumeItems);

                dataSetState = dataFarmFoodConsume;
            }

            setFarmFoodConsume(dataSetState);
        }
        else {
            if (data.farmFoodConsume?.length > 0) {
                let isExist = false;
                for (let i = 0; i < data.farmFoodConsume.length; i++) {
                    const currentItems = data.farmFoodConsume[i];

                    if (currentItems.id === food.id) {
                        if (quantity === 0) {
                            data.farmFoodConsume.splice(i, 1);
                            break;
                        }

                        data.farmFoodConsume[i].quantity = quantity;
                        isExist = true;
                        break;
                    }
                }

                // isExist
                //     ? setFarmFoodConsume(() => {
                //           callBack();
                //           return { ...data };
                //       })
                //     : setFarmFoodConsume(() => {
                //           callBack();
                //           return { ...data, farmFoodConsume: dataFarmFoodConsumeItems };
                //       });

                isExist
                    ? (dataSetState = { ...data })
                    : (dataSetState = { ...data, farmFoodConsume: dataFarmFoodConsumeItems });
            } else {
                dataFarmFoodConsume.farmFoodConsume.push(dataFarmFoodConsumeItems);

                dataSetState = dataFarmFoodConsume;
            }

            setFarmFoodConsume(dataSetState);
        }


    };

    const exportFoodToPen = (food: any, pen: any, quantity: number) => {
        const data = penFoodConsume;

        const dataPenFoodConsume = {
            ...food,
            foodSupplierId: foodSupplierData?.id,
            quantity,
        };
        const dataPenPharmaceuticalConsume = {
            ...food,
            pharmaceuticalSupplierId: foodSupplierData?.id,
            quantity,
        }
        let inputPen: any = {};
        console.log(typeConsume);
        console.log(data);

        if (typeConsume === 'pharmaceutical') {
            inputPen = {
                name: '',
                date: '',
                penName: pen.name,
                penId: pen.id,
                penPharmaceuticalConsumeItems: [dataPenPharmaceuticalConsume],
            }
            console.log(inputPen);

            if (data.length > 0) {
                let isExistedPen = false;
                penLoop: for (let penIndex = 0; penIndex < data.length; penIndex++) {
                    const currentPen = data[penIndex];
                    let isExist = false;

                    if (currentPen.penId === pen.id) {
                        isExistedPen = true;

                        for (let i = 0; i < currentPen.penPharmaceuticalConsumeItems.length; i++) {
                            const currentData = currentPen.penPharmaceuticalConsumeItems[i];

                            if (currentData.id === food.id) {
                                if (quantity === 0) {
                                    data[penIndex].penPharmaceuticalConsumeItems.splice(i, 1);

                                    if (data[penIndex].penPharmaceuticalConsumeItems.length === 0) {
                                        data.splice(penIndex, 1);
                                    }
                                    break penLoop;
                                }
                                data[penIndex].penPharmaceuticalConsumeItems[i].quantity = quantity;
                                isExist = true;
                                break;
                            }
                        }

                        if (!isExist) data[penIndex].penPharmaceuticalConsumeItems.push(dataPenPharmaceuticalConsume);
                        break;
                    }
                }

                if (isExistedPen) {

                    setPenFoodConsume([...data]);
                }
                else {
                    const tmpData = [...data, inputPen];
                    console.log(tmpData);

                    const updatedData = tmpData.filter((pen) => {
                        const updatedPen = removeItemsWithZeroQuantityPharmaceutical(pen);
                        return updatedPen.penPharmaceuticalConsumeItems.length > 0;
                    });
                    setPenFoodConsume(updatedData);
                }
            } else {
                const tmpData = [inputPen];
                console.log(tmpData);

                const updatedData = tmpData.filter((pen) => {
                    const updatedPen = removeItemsWithZeroQuantityPharmaceutical(pen);
                    return updatedPen.penPharmaceuticalConsumeItems.length > 0;
                });
                setPenFoodConsume(updatedData);
            }
        }
        else {
            inputPen = {
                name: '',
                date: '',
                penName: pen.name,
                penId: pen.id,
                penFoodConsumeItems: [dataPenFoodConsume],
            };
            if (data.length > 0) {
                let isExistedPen = false;
                penLoop: for (let penIndex = 0; penIndex < data.length; penIndex++) {
                    const currentPen = data[penIndex];
                    let isExist = false;

                    if (currentPen.penId === pen.id) {
                        isExistedPen = true;

                        for (let i = 0; i < currentPen.penFoodConsumeItems.length; i++) {
                            const currentData = currentPen.penFoodConsumeItems[i];

                            if (currentData.id === food.id) {
                                if (quantity === 0) {
                                    data[penIndex].penFoodConsumeItems.splice(i, 1);

                                    if (data[penIndex].penFoodConsumeItems.length === 0) {
                                        data.splice(penIndex, 1);
                                    }
                                    break penLoop;
                                }
                                data[penIndex].penFoodConsumeItems[i].quantity = quantity;
                                isExist = true;
                                break;
                            }
                        }

                        if (!isExist) data[penIndex].penFoodConsumeItems.push(dataPenFoodConsume);
                        break;
                    }
                }

                if (isExistedPen) {

                    setPenFoodConsume([...data]);
                }
                else {
                    const tmpData = [...data, inputPen];
                    const updatedData = tmpData.filter((pen) => {
                        const updatedPen = removeItemsWithZeroQuantity(pen);
                        return updatedPen.penFoodConsumeItems.length > 0;
                    });
                    setPenFoodConsume(updatedData);
                }
            } else {
                const tmpData = [inputPen];
                const updatedData = tmpData.filter((pen) => {
                    const updatedPen = removeItemsWithZeroQuantity(pen);
                    return updatedPen.penFoodConsumeItems.length > 0;
                });
                setPenFoodConsume(updatedData);
            }
        }

    };

    const updateAvailableQuantity = (foodId: string, quantity: number) => {
        const data = foodSupplierData?.foodItems.map((v: any) => {
            if (v.id === foodId) {
                v.totalOrder = v.totalOrder
                    ? v.totalOrder < v.quantity
                        ? (v.totalOrder += Math.abs(quantity))
                        : v.quantity
                    : quantity;
            }

            return v;
        });

        setFoodSupplierData({ ...foodSupplierData, foodItems: data });
    };


    // const completeApiData = () => {};

    return (
        <FoodConsumeContext.Provider
            value={{
                supplier: { foodSupplierData, setSupData, exportFood, setType },
                foodConsume: { penFoodConsume, farmFoodConsume },
            }}>
            {children}
        </FoodConsumeContext.Provider>
    );
};

export default FoodConsumeProvider;
