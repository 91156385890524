/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { RenderValidationError } from '@components/errors/validation';
import { Table, DatePicker, message, notification, Button } from 'antd';

import { IInitialOrderInput, IUpdateInput } from '@type/food-order';
import { IFoodSupplierData } from '@type/food-supplier';

import { apiFoodSupplier } from '@api/food-supplier';
import * as foodOrderApi from '@api/food-order';
import * as orderItemApi from '@api/food-order-item';

import farmServices from '@services/farm';
import { DATE_FORMAT } from '@constants/config';
import { UtilCollections } from '@utils/collections';

import { choosingColumns, choosingColumnsPharmaceutical, updateColumns, updateColumnsPharmaceutical } from '../../food-order/food-order-columns';

import { IFoodConsumeUpdateComponent, EOrderType, IFoodOrdered } from '@type/food-order';
import axios from 'axios';

const PharmaceuticalOrderUpdate: FC<IFoodConsumeUpdateComponent> = ({
    order,
    foodOrderId,
    toggleModal,
    refreshAfterAction,
    data,
    errors: inputErrors,
}: IFoodConsumeUpdateComponent) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const { doOrder, onChangeInputData, choosingItems } = order;
    const { changeInitialInput, initialData, foods } = data;

    const [suppliers, setSuppliers] = React.useState<IFoodSupplierData[]>([]);

    const [updateOrderedList, setUpdateOrderedList] = React.useState<IFoodOrdered[]>([]);
    const [foodSupplierId, setFoodSupplierId] = React.useState<string>('');
    const [foodLst, setFoodLst] = React.useState<any>([]);
    const [disableFoodSupplier, setDisableFoodSupplier] = React.useState<any>(false);
    const [orderType, setOrderType] = React.useState<any>('');
    const [date, setDate] = React.useState<any>();
    const [orderDetail, setOrderDetail] = React.useState<any>();
    const [listOrderItems, setListOrderItems] = React.useState<any[]>([]);

    useEffect(() => {
        console.log(choosingItems);

    }, [choosingItems]);

    React.useEffect(() => {
        getSuppliers();
        getOrderDetail();
        getOrderItemsList();
        // (async () => {
        //     const detail = (await getOrderDetail()) as any;
        //     const listOrderItems = (await getOrderItemsList()) as any;
        //     console.log(detail);
        //     setDate(detail.date);
        //     changeInitialInput({
        //         name: detail.name,
        //         date: detail.date,
        //         foodSupplierId: detail.foodSupplier.id,
        //         foodSupplierName: detail.foodSupplier.name,
        //         orderedTotalPrice: detail.totalPrice,
        //         totalPrice: 0,
        //     });
        //     console.log(initialData);

        //     setOrderedItems(listOrderItems.items);
        //     setFoodSupplierId(detail.foodSupplier.id);
        // })();
    }, []);

    useEffect(() => {
        if (!orderDetail || orderDetail.lenght > 0) return;
        setDate(orderDetail.date);
        changeInitialInput({
            name: orderDetail.name,
            date: UtilCollections.convertDateToDMY(UtilCollections.formatClientDate(orderDetail.date.split('T')[0])),
            foodSupplierId: orderDetail.pharmaceuticalSupplier.id,
            foodSupplierName: orderDetail.pharmaceuticalSupplier.name,
            orderedTotalPrice: orderDetail.totalPrice,
            totalPrice: 0,
        });
        setFoodSupplierId(orderDetail.pharmaceuticalSupplier.id);
    }, [orderDetail]);

    useEffect(() => {
        getFoodList(null);
    }, [foodSupplierId]);

    const getFoodList = async (lstId: any) => {
        if (lstId) {
            if (foodSupplierId) {
                await axios.get(`https://sit.api.pigman.com.vn/pharmaceutical/pharmaceuticalSupplier/${foodSupplierId}?offset=0&size=1000`).then((res) => {
                    console.log(res.data.data.items);
                    const newArray = res.data.data.items.filter((item: any) => !lstId.includes(item.id));
                    setFoodLst(newArray);

                    // getOrderItemsList();
                });
            }
        }
        else {
            if (foodSupplierId) {
                await axios.get(`https://sit.api.pigman.com.vn/pharmaceutical/pharmaceuticalSupplier/${foodSupplierId}?offset=0&size=1000`).then((res) => {
                    console.log(res.data.data.items);
                    setFoodLst(res.data.data.items);
                    // getOrderItemsList();
                });
            }
        }


    }
    useEffect(() => {
        if (listOrderItems.length > 0) {
            const idsToRemove = listOrderItems.map((item: any) => item.pharmaceutical.id);
            const newArrayA = foodLst.filter((item: any) => !idsToRemove.includes(item.id));
            console.log(newArrayA);

            setFoodLst(newArrayA);
        }
    }, [listOrderItems]);
    // useEffect(() => {
    //     console.log(listOrderItems);
    //     const idsToRemove = listOrderItems.map((item: any) => item.pharmaceutical.id);
    //     const newArrayA = foodLst.filter((item: any) => !idsToRemove.includes(item.id));
    //     setFoodLst(newArrayA);
    // }, [listOrderItems]);

    useEffect(() => {
        orderType && setDisableFoodSupplier(true)
    }, [orderType]);


    const getSuppliers = async () => {
        const params = {
            offset: 0,
            size: 1000,
            farmId: currentFarm.id,
        };
        await axios.post(`https://sit.api.pigman.com.vn/pharmaceuticalSuppliers/farm`, params)
            .then((res: any) => {
                console.log(res.data.data.items);
                setSuppliers(res.data.data.items);
            })
    };

    const getOrderDetail = async () => {
        console.log(foodOrderId);

        await axios.get(`https://sit.api.pigman.com.vn/pharmaceuticalOrder/${foodOrderId}`)
            .then((res) => {
                console.log(res.data.data);
                setOrderDetail(res.data.data);
            })
    }

    const getOrderItemsList = async () => {
        const req = {
            offset: 0,
            size: 1000,
            pharmaceuticalOrderId: foodOrderId,
        }
        await axios.post(`https://sit.api.pigman.com.vn/pharmaceuticalOrderItem/pharmaceuticalOrder`, req)
            .then((res) => {
                console.log(res.data.data.items);
                setListOrderItems(res.data.data.items);
            })
    }

    const schema = Yup.object().shape({
        // foodSupplierId: Yup.string().required('Vui lòng chọn nhà cung cấp'),
        // date: Yup.string().required('Trường này bắt buộc'),
        // name: Yup.string().required('Trường này bắt buộc'),
    });

    const doSubmit = async (values: IInitialOrderInput, actions: any) => {
        delete values.foodSupplierName;
        console.log(values);

        await updateOrder(values as any);

    };

    const updateOrder = async (values: IUpdateInput) => {
        const commonDataUpdate = (v: any) => {
            console.log(v);

            return {
                pharmaceuticalId: v.pharmaceutical ? v.pharmaceutical.id : v.id,
                quantity: v.quantity,
                pharmaceuticalOrderId: foodOrderId,
                price: v.price,
                subTotal: v.subTotal,
                expirationDate: v.expirationDate || moment(new Date(), 'YYYY-MM-DD'),
            };
        };
        values.pharmaceuticalSupplierId = foodSupplierId;
        values.pharmaceuticalOrderItemCreate = choosingItems.map((v: any) => commonDataUpdate(v));
        values.pharmaceuticalOrderItemUpdate = listOrderItems.map((v: any) => ({
            ...commonDataUpdate(v),
            id: v.id,
        }));
        values.date = UtilCollections.formatDateSearchBeHaviour(values.date);
        const lstTmp = [...values.pharmaceuticalOrderItemCreate, ...values.pharmaceuticalOrderItemUpdate];
        const lstTmp2 = lstTmp.map((v: any) => {
            console.log(v);

            return {
                pharmaceuticalId: v.pharmaceuticalId,
                quantity: v.quantity,
            }
        });
        if (lstTmp2.length === 0) {
            notification.error({
                message: 'Vui lòng chọn thức ăn',
                description: 'Thức ăn không được để trống',
            } as any);
            return;
        }
        else {
            const tmp = {
                name: values.name,
                date: values.date,
                lst: lstTmp2,
            }
            // values.totalPrice = initialData.totalPrice += initialData.orderedTotalPrice || 0;
            console.log(tmp);

            delete initialData.orderedTotalPrice;

            await axios.put(`https://sit.api.pigman.com.vn/pharmaceuticalOrder/${foodOrderId}`, tmp)
                .then((res) => {
                    console.log(res.data.data);
                    // message.success('Cập nhật thành công');
                    notification.success({
                        message: 'Cập nhật thành công',
                        description: '',
                    });
                    refreshAfterAction();
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    notification.error({
                        message: 'Cập nhật thất bại',
                        description: err.response.data.message,
                    })
                });

            toggleModal(false)();

        }

    };

    const onChangeInputUpdateData = (orderData: any, inputName: string, inputData: string) => (e: any) => {
        const data = { ...orderData, [inputName]: inputData || +e.target.value || '' };
        const orderedListData = updateOrderedList;

        for (let i = 0; i < orderedListData.length; i++) {
            if (updateOrderedList[i].id === orderData.id) {
                orderedListData[i] = data;
                break;
            }

            orderedListData.push(data);
        }

        if (orderedListData.length === 0) orderedListData.push(data);

        setUpdateOrderedList([...orderedListData]);

        let orderedTotalPrice = 0;
        const orderedListUpdate: any = listOrderItems.map((data: any) => {
            if (data.id === orderData.id) {
                data[inputName] = inputData || +e?.target?.value || '';
                data.subTotal = data.quantity * data.price || 0;
            }

            orderedTotalPrice += +data.subTotal;

            return data;
        });

        setListOrderItems([...orderedListUpdate]);
        changeInitialInput({ ...initialData, orderedTotalPrice });
    };

    // Define the disabledDate function
    const disabledDate = (current: any) => {
        // Disable dates that are after the current date
        return current && current > moment().endOf('day');
    };

    const removeOrderedItem = (itemId: string) => async () => {
        // await orderItemApi.removeOrderItem(itemId);
        setListOrderItems(listOrderItems.filter((v: any) => v.id !== itemId));
        // notification.success({
        // message: 'Xóa thành công',
        // });
    };

    useEffect(() => {
        const lstFoodId = listOrderItems.map((item: any) => item.pharmaceutical.id);

        getFoodList(lstFoodId);
    }, [listOrderItems]);

    return (
        <Formik initialValues={initialData} onSubmit={doSubmit} validationSchema={schema} enableReinitialize>
            {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form className="log-form" >
                    <div className="log-field">
                        <label htmlFor="name" className="log-label">
                            Tên người nhập kho <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                name="name"
                                className={`log-input`}
                                type={'text'}
                                id="name"
                                placeholder={'Tên'}
                            // value={initialData.name}
                            />
                            <ErrorMessage component={RenderValidationError} name="name" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="date" className="log-label">
                            Ngày nhập <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            {date &&
                                <>
                                    <DatePicker
                                        name="date"
                                        className={`log-input`}
                                        id="date"
                                        placeholder={'Ngày'}
                                        onChange={(date, dateString) => setFieldValue('date', dateString)}
                                        format={DATE_FORMAT}
                                        defaultValue={
                                            moment(
                                                date ? date : new Date(),
                                            )}
                                        disabledDate={disabledDate}

                                    />
                                    <ErrorMessage component={RenderValidationError} name="date" />
                                </>
                            }
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="foodSupplierId" className="log-label">
                            Hãng sản xuất <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                disabled={true}
                                as="select"
                                name="foodSupplierId"
                                className={'log-input'}
                                onChange={(e: any) => {
                                    setFieldValue('foodSupplierId', e.target.value);
                                    setFoodSupplierId(e.target.value);
                                }}>
                                <option value="">Chọn nhà cung cấp</option>
                                {suppliers.map((v, i) => (
                                    <option key={i} value={v.id}>
                                        {v.name}
                                    </option>
                                ))}
                            </Field>

                            <ErrorMessage component={RenderValidationError} name="foodSupplierId" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label htmlFor="listOrderItems" className="log-label">
                            Thuốc/vắc xin <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                        </label>
                        <div className="control has-icon-right">
                            <Field
                                disabled={foodLst.length === 0}
                                as="select"
                                name="listOrderItems"
                                className={'log-input'}
                                onChange={(e: any) => {
                                    doOrder(e.target.value, EOrderType.PUT_ON_ORDERED)();
                                    setOrderType(e.target.value);

                                }}
                                defaultChecked={''}
                                multiple={false}>
                                {(orderType === '' && foodLst.length > 0) &&
                                    <option value="" >Chọn thức ăn</option>
                                }
                                {foodLst.map((v: any) => {
                                    return (
                                        <option key={v.id} value={v.id}>
                                            {v.name}
                                        </option>
                                    );
                                })}
                                {foodLst.length === 0 && <option value="">Đã hết thức ăn chọn</option>}
                            </Field>

                            <ErrorMessage component={RenderValidationError} name="listOrderItems" />
                        </div>
                    </div>

                    <div className="log-field">
                        <label className="log-label">Thuốc/vắc xin đã chọn</label>
                        <div className="control has-icon-right">
                            <Table
                                columns={choosingColumnsPharmaceutical(onChangeInputData, doOrder, inputErrors)}
                                dataSource={choosingItems}
                                pagination={false}
                                size="small"
                                locale={{ emptyText: 'Chưa chọn thức ăn' }}
                            // footer={() => `Tổng giá: ${UtilCollections.VNDCurrencyFormat(initialData.totalPrice)}`}
                            />
                        </div>
                    </div>

                    <div className="log-field ">
                        <label className="log-label">Thuốc/vắc xin đã đặt</label>
                        <div className="control has-icon-right">
                            <Table
                                columns={updateColumnsPharmaceutical(onChangeInputUpdateData, removeOrderedItem)}
                                dataSource={listOrderItems}
                                pagination={false}
                                size="small"
                                locale={{ emptyText: 'Không có thức ăn' }}

                            />
                        </div>
                    </div>

                    <div className="log-field button-submit">
                        <Button onClick={
                            () => {
                                toggleModal(false)();
                            }
                        }>
                            Hủy bỏ
                        </Button>
                        <Button className='btn-submit-form' type='primary' htmlType='submit' >
                            Cập nhật
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default PharmaceuticalOrderUpdate;
