/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect } from 'react';
import { FoodConsumeContext } from '@components/food-consume/food-consume-context';

import { Button, Modal, Table } from 'antd';
import { penConsumeFood } from './food-step-columns';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { RenderValidationError } from '@components/errors/validation';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from '@constants/config';
import * as Yup from 'yup';

import moment from 'moment';

import { EStepStatus, IFinalStep, IFoodConsumeContext } from '@type/food-consume';

const FinalStep: FC<IFinalStep> = ({ prev, save, setStepStatus }: IFinalStep) => {

    const [showForm, setShowForm] = React.useState<any>(true);
    const [isShowNotiModal, setIsShowNotiModal] = React.useState<any>(false);
    const [valueSubmit, setValueSubmit] = React.useState<any>({});
    const [lstData, setLstData] = React.useState<any>([]);

    const removeItemsWithZeroQuantity = (pen: { penFoodConsumeItems: any[]; }) => ({
        ...pen,
        penFoodConsumeItems: pen.penFoodConsumeItems.filter((item) => item.quantity !== 0),
    });

    useEffect(() => {
        const data = foodConsumeContext.foodConsume.penFoodConsume;
        console.log(data);

        const tmpData = [];
        for (let index = 0; index < data.length; index++) {
            const currentPen = data[index];
            const tmp = [];
            for (let itemIndex = 0; itemIndex < data[index].penFoodConsumeItems.length; itemIndex++) {
                const item = data[index].penFoodConsumeItems[itemIndex];
                if (item.quantity !== 0) {
                    tmp.push(item);
                }
            }
            tmpData.push({
                ...currentPen,
                penFoodConsumeItems: tmp,
            })
        }

        setLstData(tmpData);
    }, []);

    useEffect(() => {
        console.log(lstData);
    }, [lstData]);

    const initialConsumeInput: { name: string; date: string } = {
        name: '',
        date: moment(new Date()).format(DATE_FORMAT),
    };
    const foodConsumeContext = React.useContext(FoodConsumeContext) as IFoodConsumeContext;

    const doSubmit = (values: { name: string; date: string }, actions: any) => {
        const data = {
            ...foodConsumeContext.foodConsume.farmFoodConsume,
            penFoodConsume: lstData,
        }
        console.log(data);

        save(data, values);

        setStepStatus(EStepStatus.COMPLETE, 2);
    };

    const schema = Yup.object().shape({
        name: Yup.string().required('Trường này bắt buộc'),
        date: Yup.string().required('Trường này bắt buộc'),
    });
    // Define the disabledDate function
    const disabledDate = (current: any) => {
        // Disable dates that are after the current date
        return current && current > moment().endOf('day');
    };
    return (
        <div className="wrapper-form">
            <Formik initialValues={initialConsumeInput} onSubmit={doSubmit} validationSchema={schema}>
                {({ errors, touched, isSubmitting, setFieldValue }) => (
                    <Form className="log-form" style={{ width: '95%' }}>
                        <div className="log-field">
                            <label htmlFor="date" className="log-label">
                                Tên người xuất <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <Field
                                    name="name"
                                    className={`log-input`}
                                    type={'text'}
                                    id="name"
                                    placeholder={'Tên'}
                                />
                                <ErrorMessage component={RenderValidationError} name="name" />
                            </div>
                        </div>

                        <div className="log-field">
                            <label htmlFor="date" className="log-label">
                                Ngày xuất <span style={{ color: 'red', marginLeft: 5 }}>*</span>
                            </label>
                            <div className="control has-icon-right">
                                <DatePicker
                                    name="date"
                                    className={`log-input`}
                                    id="date"
                                    placeholder={'Chọn ngày'}
                                    onChange={(date, dateString) => setFieldValue('date', dateString)}
                                    format={DATE_FORMAT}
                                    defaultValue={moment(new Date(), DATE_FORMAT)}
                                    disabledDate={disabledDate}
                                />
                                <ErrorMessage component={RenderValidationError} name="date" />
                            </div>
                        </div>

                        <div className="log-field">
                            <label htmlFor="date" className="log-label">
                                Xuất thức ăn cho trang trại
                            </label>
                            <div className="control has-icon-right">
                                <Table
                                    dataSource={lstData}
                                    columns={penConsumeFood}
                                    pagination={false}
                                    bordered
                                />
                            </div>
                        </div>

                        <div className="wrapper-action">
                            {/* <button className="btn" > */}
                            {/* Lùi lại */}
                            {/* </button> */}
                            <Button onClick={prev} className={'btn back'} >
                                Lùi lại
                            </Button>
                            {lstData.length > 0 ?

                                <Button className="btn" type='primary' htmlType="submit">
                                    Lưu
                                </Button>
                                :
                                <Button className="btn" type='primary' htmlType="submit" disabled>
                                    Lưu
                                </Button>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
            {/* <Modal
                visible={isShowNotiModal}
                title='Thông báo'
                onCancel={handleCancel}
                maskClosable={true}
                footer={false}
                className='delete-modal'
            >
                <div>Bạn có chắc chắn muốn lưu lại thông tin của đơn xuất này? <br /> Khi bạn đã lưu sẽ không thể thay đổi hay xóa được đơn xuất</div>
                <div className='action-btn'>
                    <Button className='cancel-btn' type="primary" onClick={handleCancel}>
                        Hủy bỏ
                    </Button>
                    <Button className='summit-btn' type="primary" htmlType="submit" onClick={handleNotiSubmit}>
                        Xác nhận
                    </Button>
                </div>
            </Modal> */}
        </div>
    );
};

export default FinalStep;
