import React, { FC } from 'react';

import SickPigs from '../../sickpigs/SickPigs';
import './style.sickpigsProfile.scss';

const SickpigsProfile: FC = () => {
    return (
        <>
            <SickPigs />
        </>
    );
};

export default SickpigsProfile;