/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, useEffect } from 'react';
import { FoodConsumeContext } from '@components/food-consume/food-consume-context';

import { message, notification, Table } from 'antd';
import { supplierFoodConsumeDetail, penListColumns } from './food-step-columns';

import farmServices from '@services/farm';

import { apiPen } from '@api/pen';

import * as inventoryApi from '@api/inventory';

import { EStepStatus, IPenConsumeStep, IFoodConsume, IFoodConsumeContext } from '@type/food-consume';
import { IPen } from '@type/pen';
import { useDispatchRoot } from '@src/src/app/redux/store';
import { setNotEnoughFood } from '@src/src/app/redux/controller/farm.slice';

const PenConsumeStep: FC<IPenConsumeStep> = ({ setStepStatus }: IPenConsumeStep) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const dispatch = useDispatchRoot();

    const { supplier, foodConsume } = React.useContext(FoodConsumeContext) as IFoodConsumeContext;


    const [pens, setPens] = React.useState<IPen[]>([]);
    const [lstQuantityFood, setLstQuantityFood] = React.useState<any[]>([]);

    console.log(supplier.foodSupplierData.foodItems);

    React.useEffect(() => {
        (async () => {
            const foodItemsData = (await inventoryApi.getInventory(
                currentFarm.id,
                supplier.foodSupplierData.id,
            )) as any;

            supplier.setSupData({
                ...supplier.foodSupplierData,
                foodItems: foodItemsData.items,
                totalAvailableFood: foodItemsData.total,
            });

            if (foodItemsData.total === 0) {
                notification.error({
                    message: 'Không có thức ăn nào',
                });
                return;
            }

            const data = (await apiPen.getAllPen({ farmId: currentFarm.id, offset: 0, size: 200 })) as any;

            setPens(data.items);
        })();
    }, []);

    useEffect(() => {
        let flat = false;
        if (lstQuantityFood.length > 0) {
            supplier.foodSupplierData.foodItems.map((v: any) => {
                let quantity = 0;
                lstQuantityFood.map((v1: any) => {
                    if (v.food.id === v1.food.food.id) {
                        quantity += parseInt(v1.quantity);
                        if (quantity > v.quantity) {
                            notification.error({
                                message: `Số lượng thức ăn ${v.food.name} không đủ`,
                            });
                            setStepStatus(EStepStatus.NOT_COMPLETE, 1);
                            flat = true;
                            return;
                        }
                    }
                });
            });
            if (!flat) {
                setStepStatus(EStepStatus.COMPLETE, 1);
            }
        }
        // if (lstQuantityFood.length > 0) {
        //     setStepStatus(EStepStatus.COMPLETE, 1);
        // }
    }, [lstQuantityFood]);

    const getDataExportFood = (food: IFoodConsume, pen: IPen) => (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);

        // if (e.target.value === '' || e.target.value === '0' || parseInt(e.target.value, 10) === 0) {
        //     handleSetValueFood(food, pen, e.target.value);
        //     return;
        // }
        handleSetValueFood(food, pen, e.target.value);
        const { penFoodConsume } = foodConsume;

        supplier.exportFood(food, pen, +e.target.value);

    };

    const handleSetValueFood = (food: IFoodConsume, pen: IPen, quantity: any) => {
        const existingItem = lstQuantityFood.find((item) => (item.food.id === food.id && item.pen.id === pen.id));

        if (existingItem) {
            // If the item exists, increase its quantity by 1
            const updatedItems = lstQuantityFood.map((item) =>
                (item.food.id === food.id && item.pen.id === pen.id) ? { ...item, quantity: quantity } : item
            );
            setLstQuantityFood(updatedItems);
        } else {
            // If the item does not exist, add it to the array with quantity 1
            const newItem = {
                food: food,
                pen: pen,
                quantity: quantity,
            };
            setLstQuantityFood([...lstQuantityFood, newItem]);
        }
    }
    return (
        <div>
            <div id="detail-supplier">
                <div id="supplier-information">
                    <h2 className="consume-title"> Thông tin nhà cung cấp </h2>

                    <div className="information">
                        <label htmlFor="supplier-name">Tên: </label>
                        <span id="supplier-name"> {supplier.foodSupplierData.name} </span>
                    </div>

                    <div className="information">
                        <label htmlFor="supplier-address">Địa chỉ: </label>
                        <span id="supplier-address"> {supplier.foodSupplierData.address} </span>
                    </div>

                    <div className="information">
                        <label htmlFor="total-food">Tổng số lương thực: </label>
                        <span id="total-food"> {supplier.foodSupplierData.totalAvailableFood} </span>
                    </div>
                </div>

                <div id="food-detail">
                    <h2 className="consume-title center-text"> Thông tin thức ăn </h2>

                    <div className="wrapper-food">
                        <Table
                            dataSource={supplier.foodSupplierData.foodItems}
                            columns={supplierFoodConsumeDetail}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>

            <Table
                dataSource={pens}
                columns={penListColumns(supplier.foodSupplierData.foodItems, getDataExportFood)}
                pagination={false}
                className="mt-24"
                locale={{ emptyText: 'Không có dữ liệu' }}
            />
            {/* <div className="wrapper-action">
                <button className="btn" >
                    Lùi lại
                </button>
                <button className="btn baka" type="submit">
                    Lưu
                </button>

            </div> */}
        </div>
    );
};

export default PenConsumeStep;
