/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetcher } from '@api/sender';
import { CFG_BASE_URL, WEBRTC_STREAMING } from '../constants/config';
import { IImportPig, IListPigByFarmId, IUpdateIdentityInput, IUpdatePig } from '@type/pig';

export const list = () => {
    return fetcher.get(`${CFG_BASE_URL}/pigs`);
}

export const detail = (id: string) => {
    return fetcher.get(`${CFG_BASE_URL}/pigs/${id}`)
}

export const updateIdentity = ({ pigAIIds, data, userId, farmId }: IUpdateIdentityInput) => {
    return fetcher.put(`${CFG_BASE_URL}/pigs`, { pigAIIds, data, userId, farmId } as any)
}

export const listPigByFarmId = ({ farmId, offset, size, isIdentityConfirm }: IListPigByFarmId) => {
    return fetcher.get(`${CFG_BASE_URL}/pigs/farmId/${farmId}`, { offset, size, isIdentityConfirm });
}

export const apiPig = {
    getAll(pigAIId: string, penId: string) {
        return fetcher.get(`${CFG_BASE_URL}/pigs/pen/${penId}/pigAIId/${pigAIId}`);
    }
}

export const updatePig = (id: string, data: IUpdatePig) => {
    return fetcher.put(`${CFG_BASE_URL}/pigs/${id}`, data as any);
}

export const createPigFromAI = (camId: string) => {
    return fetcher.get(`${WEBRTC_STREAMING}/behavioralAnalysis/camId/${camId}`);
}

export const importPigFromAI = (camId: string, data: IImportPig) => {
    return fetcher.post(`${WEBRTC_STREAMING}/camera/${camId}/importPig`, data as any);
}

export const getAllPigsByPenId = (penId: string, offset?: number, size?: number) => {
    return fetcher.get(`${CFG_BASE_URL}/pigs/penId/${penId}`, { offset, size })
}