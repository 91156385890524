import { Breadcrumb, Button, DatePicker, Form, Modal, Select, Space, Spin, Table, Tooltip, notification } from 'antd'
import './PharmaceuticalPlan.scss'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import farmService from '@services/farm';
import userServices from '@services/user';
import { EditOutlined, DeleteOutlined, FlagOutlined } from '@material-ui/icons'
import user from '@src/src/app/services/user'
import { AiOutlineEye } from 'react-icons/ai'
import { IconButton } from '@material-ui/core'
import row from 'antd/lib/row'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
const PharmaceuticalPlan = () => {

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'stt',
        },
        {
            title: 'Ngày tiêm',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Tên vắc xin',
            dataIndex: 'vaccine',
            key: 'vaccine',
        },
        {
            title: 'Tên bệnh',
            dataIndex: 'disease',
            key: 'disease',
        },
        {
            title: 'Tên chuồng',
            dataIndex: 'pen',
            key: 'pen',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
        }
    ]

    const [data, setData] = React.useState<any[]>([])
    const [dataPharmaceuticalPlan, setDataPharmaceuticalPlan] = React.useState<any[]>([])
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstPharmaceutical, setLstPharmaceutical] = useState<any[]>([]); // Danh sách thuốc
    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const [lstDisease, setLstDisease] = useState<any[]>([]); // Danh sách bệnh
    const currentFarm = farmService.getCurrentFarm();
    const user = userServices.get();
    const [form] = Form.useForm();
    const [isShowAddModal, setIsShowAddModal] = useState(false)
    const [isShowEditModal, setIsShowEditModal] = useState(false)
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)
    const [idEditPlan, setIdEditPlan] = useState<any>('')
    const [idDeletePlan, setIdDeletePlan] = useState<any>('')

    const [penSelect, setPenSelect] = useState<any>('')
    const [statusSelect, setStatusSelect] = useState<any>('')

    useEffect(() => {
        getAllPharmaceutical()
        getAllPenByFarmId()
        getAllDisease()
    }, [])
    useEffect(() => {
        getALlVacineSchedule()
    }, [currentFarm?.id, lstPharmaceutical, lstPen, penSelect, statusSelect])

    const getALlVacineSchedule = async () => {
        if (!currentFarm?.id) return
        setLoading(true)
        let tmp = '';
        if (penSelect)
            tmp += `&penId=${penSelect}`
        if (statusSelect)
            tmp += `&status=${statusSelect === '1' ? true : false}`

        await axios.get(`https://sit.api.pigman.com.vn/vaccinationSchedule?farmId=${currentFarm.id}` + tmp)
            .then(res => {
                console.log(res?.data?.data);

                const tmp = res?.data?.data.map((item: any, index: number) => {
                    console.log(item);

                    return {
                        stt: index + 1,
                        date: moment(item?.time).format('DD/MM/YYYY'),
                        vaccine: item.pharmaceutical?.name,
                        disease: item.disease?.name ? item.disease?.name : 'Không xác định',
                        pen: item.pen?.name,
                        status: item?.status ? 'Đã tiêm' : 'Chưa tiêm',
                        action:
                            <Space key={index} style={{ alignItems: 'end' }}>
                                <Tooltip title="Sửa">
                                    <IconButton aria-label="edit" onClick={() => handleOpenEditModal(item)}>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Xoá">
                                    <IconButton aria-label="delete" onClick={() => handleOpenDeleteModal(item.id)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                            </Space>
                    }
                })
                setData(tmp);
                setLoading(false)
            });
    }

    const getAllPharmaceutical = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/pharmaceutical?offset=0&size=1000&farmId=${currentFarm?.id}`)
            .then(res => {
                console.log(res?.data?.data?.items);
                setLstPharmaceutical(res?.data?.data?.items)
            })
    }

    const getAllPenByFarmId = async () => {
        const req = {
            offset: 0,
            size: 1000,
            farmId: currentFarm?.id,
        }
        await axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
            .then(res => {
                console.log(res.data.data.items);
                setLstPen(res.data.data.items)
            })
    }

    const getAllDisease = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/disease?offset=0&size=1000&farmId=${currentFarm?.id}`)
            .then(res => {
                console.log(res?.data?.data?.items);
                setLstDisease(res?.data?.data?.items)
            })
    }

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };
    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const handleOpenAddModal = () => {
        form.resetFields()
        setIsShowAddModal(true)
    }

    const handleOpenEditModal = (item: any) => {
        console.log(item);
        form.setFieldsValue({
            date: moment(item.time),
            vaccine: item?.pharmaceutical?.id,
            pen: item?.pen?.id,
            disease: item?.disease ? item.disease.id : null,
            status: item.status === true ? '1' : '0',
        })
        setIsShowEditModal(true)
        setIdEditPlan(item.id)
    }

    const handleOpenDeleteModal = async (id: any) => {
        setIsShowDeleteModal(true)
        setIdDeletePlan(id)
    }

    const handleFinishAddForm = async (values: any) => {
        console.log(values);

        const req = {
            userId: user?.id,
            farmId: currentFarm?.id,
            penId: values.pen,
            pharmaceuticalId: values.vaccine,
            diseaseId: values.disease,
            time: moment(values.date),
            note: 'string',
            status: values.status === '1' ? true : false,
        }
        await axios.post(`https://sit.api.pigman.com.vn/vaccinationSchedule`, req)
            .then(res => {
                console.log(res);
                setIsShowAddModal(false)
                notification.success({
                    message: 'Thêm lịch tiêm phòng thành công',
                } as any)
                getALlVacineSchedule()
            })
            .catch(err => {
                console.log(err);
                notification.error({
                    message: 'Thêm lịch tiêm phòng thất bại',
                })
            })
    }

    const handleFinishEditForm = async (values: any) => {
        console.log(values);
        const req = {
            penId: values.pen,
            pharmaceuticalId: values.vaccine,
            diseaseId: values.disease,
            time: moment(values.date),
            note: 'string',
            status: values.status === '1' ? true : false,
        }
        console.log(req);
        if (!idEditPlan) return
        await axios.put(`https://sit.api.pigman.com.vn/vaccinationSchedule/update/${idEditPlan}`, req)
            .then(res => {
                console.log(res);
                setIsShowEditModal(false)
                notification.success({
                    message: 'Sửa lịch tiêm phòng thành công',
                } as any)
                getALlVacineSchedule()
            })
            .catch(err => {
                console.log(err);
                notification.error({
                    message: 'Sửa lịch tiêm phòng thất bại',
                })
            })
    }

    const handleFinishDeleteForm = async () => {
        if (!idDeletePlan) return
        await axios.delete(`https://sit.api.pigman.com.vn/vaccinationSchedule/delete/${idDeletePlan}`)
            .then(res => {
                console.log(res);
                setIsShowDeleteModal(false)
                notification.success({
                    message: 'Xóa lịch tiêm phòng thành công',
                } as any)
                getALlVacineSchedule()
            })
            .catch(err => {
                console.log(err);
                notification.error({
                    message: 'Xóa lịch tiêm phòng thất bại',
                })
            })
    }

    const handleChangePen = async (value: any) => {
        setPenSelect(value ? value : '')
    }


    return (
        <div className='main-content pharmaceutical-plan'>
            <div className='main-breadcrumb'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <span>Phòng dịch bệnh</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lịch tiêm phòng</Breadcrumb.Item>
                </Breadcrumb>
                <div className='button-sick-pig' >
                    <Select

                        showSearch
                        placeholder='Chọn chuồng'
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleChangePen}
                        allowClear
                    >
                        {lstPen && lstPen.map((item, index) => {
                            return (
                                <option key={index} value={item.id}>{item.name}</option>
                            )
                        }
                        )}
                    </Select>

                    <Select
                        showSearch
                        placeholder='Chọn trạng thái'
                        filterOption={(input, option) =>
                            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => setStatusSelect(value ? value : '')}
                        allowClear
                    >
                        <option value='1'>Đã tiêm</option>
                        <option value='0'>Chưa tiêm</option>
                    </Select>

                    <Button onClick={handleOpenAddModal}>
                        Thêm lịch tiêm phòng
                    </Button>
                </div>
            </div>
            <Spin spinning={loading} tip="Đang tải...">
                <div className='table-sick-pig'>
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowClassName={getRowClassName}
                        pagination={paginationConfig}
                    />
                </div>
            </Spin>
            <Modal
                title="Thêm lịch tiêm phòng"
                visible={isShowAddModal}
                onOk={() => setIsShowAddModal(false)}
                onCancel={() => setIsShowAddModal(false)}
                width={1000}
                footer={null}
            >
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleFinishAddForm}
                >
                    <Form.Item
                        label='Ngày tiêm'
                        name='date'
                        rules={[{ required: true, message: 'Vui lòng nhập ngày tiêm' }]}
                    >
                        <DatePicker placeholder='Chọn ngày tiêm' style={{ width: '100%' }} format={'DD/MM/YYYY'} />
                    </Form.Item>
                    <Form.Item
                        label='Tên vắc xin'
                        name='vaccine'
                        rules={[{ required: true, message: 'Vui lòng nhập tên vắc xin' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên vắc xin'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {lstPharmaceutical && lstPharmaceutical.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Tên bệnh'
                        name='disease'
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên bệnh'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {lstDisease && lstDisease.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Tên chuồng'
                        name='pen'
                        rules={[{ required: true, message: 'Vui lòng nhập tên chuồng' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên chuồng'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {lstPen && lstPen.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label='Trạng thái'
                        name='status'
                        rules={[{ required: true, message: 'Vui lòng nhập trạng thái' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn trạng thái'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <option value='1'>Đã tiêm</option>
                            <option value='0'>Chưa tiêm</option>
                        </Select>

                    </Form.Item>
                    <Form.Item style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowAddModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit'>
                            Lưu
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Cập nhật lịch tiêm phòng"
                visible={isShowEditModal}
                onOk={() => setIsShowEditModal(false)}
                onCancel={() => setIsShowEditModal(false)}
                width={1000}
                footer={null}
            >
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleFinishEditForm}
                >
                    <Form.Item
                        label='Ngày tiêm'
                        name='date'
                        rules={[{ required: true, message: 'Vui lòng nhập ngày tiêm' }]}
                    >
                        <DatePicker placeholder='Chọn ngày tiêm' style={{ width: '100%' }} format={'DD/MM/YYYY'} />
                    </Form.Item>
                    <Form.Item
                        label='Tên vắc xin'
                        name='vaccine'
                        rules={[{ required: true, message: 'Vui lòng nhập tên vắc xin' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên vắc xin'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {lstPharmaceutical && lstPharmaceutical.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Tên bệnh'
                        name='disease'
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên bệnh'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {lstDisease && lstDisease.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Tên chuồng'
                        name='pen'
                        rules={[{ required: true, message: 'Vui lòng nhập tên chuồng' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn tên chuồng'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {lstPen && lstPen.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            }
                            )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Trạng thái'
                        name='status'
                        rules={[{ required: true, message: 'Vui lòng nhập trạng thái' }]}
                    >
                        <Select
                            showSearch
                            placeholder='Chọn trạng thái'
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <option value='1'>Đã tiêm</option>
                            <option value='0'>Chưa tiêm</option>
                        </Select>

                    </Form.Item>
                    <Form.Item style={{ textAlign: 'end' }}>
                        <Button onClick={() => setIsShowEditModal(false)} style={{ marginRight: 10 }}>
                            Hủy bỏ
                        </Button>
                        <Button type='primary' htmlType='submit'>
                            Xác nhận
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title='Xóa lịch tiêm phòng'
                visible={isShowDeleteModal}
                onOk={() => setIsShowDeleteModal(false)}
                onCancel={() => setIsShowDeleteModal(false)}
                width={400}
                footer={null}
            >
                <div className='modal-delete'>
                    <div className='modal-delete-content'>
                        <div className='modal-delete-content-text'>
                            <span>Bạn có chắc chắn muốn xóa lịch tiêm phòng này không?</span>
                        </div>
                    </div>
                    <div className='modal-delete-footer'>
                        <Button onClick={() => setIsShowDeleteModal(false)} style={{ marginRight: 10 }}>
                            Hủy
                        </Button>
                        <Button type='primary' htmlType='submit' onClick={handleFinishDeleteForm}>
                            Xóa
                        </Button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default PharmaceuticalPlan
