/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    differenceInDays,
    format,
    getMonth,
    getHours,
    getSeconds,
    getMinutes
} from 'date-fns';
import { UtilCollections } from './collections';

const dateDiff = (startDate: string, endDate: string) => {
    return differenceInDays(new Date(endDate), new Date(startDate));
}

const currentDateFull = () => {
    return format(new Date(), 'yyyy-MM-dd');
}

const formatDate = (date: string) => {
    return format(new Date(date), 'dd-MM-yyyy');
}

const getEndOfDate = (date: string): Date => {
    const end_ = new Date();
    const dateTime = UtilCollections.formatDateSearchBeHaviour(date).split('-');
    const year = parseInt(dateTime[0]);
    const month = parseInt(dateTime[1]);
    const day = parseInt(dateTime[2]);
    end_.setFullYear(year, month - 1, day);
    end_.setHours(23)
    end_.setMinutes(59);
    end_.setSeconds(59);
    end_.setMilliseconds(50);
    return end_;
}

const getStartedDate = (date: Date): Date => {
    const tmp = date;
    tmp.setHours(0, 0, 0);
    return tmp;
}

const getEndedDate = (date: Date): Date => {
    const tmp = date;
    tmp.setHours(23, 59, 59);
    return tmp;
}

const currentMonth = () => getMonth(new Date());
const currentHour = () => getHours(new Date()) < 10 ? `0${getHours(new Date())}` : getHours(new Date());
const currentMinute = () => getMinutes(new Date()) < 10 ? `0${getMinutes(new Date())}` : getMinutes(new Date());
const currentSeconds = () => getSeconds(new Date()) < 10 ? `0${getSeconds(new Date())}` : getSeconds(new Date());

export const UtilDate = {
    dateDiff,
    currentDateFull,
    formatDate,
    currentMonth,
    currentHour,
    currentMinute,
    currentSeconds,
    getEndOfDate,
    getStartedDate,
    getEndedDate
};