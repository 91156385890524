/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from 'react';
import { apiFoodSupplier } from '@api/food-supplier';
import farmServices from '@services/farm';
import { FoodConsumeContext } from '@components/food-consume/food-consume-context';

import { Table } from 'antd';
import { supplierStepColumns } from './food-step-columns';

import { EStepStatus, ISupplierStep } from '@type/food-consume';
import { IFoodSupplierData } from '@type/food-supplier';
import { ICommonListResponse } from '@type/common';

const FoodSupplierStep: FC<ISupplierStep> = ({ next, setStepStatus }: ISupplierStep) => {
    const currentFarm: any = farmServices.getCurrentFarm() as any;

    const [suppliers, setSuppliers] = React.useState<IFoodSupplierData[]>();

    const consumeContext = React.useContext(FoodConsumeContext) as any;

    React.useEffect(() => {
        (async () => {
            const data = (await apiFoodSupplier.getAll({
                size: 2000,
                farmId: currentFarm.id,
            })) as ICommonListResponse<IFoodSupplierData>;
            setSuppliers(data.items);
        })();
    }, []);

    return (
        <Table
            rowClassName={'pointer'}
            onRow={(record) => {
                return {
                    onClick: () => {
                        consumeContext.supplier.setSupData(record);
                        setStepStatus(EStepStatus.COMPLETE, 0);
                        next();
                    },
                };
            }}
            dataSource={suppliers}
            columns={supplierStepColumns}
            pagination={false}
            locale={{ emptyText: 'Không có nhà cung cấp' }}
        />
    );
};

export default FoodSupplierStep;
