/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC } from 'react';
import Modal, { IToggleModal } from '@elements/modal/modal';
import Login from '@components/auth/login';
import Register from '@components/auth/register';
import './auth.scss';
import logo from '@assets/logo.png';
import cancel from '@assets/cancel.png';
import Pig from '@elements/pig/Pig'
import { EAuthFormType } from '@components/common/header/header.types';

interface IAuthModal extends IToggleModal {
    isShowLoginForm: boolean
    toggleChangeAuthForm: any
    formType: EAuthFormType
}

const AuthModal: FC<IAuthModal> = ({ isShowModal, toggleModal, isShowLoginForm, toggleChangeAuthForm, formType }: IAuthModal) => {

    // const [isShow, setIsShow] = React.useState(isShowModal);

    // const toggleModal1 = (isShow: boolean) => (_: React.MouseEvent<HTMLElement>): void => {
    //     setIsShow(isShow);
    // };

    return (
        <Modal isShowModal={isShowModal} toggleModal={toggleModal} isHiddenHeader={true}>
            <>
                <div id='auth-modal-header'>
                    <img id='auth-modal__logo' src={logo} alt='logo' />
                    <img id='auth-modal__cancel' src={cancel} alt='cancel' onClick={toggleModal(false)} />
                </div>
                <div id='auth-content'>
                    <div id='auth-left'>
                        <h1> {formType === EAuthFormType.Login ? 'Chào mừng bạn đã quay trở lại!' : 'Giải pháp quản lý lợn trong trang trại bằng công nghệ AI'} </h1>
                        <Pig isOpacityStyle={false} className={''} />
                    </div>

                    <div id='wrapper-form'>
                        <div className="container">
                            <div className="tabs">
                                <input type="radio" id="login-form" name="tabs" checked={isShowLoginForm} onClick={toggleChangeAuthForm(true, EAuthFormType.Login)} />
                                <label className="tab" htmlFor="login-form">Đăng Nhập</label>
                                <input type="radio" id="register-form" name="tabs" checked={!isShowLoginForm} onClick={toggleChangeAuthForm(false, EAuthFormType.Register)} />
                                <label className="tab" htmlFor="register-form">Đăng ký</label>
                                <span className="glider"></span>
                            </div>
                        </div>


                        {
                            formType === EAuthFormType.Login ? <Login /> : <Register />
                        }
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default AuthModal;