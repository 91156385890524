import React, { useEffect, useState } from 'react'
import './style.modalprofile.scss'
import axios from 'axios'
import moment from 'moment'
import { Button, Form, Image, Input, InputNumber, Modal, Select, Spin, Table, notification } from 'antd'
import * as ModalApi from '@api/modal';
import { buffer } from 'rxjs/operators'
import temp1 from '@assets/model_F1_curve.png'
import temp2 from '@assets/model_PR_curve.png'
import temp3 from '@assets/model_P_curve.png'
import temp4 from '@assets/model_R_curve.png'
import temp5 from '@assets/model_confusion_matrix.png'
import temp6 from '@assets/model_results.png'
import track1 from '@assets/tracking_1.png'
import track2 from '@assets/tracking_2.jpg'
import track3 from '@assets/tracking_3.jpg'
import track4 from '@assets/tracking_4.jpg'
import track5 from '@assets/tracking_5.jpg'
import ImageBehavior1 from '@assets/image-behaviour-1.png'
import ImageBehavior2 from '@assets/image-behaviour-2.png'
import ImageTemperature1 from '@assets/image-temperature-1.png'
import ImageTemperature2 from '@assets/image-temperature-2.png'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const columns = [
    {
        title: 'Tên trang trại',
        dataIndex: 'farmName',
        key: 'farmName',
    },
    // {
    //     title: 'Tên chuồng',
    //     dataIndex: 'pigHouseName',
    //     key: 'pigHouseName',
    // },
    {
        title: 'Số lượng mẫu',
        dataIndex: 'sampleCount',
        key: 'sampleCount',
    },
    {
        title: 'Thao tác',
        dataIndex: 'action',
        key: 'action',
    },
];

const columnsTrain = [
    {
        title: 'Ngày',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Số lượng mẫu',
        dataIndex: 'sampleCount',
        key: 'sampleCount',
    },
    {
        title: 'Tình trạng',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Kết quả',
        dataIndex: 'result',
        key: 'result',
    },
    // {
    //     title: 'Thao tác',
    //     dataIndex: 'action',
    //     key: 'action',
    // }
]

const columnsEdit = [
    {
        title: 'Tên file',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Tên file',
        dataIndex: 'name1',
        key: 'name1',
    },
    {
        title: 'Tên file',
        dataIndex: 'name2',
        key: 'name2',
    },
    {
        title: 'Tên file',
        dataIndex: 'name3',
        key: 'name3',
    },
    {
        title: 'Tên file',
        dataIndex: 'name4',
        key: 'name4',
    },
    // {
    //     title: 'Thao tác',
    //     dataIndex: 'action',
    //     key: 'action',
    //     width: 100
    // }
]

const dataBehaviour = [
    {
        image: ImageBehavior1,
        content: 'Phát hiện bất thường trong chuồng lợn khỏe'
    },
    {
        image: ImageBehavior2,
        content: 'Phát hiện bất thường trong chuồng lợn bệnh'
    }
]
const dataTemperature = [
    {
        image: ImageTemperature1,
        content: 'Không phát hiện xu hướng tăng nhiệt độ'
    },
    {
        image: ImageTemperature2,
        content: 'Phát hiện xu hướng tăng nhiệt độ'
    }
]
const ModalProfile = () => {

    const [modalDetect, setModalDetect] = React.useState<any>(null)
    const [modalTracking, setModalTracking] = React.useState<any>(null)
    const [modalBehaviourTracking, setModalBehaviourTracking] = React.useState<any>(null)
    const [modalTemperatureTracking, setModalTemperatureTracking] = React.useState<any>(null)
    const [lstDataSetTracking, setLstDataSetTracking] = React.useState<any>(null)
    const [lstDataSetDetect, setLstDataSetDetect] = React.useState<any>(null)
    const [dataTableTracking, setDataTableTracking] = React.useState<any>(null)
    const [dataTableDetect, setDataTableDetect] = React.useState<any>(null)
    const [isShowModalTracking, setIsShowModalTracking] = React.useState<boolean>(false)
    const [isShowModalDetect, setIsShowModalDetect] = React.useState<boolean>(false)
    const [isShowModalAddSampleDetect, setIsShowModalAddSampleDetect] = React.useState<boolean>(false)
    const [isShowModalAddSampleDetectById, setIsShowModalAddSampleDetectById] = React.useState<boolean>(false)
    const [isShowModalAddSampleTracking, setIsShowModalAddSampleTracking] = React.useState<boolean>(false)
    const [isShowModalAddSampleTrackingById, setIsShowModalAddSampleTrackingById] = React.useState<boolean>(false)
    const [isShowModalEditSampleTracking, setIsShowModalEditSampleTracking] = React.useState<boolean>(false)
    const [isShowModalEditSampleDetect, setIsShowModalEditSampleDetect] = React.useState<boolean>(false)
    const [lstImageTracking, setLstImageTracking] = React.useState<any>(null)
    const [lstImageDetect, setLstImageDetect] = React.useState<any>(null)

    const [lstFarm, setLstFarm] = React.useState<any>(null)
    const [lstPen, setLstPen] = React.useState<any>(null)

    const [isTraningDETECTION, setIsTraningDETECTION] = React.useState<boolean>(true)
    const [isTraningTRACKING, setIsTraningTRACKING] = React.useState<boolean>(true)

    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    const [isLoadingDataTableDetect, setIsLoadingDataTableDetect] = React.useState<boolean>(false)

    useEffect(() => {
        getAllFarm();
        getDetectionModel();
        getTrackingModel();
        getBehaviourTrackingModel();
        getTemperatureTrackingModel();
        getAllDataSet();
        fetchDataMain();
    }, [])



    const getAllFarm = async () => {

        await axios.get(`https://sit.api.pigman.com.vn/admin/getAllFarm/getAllFarmFromAdmin`)
            .then((res: any) => {
                setLstFarm(res?.data?.data)
            })
    }

    const onChangeFarm = async (e: any) => {
        console.log(e);
        if (!e) return;
        await axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${e}?size=1000`)
            .then((res: any) => {
                setLstPen(res?.data?.data?.items)
            })
    }

    const getDetectionModel = async () => {
        await axios.get('https://sit.api.pigman.com.vn/detectionModel')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalDetect(res.data.data);
            })
    }

    const getTrackingModel = async () => {
        await axios.get('https://sit.api.pigman.com.vn/trackingModel')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalTracking(res.data.data);
            })
    }

    const getBehaviourTrackingModel = async () => {
        await axios.get('https://sit.api.pigman.com.vn/behaviourTrackingModel')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalBehaviourTracking(res.data.data);

                // const tmp = res.data.data;
                // tmp.map((item: any) => {
                //     // const originalPercentage = "20%";
                //     const percentageValue = parseFloat(item.eatThreshold); // Convert the percentage to a decimal
                //     const minutesValue = percentageValue * 30; // Calculate the equivalent minutes

                //     const modifiedString = `${percentageValue}% is ${minutesValue} minute`;

                // })
            })
    }

    const getTemperatureTrackingModel = async () => {
        await axios.get('https://sit.api.pigman.com.vn/temperatureTrackingModel')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                setModalTemperatureTracking(res.data.data);
            })
    }

    const getAllDataSet = async () => {
        await axios.get('https://sit.api.pigman.com.vn/dataset/all?modelType=DETECTION')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                console.log(res.data.data);
                setLstDataSetDetect(res.data.data);
            })
            .catch((err: any) => {
                console.log(err);
            })
        await axios.get('https://sit.api.pigman.com.vn/dataset/all?modelType=TRACKING')
            .then((res: any) => {
                if (res.data.data.length === 0) return;
                console.log(res.data.data);
                setLstDataSetTracking(res.data.data);
            })
            .catch((err: any) => {
                console.log(err);
            })
        // await axios.get('https://sit.api.pigman.com.vn/dataset/all')
        //     .then((res: any) => {
        //         if (res.data.data.length === 0) return;
        //         console.log(res.data.data);

        //         const tmp = res.data.data.filter((item: any) => item.modelType === 'TRACKING')
        //         setLstDataSetTracking(tmp);
        //         const tmp2 = res.data.data.filter((item: any) => item.modelType === 'DETECTION')
        //         setLstDataSetDetect(tmp2);
        //     })
        //     .catch((err: any) => {
        //         console.log(err);
        //     })
    }

    useEffect(() => {
        if (!lstDataSetTracking) return;
        console.log(lstDataSetTracking);

        const tmp: any[] = []
        lstDataSetTracking.map((item: any, index: any) => {
            if (index === 3) {
                tmp.push({
                    farmName: 'Dữ liệu huấn luyện ban đầu',
                    // pigHouseName: '',
                    sampleCount: item.numpic,
                    action:
                        <div className='button-group'>
                            <Button
                                onClick={() => {
                                    setIsShowModalTracking(false)
                                    setIsShowModalAddSampleTrackingById(true)
                                    setSelectedSample(item)
                                    // setIsShowModalAddSampleTracking(true)
                                }}
                            >Thêm mẫu</Button>
                            <Button
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditTracking(item)
                                    }
                                }
                            >Chỉnh sửa</Button>
                        </div >
                })
            }
            else {
                tmp.push({
                    farmName: item.farm.farmName,
                    // pigHouseName: item.pen.penName,
                    sampleCount: item.numpic,
                    action:
                        <div className='button-group'>
                            <Button
                                onClick={() => {
                                    setIsShowModalTracking(false)
                                    setIsShowModalAddSampleTrackingById(true)
                                    setSelectedSample(item)
                                    // setIsShowModalAddSampleTracking(true)
                                }}
                            >Thêm mẫu</Button>
                            <Button
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditTracking(item)
                                    }
                                }>Chỉnh sửa</Button>
                        </div >


                })
            }
            setDataTableTracking(tmp.reverse())
        })
        dataTableTracking

    }, [lstDataSetTracking])

    useEffect(() => {
        if (!lstDataSetDetect) return;
        console.log(lstDataSetDetect);
        setIsLoadingDataTableDetect(true)
        const tmp: any[] = []
        lstDataSetDetect.map((item: any, index: any) => {
            if (index === 0) {
                tmp.push({
                    farmName: 'Dữ liệu huấn luyện ban đầu',
                    // pigHouseName: '',
                    sampleCount: item.numpic,
                    action:
                        <div className='button-group'>
                            <Button onClick={() => {
                                setIsShowModalDetect(false)
                                setIsShowModalAddSampleDetectById(true)
                                setSelectedSample(item)
                            }}>Thêm mẫu</Button>
                            <Button
                                disabled={isLoading}
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditDetection(item)
                                    }
                                }
                            >Chỉnh sửa</Button>
                        </div>

                })
            }
            else {
                tmp.push({
                    farmName: item.farm.farmName,
                    // pigHouseName: item.pen.penName,
                    sampleCount: item.numpic,
                    action:
                        <div className='button-group'>
                            <Button onClick={() => {
                                setIsShowModalDetect(false)
                                setIsShowModalAddSampleDetectById(true)
                                setSelectedSample(item)
                            }}>Thêm mẫu</Button>
                            <Button
                                disabled={isLoading}
                                onClick={
                                    () => {
                                        setIsLoading(true)
                                        handleClickEditDetection(item)
                                    }
                                }
                            >Chỉnh sửa</Button>
                        </div>

                })
            }
            console.log(tmp);
            setDataTableDetect(tmp)
        })
        setIsLoadingDataTableDetect(false)
        // setDataTableTracking(tmp);
    }, [lstDataSetDetect])

    const handleClickEditTracking = async (item: any) => {
        setIsLoading(true)
        console.log(item);
        await axios.get(`https://sit.api.pigman.com.vn/dataset/dataset/${item.id}`)
            .then((res: any) => {
                // console.log(res.data);
                const rows = Math.ceil(res?.data?.length / 5);
                setLstImageTrackingLength(res.data.length)
                //console.log(rows);
                const tmp: any[] = [];
                for (let i = 0; i < rows; i++) {
                    const item = res.data[i * 5];
                    const item1 = res.data[i * 5 + 1];
                    const item2 = res.data[i * 5 + 2];
                    const item3 = res.data[i * 5 + 3];
                    const item4 = res.data[i * 5 + 4];
                    tmp.push({
                        name:
                            <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking('https://sit.api.pigman.com.vn/dataset/images/' + item.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item)}>Xóa</Button>
                                </div>
                                <div>Con_Số_{item?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div>,
                        name1:
                            item1 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item1?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking('https://sit.api.pigman.com.vn/dataset/images/' + item1.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item1)}>Xóa</Button>
                                </div>
                                <div>Con_Số_{item1?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name2:
                            item2 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item2?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking('https://sit.api.pigman.com.vn/dataset/images/' + item2.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item2)}>Xóa</Button>
                                </div>
                                <div>Con_Số_{item2?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name3:
                            item3 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item3?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking('https://sit.api.pigman.com.vn/dataset/images/' + item3.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item3)}>Xóa</Button>
                                </div>
                                <div>Con_Số_{item3?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                        name4:
                            item4 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item4?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModalTracking('https://sit.api.pigman.com.vn/dataset/images/' + item4.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteTrackingImage(item4)}>Xóa</Button>
                                </div>
                                <div>Con_Số_{item4?.directoryImage?.replace(/^.*\//, '')}</div>
                            </div> : <div></div>,
                    })

                }
                // const tmp = res.data.map((item: any) => {
                //     const name = item?.directoryImage?.replace(/^.*\//, '');
                //     return {
                //         name:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={100} preview={true} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         name1:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={100} preview={true} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         name2:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={100} preview={true} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         name3:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={100} preview={true} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         name4:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={100} preview={true} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         //action: <Button onClick={() => handleDeleteTrackingImage(item)}>Xóa</Button>
                //     }
                // })

                console.log(tmp);
                console.log(tmp.length)
                setLstImageTracking(tmp)
                setIsShowModalTracking(false)
                setIsShowModalEditSampleTracking(true)
                setIsLoading(false)

            })
    }
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalOpenTracking, setModalOpenTracking] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedLabel, setSelectedLabel] = useState<string[]>([]);
    const [isModalLabelOpen, setModalLabelOpen] = useState(false);


    const openModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalOpen(true);
    };


    const openViewLabel = async (url: string) => {
        // setSelectedImage(url);
        await axios.get(url).then((res: any) => {
            const out: string[] = res.data.split(/\r\n/g);
            const updatedOut = out.map((item) => item.toString().replace(/ /g, '  '));
            setSelectedLabel(updatedOut);
        });
        setModalLabelOpen(true);
    };
    console.log(selectedLabel);
    const openModalTracking = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalOpenTracking(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const closeModalTracking = () => {
        setModalOpenTracking(false);
    };

    const closeModalLabel = () => {
        setModalLabelOpen(false);
    };

    const handleClickEditDetection = async (item: any) => {

        setIsLoading(true)
        await axios.get(`https://sit.api.pigman.com.vn/dataset/dataset/${item.id}`)
            .then((res: any) => {
                console.log(res.data.length);
                setLstImageDetectLength(res.data.length)
                const rows = Math.ceil(res?.data?.length / 5);
                //console.log(rows);
                const tmp: any[] = [];
                for (let i = 0; i < rows; i++) {
                    const item = res.data[i * 5];
                    const item1 = res.data[i * 5 + 1];
                    const item2 = res.data[i * 5 + 2];
                    const item3 = res.data[i * 5 + 3];
                    const item4 = res.data[i * 5 + 4];
                    tmp.push({
                        name:
                            <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal('https://sit.api.pigman.com.vn/dataset/images/' + item.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>

                                </div>
                                <div>{item?.directoryImage?.replace(/^.*\//, '')}</div>
                                <Button className='button-view-label' onClick={() => openViewLabel('https://sit.api.pigman.com.vn/dataset/readFileLabel/' + item.id)}>Xem nhãn</Button>

                            </div>,
                        name1:
                            item1 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item1?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal('https://sit.api.pigman.com.vn/dataset/images/' + item1.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item1)}>Xóa</Button>

                                </div>
                                <div>{item1?.directoryImage?.replace(/^.*\//, '')}</div>
                                <Button className='button-view-label' onClick={() => openViewLabel('https://sit.api.pigman.com.vn/dataset/readFileLabel/' + item1.id)}>Xem nhãn</Button>

                            </div> : <div></div>,
                        name2:
                            item2 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item2?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal('https://sit.api.pigman.com.vn/dataset/images/' + item2.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item2)}>Xóa</Button>

                                </div>
                                <div>{item2?.directoryImage?.replace(/^.*\//, '')}</div>
                                <Button className='button-view-label' onClick={() => openViewLabel('https://sit.api.pigman.com.vn/dataset/readFileLabel/' + item2.id)}>Xem nhãn</Button>

                            </div> : <div></div>,
                        name3:
                            item3 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item3?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal('https://sit.api.pigman.com.vn/dataset/images/' + item3.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item3)}>Xóa</Button>

                                </div>
                                <div>{item3?.directoryImage?.replace(/^.*\//, '')}</div>
                                <Button className='button-view-label' onClick={() => openViewLabel('https://sit.api.pigman.com.vn/dataset/readFileLabel/' + item3.id)}>Xem nhãn</Button>

                            </div> : <div></div>,
                        name4:
                            item4 ? <div>
                                <div className='image-item'>
                                    <Image
                                        className='image' src={'https://sit.api.pigman.com.vn/dataset/images/' + item4?.id}
                                        width={120}
                                        height={120}
                                        preview={false}
                                        onClick={() => openModal('https://sit.api.pigman.com.vn/dataset/images/' + item4.id)}
                                    />
                                    <Button className='button-delete-image' onClick={() => handleDeleteDetectImage(item4)}>Xóa</Button>

                                </div>
                                <div>{item4?.directoryImage?.replace(/^.*\//, '')}</div>
                                <Button className='button-view-label' onClick={() => openViewLabel('https://sit.api.pigman.com.vn/dataset/readFileLabel/' + item4.id)}>Xem nhãn</Button>

                            </div> : <div></div>,
                    })

                }
                // const tmp = res.data.map((item: any) => {
                //     const name = item?.directoryImage?.replace(/^.*\//, '');
                //     return {
                //         name:
                //             <div>
                //                 <div ><Image src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id} width={200} preview={false} /></div>
                //                 <div>{name}</div>
                //             </div>,
                //         action: <Button onClick={() => handleDeleteDetectImage(item)}>Xóa</Button>
                //     }
                // })
                setLstImageDetect(tmp)
                setIsShowModalDetect(false)
                setIsShowModalEditSampleDetect(true)
                setIsLoading(false)
            })

    }

    const [selectedImages, setSelectedImages] = useState<any[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [inputKey, setInputKey] = useState(Date.now()); // Key to reset the input field
    const [selectedImageNames, setSelectedImageNames] = useState<any>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<any>([]);
    const [selectedSample, setSelectedSample] = useState<any>(null);

    const [formTracking] = Form.useForm();
    const [formDetect] = Form.useForm();
    const [formTrackingById] = Form.useForm();
    const [formDetectById] = Form.useForm();

    const handleFileChange = (e: any) => {
        const files: any = Array.from(e.target.files);
        const newFiles = files.filter((file: any) => !selectedFileNames.includes(file.name));

        if (newFiles.length > 0) {
            setSelectedFiles([...selectedFiles, ...newFiles]);
            const newFileNames = newFiles.map((file: any) => file.name);
            setSelectedFileNames([...selectedFileNames, ...newFileNames]);
        }

        setInputKey(Date.now());
    };

    const handleRemoveFile = (index: any) => {
        const updatedFiles = [...selectedFiles];
        const updatedFileNames = [...selectedFileNames];
        const removedFile = updatedFiles.splice(index, 1)[0];
        updatedFileNames.splice(index, 1);

        setSelectedFiles(updatedFiles);
        setSelectedFileNames(updatedFileNames);
    };
    // Function to handle file input change
    const handleFileInputChange = (e: any) => {
        const files: any = Array.from(e.target.files);
        const newImages = files.filter((file: any) => !selectedImageNames.includes(file.name));

        if (newImages.length > 0) {
            setSelectedImages([...selectedImages, ...newImages]);
            const newImageNames = newImages.map((image: any) => image.name);
            setSelectedImageNames([...selectedImageNames, ...newImageNames]);
        }

        setInputKey(Date.now());
    };

    // Function to handle file removal
    const handleRemoveImage = (index: any) => {
        // const updatedImages = [...selectedImages];
        // updatedImages.splice(index, 1); // Remove the selected image at the specified index
        // setSelectedImages(updatedImages);
        const updatedImages = [...selectedImages];
        const updatedImageNames = [...selectedImageNames];
        const removedImage = updatedImages.splice(index, 1)[0];
        updatedImageNames.splice(index, 1);

        setSelectedImages(updatedImages);
        setSelectedImageNames(updatedImageNames);
    };

    const renderSelectedFile = () => {
        return selectedFiles.map((file, index) => (
            <div key={index} className="image-container">
                <div>
                    {/* <img src={URL.createObjectURL(file)} alt={`File ${index}`} width="100" /> */}
                    <p>{file.name}</p>
                </div>
                <Button onClick={() => handleRemoveFile(index)}>Xóa file</Button>
            </div>
        ));
    }

    // Function to render the selected images
    const renderSelectedImages = () => {
        return selectedImages.map((image, index) => (
            <div key={index} className="image-container">
                <div>
                    <img src={URL.createObjectURL(image)} alt={`Image ${index}`} width="100" />
                    <p>{image.name}</p>
                </div>
                <Button onClick={() => handleRemoveImage(index)}>Xóa ảnh</Button>
            </div>
        ));
    };

    const handleFinishTracking = async (values: any) => {

        // if (!selectedImages || selectedImages.length === 0) {
        //     notification.error({
        //         message: 'Lỗi',
        //         description: 'Vui lòng chọn mẫu mô hình',
        //     })
        //     return;
        // }
        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }
        const formData = new FormData();
        formData.append("farmId", values.farmName);
        formData.append("penId", '');
        formData.append("modelTypes", 'TRACKING');
        selectedFiles.forEach((image: any) => {
            formData.append("profilePhoto", image);
        });
        try {
            const res = await fetch('https://sit.api.pigman.com.vn/dataset/newDataset', {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleTracking(false)
                setIsShowModalTracking(true)
                formTracking.resetFields();
                setSelectedImages([]);
                getAllDataSet()
            }

        } catch (err) {
            console.log(err);
        }
    }
    const handleFinishDetect = async (values: any) => {

        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }

        const formData = new FormData();
        formData.append("farmId", values.farmName);
        formData.append("penId", '');
        formData.append("modelTypes", 'DETECTION');

        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch('https://sit.api.pigman.com.vn/dataset/newDataset', {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleDetect(false)
                setIsShowModalDetect(true)
                formDetect.resetFields();
                setSelectedImages([]);
                setSelectedFiles([]);
                getAllDataSet()

            }

        } catch (err) {
            console.log(err);
        }

    }
    const handleFinishTrackingById = async (values: any) => {
        console.log(values);

        console.log(selectedSample);


        // if (!selectedImages || selectedImages.length === 0) {
        //     notification.error({
        //         message: 'Lỗi',
        //         description: 'Vui lòng chọn mẫu mô hình',
        //     })
        //     return;
        // }

        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }
        console.log(selectedFiles);

        const formData = new FormData();
        formData.append("datasetId", selectedSample.id);
        // selectedImages.forEach((image: any) => {
        //     formData.append("profilePhoto", image);
        // });
        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch('https://sit.api.pigman.com.vn/image/newDataset', {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleTrackingById(false)
                setIsShowModalTracking(true)
                formTrackingById.resetFields();
                setSelectedImages([]);
                getAllDataSet()
            }

        } catch (err) {
            console.log(err);
        }
    }

    const handleFinishDetectById = async (values: any) => {
        if (!selectedFiles || selectedFiles.length === 0) {
            notification.error({
                message: 'Lỗi',
                description: 'Vui lòng chọn nhãn mô hình',
            })
            return;
        }

        const formData = new FormData();
        formData.append("datasetId", selectedSample.id);
        selectedFiles.forEach((file: any) => {
            formData.append("profilePhoto", file);
        });
        try {
            const res = await fetch('https://sit.api.pigman.com.vn/image/newDataset', {
                method: 'POST',
                body: formData
            })

            console.log(res);
            const data = await res.json();
            console.log(data);
            if (data.message === 'error') {
                if (data.statusCode.code === 'EEXIST') {
                    notification.error({
                        message: 'Lỗi',
                        description: 'Mẫu đã tồn tại',
                    } as any)
                }
            }
            else {
                notification.success({
                    message: 'Thành công',
                    description: 'Thêm mẫu thành công',
                } as any)

                setIsShowModalAddSampleDetectById(false)
                setIsShowModalDetect(true)
                formDetectById.resetFields();
                setSelectedImages([]);
                setSelectedFiles([]);
                getAllDataSet()

            }

        } catch (err) {
            console.log(err);
        }

    }

    const customValidation = (_: any, value: any) => {
        // Define your custom validation logic here
        // console.log(value);

        if (!selectedImages || selectedImages.length === 0) {
            return Promise.reject('Vui lòng chọn mẫu mô hình');
        }
        return Promise.resolve();
    };

    const customValidationFile = (_: any, value: any) => {
        // Define your custom validation logic here
        // console.log(value);

        if (!selectedFiles || selectedFiles.length === 0) {
            return Promise.reject('Vui lòng chọn nhãn mô hình');
        }
        return Promise.resolve();
    }

    const [isShowDeleteTracking, setIsShowDeleteTracking] = React.useState<boolean>(false)
    const [isShowDeleteDetect, setIsShowDeleteDetect] = React.useState<boolean>(false)
    const [idDelete, setIdDelete] = React.useState<any>(null)

    const handleDeleteTrackingImage = async (item: any) => {
        console.log(item);
        setIdDelete(item.id)
        setIsShowDeleteTracking(true)
        setIsShowModalEditSampleTracking(false)
    }

    const handleDeleteDetectImage = async (item: any) => {
        console.log(item);
        setIdDelete(item.id)
        setIsShowDeleteDetect(true)
        setIsShowModalEditSampleDetect(false)
    }

    const handleDeleteTrackingImageModal = async () => {
        console.log(idDelete);
        await axios.delete(`https://sit.api.pigman.com.vn/dataset/${idDelete}`)
            .then((res: any) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Xóa mẫu thành công',
                } as any)
                setIsShowDeleteTracking(false)
                setIsShowModalTracking(true)
                getAllDataSet()
            })
    }

    const handleDeleteDetectImageModal = async () => {
        console.log(idDelete);
        await axios.delete(`https://sit.api.pigman.com.vn/dataset/${idDelete}`)
            .then((res: any) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Xóa mẫu thành công',
                } as any)
                setIsShowDeleteDetect(false)
                setIsShowModalDetect(true)
                getAllDataSet()
            })
    }

    const [lstDateSetByType, setLstDateSetByType] = React.useState<any>(null)
    const [isTrainingTracking, setIsTrainingTracking] = React.useState<boolean>(false)
    const [isTrainingDetection, setIsTrainingDetection] = React.useState<boolean>(false)
    const [typeClick, setTypeClick] = React.useState<any>(null)

    useEffect(() => {
        fetchData();
        // Set up an interval to call the API every 1 minute
        const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute
        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [])

    const fetchData = async () => {
        checkTrainTracking();
        checkTrainDetection();
    }

    const checkTrainTracking = async () => {
        await axios.get('https://sit.api.pigman.com.vn/trainer/check/haveTraining?modelType=TRACKING')
            .then((res: any) => {
                setIsTrainingTracking(res?.data?.data)
            })
    }

    const checkTrainDetection = async () => {
        await axios.get('https://sit.api.pigman.com.vn/trainer/check/haveTraining?modelType=DETECTION')
            .then((res: any) => {
                setIsTrainingDetection(res?.data?.data)
            })
    }

    const handleTrainModal = async (type: any) => {
        fetchData();
        setTypeClick(type)
        handleCheckTrain(type)
        // getAllDateSetByType(type)
    }

    const handleCheckTrain = async (type: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/trainer/check/haveTraining?modelType=${type}`)
            .then((res: any) => {
                console.log(res.data.data);
                if (res.data.data === true) {
                    notification.warning({
                        message: 'Thông báo',
                        description: 'Mô hình đang được huấn luyện',
                    } as any)
                    return;
                }
                else if (res.data.data === false) {
                    getAllDateSetByType(type)
                }
            })
    }

    const getAllDateSetByType = async (type: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/dataset/all?modelType=${type}`)
            .then((res: any) => {
                console.log(res.data.data);
                if (type === 'TRACKING') {
                    setLstDateSetByType(res.data.data)
                }
                else {
                    setLstDateSetByType(res.data.data)
                }
            })
    }

    useEffect(() => {
        if (!lstDateSetByType) return;
        const lstDatesetId = lstDateSetByType.map((item: any) => item.id)
        const req = {
            date: new Date(),
            datasetId: lstDatesetId,
            batchSize: 0,
            epochs: 0,
            lr: 0,
            isTraining: true,
            result: 0,
        }
        if (typeClick === 'TRACKING') {
            const tmp = {
                ...req,
                modelType: 'TRACKING',
                modelId: modalTracking[0].id
            }

            axios.post(`https://sit.api.pigman.com.vn/trainer`, tmp)
                .then((res: any) => {
                    console.log(res);
                    notification.success({
                        message: 'Thành công',
                        description: 'Bắt đầu huấn luyện',
                    } as any)
                })
                .catch((err: any) => {
                    console.log(err);
                })
        }
        else if (typeClick === 'DETECTION') {
            const tmp = {
                ...req,
                modelType: 'DETECTION',
                modelId: modalDetect[0].id
            }

            axios.post(`https://sit.api.pigman.com.vn/trainer`, tmp)
                .then((res: any) => {
                    console.log(res);
                    notification.success({
                        message: 'Thành công',
                        description: 'Bắt đầu huấn luyện',
                    } as any)
                })
                .catch((err: any) => {
                    console.log(err);
                })
        }
    }, [lstDateSetByType])

    const [lstTrainHistory, setLstTrainHistory] = React.useState<any>(null)
    const [isShowTrainHistory, setIsShowTrainHistory] = React.useState<boolean>(false)
    const [dataTableTrain, setDataTableTrain] = React.useState<any>(null)
    const viewTrain = async (type: any) => {
        await axios.get(`https://sit.api.pigman.com.vn/trainer?modelType=${type}`)
            .then((res: any) => {
                console.log(res.data.data);
                setLstTrainHistory(res.data.data)
                setIsShowTrainHistory(true)
            })
    }

    useEffect(() => {
        console.log(lstTrainHistory);
        if (!lstTrainHistory) return;
        const tmp: any[] = []
        lstTrainHistory.map((item: any) => {
            tmp.push({
                date: moment(item.date).format('DD/MM/YYYY'),
                sampleCount: item.numPics,
                status: item.isTraining ? 'Đang huấn luyện' : 'Hoàn thành',
                result: item.result === 0 ? 'Đang xử lý' : `${item.result}%`
            })
        })
        setDataTableTrain(tmp)
    }, [lstTrainHistory])

    const formatValue = (value: any) => {
        if (!Number.isInteger(value)) {
            return value.toFixed(1);
        }
        return value;
    }
    const handleKeyPress = (e: any) => {
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode === 44 || charCode === 45 || charCode === 101) {
            e.preventDefault();
        }
    };
    const [isShowEditBehaviourTracking, setIsShowEditBehaviourTracking] = React.useState<boolean>(false)
    const [itemEditBehaviourTracking, setItemEditBehaviourTracking] = React.useState<any>(null)
    const [formEditBehaviourTracking] = Form.useForm();

    const handleClickEditBehaviourTracking = async (item: any) => {
        console.log(item);
        setItemEditBehaviourTracking(item)
        setIsShowEditBehaviourTracking(true)
        formEditBehaviourTracking.setFieldsValue({
            name: item.name,
            date: moment(item.updatedAt).format('DD/MM/YYYY'),
            accuracy: item.accuracy + '%',
            lieThreshold: formatValue(item.lieThreshold / 100 * 30),
            eatThreshold: formatValue(item.eatThreshold / 100 * 30),
            playThreshold: formatValue(item.playThreshold / 100 * 30),
        })
    }

    const handleCancelEditBehaviourTracking = () => {
        setIsShowEditBehaviourTracking(false)
        formEditBehaviourTracking.resetFields();
    }

    const handleFinishEditBehaviourTracking = async (values: any) => {
        console.log(values);

        const req = {
            name: values.name,
            updatedAt: itemEditBehaviourTracking.updatedAt,
            accuracy: itemEditBehaviourTracking.accuracy,
            doTrain: true,
            eatThreshold: values.eatThreshold / 30 * 100,
            lieThreshold: values.lieThreshold / 30 * 100,
            playThreshold: values.playThreshold / 30 * 100,
        }
        await axios.put(`https://sit.api.pigman.com.vn/behaviourTrackingModel/${itemEditBehaviourTracking.id}`, req)
            .then((res: any) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Cập nhật mô hình thành công',
                } as any)
                setIsShowEditBehaviourTracking(false)
                formEditBehaviourTracking.resetFields();
                getBehaviourTrackingModel()
            })
            .catch((err: any) => {
                console.log(err);
                notification.error({
                    message: 'Lỗi',
                    description: 'Cập nhật mô hình thất bại',
                } as any)
            })
    }

    const handleChangeInputThreshold = (e: any, type: any) => {
        console.log(e.target.value);
        console.log(type);
        if (e.target.value > 30) {
            switch (type) {
                case 'lieThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        lieThreshold: 30
                    })
                    break;
                case 'eatThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        eatThreshold: 30
                    })
                    break;
                case 'playThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        playThreshold: 30
                    })
                    break;
            }
        }
        else if (e.target.value < 0) {
            switch (type) {
                case 'lieThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        lieThreshold: 0
                    })
                    break;
                case 'eatThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        eatThreshold: 0
                    })
                    break;
                case 'playThreshold':
                    formEditBehaviourTracking.setFieldsValue({
                        playThreshold: 0
                    })
                    break;
            }
        }
    }

    useEffect(() => {
        setSelectedFiles([]);
        setSelectedFileNames([]);
        setSelectedImages([]);
        setSelectedImageNames([]);
    }, [
        isShowModalTracking,
        isShowModalDetect,
        isShowModalAddSampleDetect,
        isShowModalAddSampleTracking,
        isShowModalAddSampleDetectById,
        isShowModalAddSampleTrackingById,
        isShowModalEditSampleTracking,
        isShowModalEditSampleDetect,
        isShowDeleteTracking,
        isShowDeleteDetect,
        isShowTrainHistory,
        isShowEditBehaviourTracking
    ])

    const pagination = {
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // Customize the page size options
        // Customize the text for pagination
        locale: {
            items_per_page: '/ trang',
            jump_to: 'Đến',
            jump_to_confirm: 'Xác nhận',
            page: 'trang',
        },
    };
    const paramDetection: any[] = [
        {
            id: 1,
            name: 'Số vòng (Epochs)',
            details: 'Số lần huấn luyện lại cả bộ dữ liệu',
            rangeValue: '10 - 1000',
            value: 300
        },
        {
            id: 2,
            name: 'Kích thước lô (Batch size)',
            details: 'Số ảnh được đưa vào trong một lần tính toán',
            rangeValue: '2^n (3<=n<=1010)',
            value: 16
        },
        {
            id: 3,
            name: 'Số tham số (Parameters)',
            details: 'Số lượng tham số của mô hình',
            rangeValue: '37,620,125',
            value: '37,620,125'
        },
        {
            id: 4,
            name: 'Hàm tối ưu (Optimizer)',
            details: 'Thuật toán tối ưu',
            rangeValue: 'adam',
            value: 'adam'
        },
        {
            id: 5,
            name: 'Số lớp (Classes)',
            details: 'Số lượng hành vi cần được phân loại',
            rangeValue: '3',
            value: 3
        },
    ]

    const paramTracking: any[] = [
        {
            id: 1,
            name: 'Số vòng (Epochs)',
            details: 'Số lần huấn luyện lại cả bộ dữ liệu',
            rangeValue: '10 - 200',
            value: 50
        },
        {
            id: 2,
            name: 'Kích thước lô (Batch size)',
            details: 'Số dữ liệu được đưa vào trong một lần tính toán',
            rangeValue: '2^n (3<=n<=1010)',
            value: 32
        },
        {
            id: 3,
            name: 'Hàm mất mát (Loss)',
            details: 'Loss function',
            rangeValue: 'softmax',
            value: 'softmax'
        },
        {
            id: 4,
            name: '(Số tham số) Parameters',
            details: 'Số lượng tham số của mô hình',
            rangeValue: '2,193,616',
            value: '2,193,616'
        },
        {
            id: 5,
            name: 'Tốc độ học (Learning rate)',
            details: 'Learning rate',
            rangeValue: '0.1',
            value: '0.1'
        },
    ]

    const [showImage, setShowImage] = useState(false);

    const handleToggleImage = () => {
        setShowImage(!showImage); // Toggle the state (show/hide)
    };

    const [showTracking, setShowTracking] = useState(false);

    const handleToggleImageTracking = () => {
        setShowTracking(!showTracking); // Toggle the state (show/hide)
    };
    const [isOpenTmp1, setIsOpenTmp1] = useState(false);
    const openLightboxTmp1 = () => {
        setIsOpenTmp1(true);
    }
    const [isOpenTmp2, setIsOpenTmp2] = useState(false);
    const openLightboxTmp2 = () => {
        setIsOpenTmp2(true);
    }
    const [isOpenTmp3, setIsOpenTmp3] = useState(false);
    const openLightboxTmp3 = () => {
        setIsOpenTmp3(true);
    }
    const [isOpenTmp4, setIsOpenTmp4] = useState(false);
    const openLightboxTmp4 = () => {
        setIsOpenTmp4(true);
    }
    const [isOpenTmp5, setIsOpenTmp5] = useState(false);
    const openLightboxTmp5 = () => {
        setIsOpenTmp5(true);
    }
    const [isOpenTmp6, setIsOpenTmp6] = useState(false);
    const openLightboxTmp6 = () => {
        setIsOpenTmp6(true);
    }
    const [isOpenTrack1, setIsOpenTrack1] = useState(false);
    const openLightboxTrack1 = () => {
        setIsOpenTrack1(true);
    }
    const [isOpenTrack2, setIsOpenTrack2] = useState(false);
    const openLightboxTrack2 = () => {
        setIsOpenTrack2(true);
    }
    const [isOpenTrack3, setIsOpenTrack3] = useState(false);
    const openLightboxTrack3 = () => {
        setIsOpenTrack3(true);
    }
    const [isOpenTrack4, setIsOpenTrack4] = useState(false);
    const openLightboxTrack4 = () => {
        setIsOpenTrack4(true);
    }
    const [isOpenTrack5, setIsOpenTrack5] = useState(false);
    const openLightboxTrack5 = () => {
        setIsOpenTrack5(true);
    }

    const [isOpenBeha1, setIsOpenBeha1] = useState(false);
    const openLightboxBeha1 = () => {
        setIsOpenBeha1(true);
    }
    const [isOpenBeha2, setIsOpenBeha2] = useState(false);
    const openLightboxBeha2 = () => {
        setIsOpenBeha2(true);
    }

    const [isOpenTempe1, setIsOpenTempe1] = useState(false);
    const openLightboxTempe1 = () => {
        setIsOpenTempe1(true);
    }

    const [isOpenTempe2, setIsOpenTempe2] = useState(false);
    const openLightboxTempe2 = () => {
        setIsOpenTempe2(true);
    }

    const [mainModalData, setMainModalData] = useState<any>(null)

    const fetchDataMain = async () => {
        const tmp: any[] = []
        await axios.get(`https://sit.api.pigman.com.vn/trainer?modelType=DETECTION`)
            .then((res: any) => {
                console.log(res.data.data);
                // Lấy ra phần tử có ngày cập nhật mới nhất
                const maxDate = res.data.data.reduce((prev: any, current: any) => (prev.date > current.date) ? prev : current)
                tmp.push(maxDate)
            })
            .catch((err: any) => {
                console.log(err);
            })
        console.log(tmp);
        await axios.get(`https://sit.api.pigman.com.vn/trainer?modelType=TRACKING`)
            .then((res: any) => {
                console.log(res.data.data);
                const maxDate = res.data.data.reduce((prev: any, current: any) => (prev.date > current.date) ? prev : current)
                tmp.push(maxDate)
            })
            .catch((err: any) => {
                console.log(err);
            })
        console.log(tmp);
        setMainModalData(tmp)

    }

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Set the number of records per page
    const [numberPagination, setNumberPagination] = useState(``);
    const [lstImageDetectLength, setLstImageDetectLength] = useState<any>(null)
    const [lstImageTrackingLength, setLstImageTrackingLength] = useState<any>(null)
    useEffect(() => {
        setCurrentPage(1);
        if (lstImageTrackingLength > 0) {
            if (1 === Math.ceil(lstImageTrackingLength / (pageSize * 5))) {
                // Lấy ra số ảnh còn lại của trang cuối
                const tmp = lstImageTrackingLength - (1 - 1) * pageSize * 5
                setNumberPagination(`Trang ${1}: ${tmp} ảnh lợn (${((1 - 1) * pageSize * 5) + 1}-${lstImageTrackingLength})`)
            }
            else {
                setNumberPagination(`Trang ${1}: ${pageSize * 5} ảnh lợn (${((1 - 1) * pageSize * 5) + 1}-${1 * pageSize * 5})`)
            }
        }
    }, [lstImageTrackingLength])

    useEffect(() => {
        setCurrentPage(1);
        if (lstImageDetectLength > 0) {
            if (1 === Math.ceil(lstImageDetectLength / (pageSize * 5))) {
                // Lấy ra số ảnh còn lại của trang cuối
                const tmp = lstImageDetectLength - (1 - 1) * pageSize * 5
                setNumberPagination(`Trang ${1}: ${tmp} ảnh lợn (${((1 - 1) * pageSize * 5) + 1}-${lstImageDetectLength})`)
            }
            else {
                setNumberPagination(`Trang ${1}: ${pageSize * 5} ảnh lợn (${((1 - 1) * pageSize * 5) + 1}-${1 * pageSize * 5})`)
            }
        }
    }, [lstImageDetectLength])
    const handleTableChange = (page: any, pageSize: any) => {
        console.log(page);
        console.log(pageSize);
        console.log(currentPage);
        setCurrentPage(page);
        console.log(lstImageTrackingLength);
        console.log(Math.ceil(lstImageDetectLength / (pageSize * 5)));

        if (lstImageDetectLength > 0) {
            if (page === Math.ceil(lstImageDetectLength / (pageSize * 5))) {
                console.log(page);
                const tmp = lstImageDetectLength - (page - 1) * pageSize * 5
                setNumberPagination(`Trang ${formatNumber(page)}: ${tmp} ảnh lợn (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(lstImageDetectLength)})`)
                return
            }
            else {
                setNumberPagination(`Trang ${formatNumber(page)}: ${pageSize * 5} ảnh lợn (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(page * pageSize * 5)})`)
                return

            }
        }

        if (lstImageTrackingLength > 0) {
            if (page === Math.ceil(lstImageTrackingLength / (pageSize * 5))) {
                const tmp = lstImageTrackingLength - (page - 1) * pageSize * 5

                setNumberPagination(`Trang ${formatNumber(page)}: ${tmp} ảnh lợn (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(lstImageTrackingLength)})`)
                return

            }
            else {
                setNumberPagination(`Trang ${formatNumber(page)}: ${pageSize * 5} ảnh lợn (${formatNumber(((page - 1) * pageSize * 5) + 1)}-${formatNumber(page * pageSize * 5)})`)
                return

            }
        }
    };

    const paginationConfigImage = {
        current: currentPage,
        pageSize: pageSize,
        onChange: handleTableChange,
    };

    // Tạo hàm biến đổi các giá trị từ 1000000 thành 1.000.000
    const formatNumber = (num: any) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <div className='profile-modal-main'>
            {modalDetect &&
                <div className='container'>
                    <div className="container-title">MÔ HÌNH PHÁT HIỆN LỢN</div>
                    <div className="container-content">
                        <div className="container-content-name">Tên mô hình: <b>{modalDetect[0].name}</b> </div>
                        {(mainModalData && mainModalData.length > 0) &&
                            <div className="container-content-date">Ngày cập nhật: <b>{moment(mainModalData[0]?.date).format("DD/MM/YYYY")}</b> </div>
                        }
                        {(!mainModalData) &&
                            <div className="container-content-date">Ngày cập nhật: <b>Đang cập nhật...</b> </div>
                        }
                        <div className="container-content-match">Độ chính xác (mAP): <b>{modalDetect[0].accuracy}%</b> </div>
                        <div className='container-content-buttons'>
                            <Button className='container-content-button' onClick={() => setIsShowModalDetect(true)}>Dữ liệu huấn luyện</Button>
                            {isTrainingDetection ?
                                <Button disabled loading className='container-content-button' onClick={() => handleTrainModal('DETECTION')}>Đang huấn luyện</Button>
                                :
                                <Button className='container-content-button' onClick={() => handleTrainModal('DETECTION')}>Huấn luyện mô hình</Button>
                            }
                            <Button className='container-content-button' onClick={() => viewTrain('DETECTION')}>Lịch sử huấn luyện</Button>
                        </div>
                        <div>
                            <div className='image-container' style={{ marginTop: 50 }}>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp5(); }}>
                                        <img src={temp5} alt="Image 5" className='image' />
                                    </a>
                                    <div className='image-name'>Biếu đồ Confusion Matrix của mô hình sau khi huấn luyện</div>
                                    {isOpenTmp5 && (
                                        <Lightbox
                                            mainSrc={temp5}
                                            onCloseRequest={() => setIsOpenTmp5(false)}
                                        />
                                    )}
                                </div>
                                <div className='image-block' >
                                    <div className='table-detect' style={{}}>
                                        <table className="table-detect-param" >
                                            <thead className='table-detect-param-header'>
                                                <tr>
                                                    <th className='headerGrid'>STT</th>
                                                    <th className='headerGrid'>Tên tham số</th>
                                                    <th className='headerGrid'>Giá trị</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-detect-param-body'>
                                                {paramDetection.map(emp => (
                                                    <tr key={emp.id}>
                                                        <td className='bodyGrid'>{emp.id}</td>
                                                        <td className='bodyGrid'>{emp.name}</td>
                                                        <td className='bodyGrid'>{emp.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className='table-model-name'>Bảng tham số của mô hình cho quá trình huấn luyện</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='imageModel'>
                            <Button className='container-content-button' onClick={handleToggleImage} style={{ borderRadius: 10, marginLeft: 10, marginTop: 20, marginBottom: 20 }}>
                                {showImage ? 'Ẩn' : 'Xem chi tiết'}
                            </Button>
                            {showImage && (
                                <div>
                                    <div className='image-container'>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp1(); }}>
                                                <img src={temp1} alt="Image 1" className='image' />
                                            </a>
                                            <div className='image-name'>Biểu đồ kết quả F1 Curve của mô hình sau khi huấn luyện</div>
                                            {isOpenTmp1 && (
                                                <Lightbox
                                                    mainSrc={temp1}
                                                    onCloseRequest={() => setIsOpenTmp1(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp2(); }}>
                                                <img src={temp2} alt="Image 2" className='image' />
                                            </a>
                                            <div className='image-name'>Biểu đồ kết quả PR Curve của mô hình sau khi huấn luyện</div>
                                            {isOpenTmp2 && (
                                                <Lightbox
                                                    mainSrc={temp2}
                                                    onCloseRequest={() => setIsOpenTmp2(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp3(); }}>
                                                <img src={temp3} alt="Image 3" className='image' />
                                            </a>
                                            <div className='image-name'>Biểu đồ kết quả Precision Curve của mô hình sau khi huấn luyện</div>
                                            {isOpenTmp3 && (
                                                <Lightbox
                                                    mainSrc={temp3}
                                                    onCloseRequest={() => setIsOpenTmp3(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp4(); }}>
                                                <img src={temp4} alt="Image 4" className='image' />
                                            </a>
                                            <div className='image-name'>Biểu đồ kết quả Recall Curve của mô hình sau khi huấn luyện</div>
                                            {isOpenTmp4 && (
                                                <Lightbox
                                                    mainSrc={temp4}
                                                    onCloseRequest={() => setIsOpenTmp4(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTmp6(); }}>
                                                <img src={temp6} alt="Image 6" className='image' />
                                            </a>
                                            <div className='image-name'>Bảng kết quả huấn luyện của mô hình</div>
                                            {isOpenTmp6 && (
                                                <Lightbox
                                                    mainSrc={temp6}
                                                    onCloseRequest={() => setIsOpenTmp6(false)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                    </div>
                </div>
            }
            {modalTracking &&
                <div className='container'>
                    <div className="container-title">MÔ HÌNH THEO DÕI LỢN</div>
                    <div className="container-content">
                        <div className="container-content-name">Tên mô hình: <b>{modalTracking[0].name}</b> </div>
                        {(mainModalData && mainModalData.length > 0) &&
                            <div className="container-content-date">Ngày cập nhật: <b>{moment(mainModalData[1]?.date).format("DD/MM/YYYY")}</b> </div>
                        }
                        {(!mainModalData) &&
                            <div className="container-content-date">Ngày cập nhật: <b>Đang cập nhật...</b> </div>
                        }
                        <div className="container-content-match">Độ chính xác (IDF1): <b>{modalTracking[0].accuracy}%</b> </div>
                        <div className='container-content-buttons'>
                            <Button className='container-content-button' onClick={() => setIsShowModalTracking(true)}>Dữ liệu huấn luyện</Button>
                            {isTrainingTracking ?
                                <Button disabled loading className='container-content-button' onClick={() => handleTrainModal('TRACKING')}>Đang huấn luyện</Button>
                                :
                                <Button className='container-content-button' onClick={() => handleTrainModal('TRACKING')}>Huấn luyện mô hình</Button>
                            }
                            <Button className='container-content-button' onClick={() => viewTrain('TRACKING')}>Lịch sử huấn luyện</Button>
                        </div>
                        <div>
                            <div className='image-container' style={{ marginTop: 50 }}>
                                <div className='image-block'>
                                    <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTrack1(); }}>
                                        <img src={track1} alt="Tracking 1" className='image' />
                                    </a>
                                    <div className='image-name'>Biểu đồ Loss</div>
                                    {isOpenTrack1 && (
                                        <Lightbox
                                            mainSrc={track1}
                                            onCloseRequest={() => setIsOpenTrack1(false)}
                                        />
                                    )}
                                </div>
                                <div className='image-block'>
                                    <div className='table-detect' style={{}}>
                                        <table className="table-detect-param" >
                                            <thead className='table-detect-param-header'>
                                                <tr>
                                                    <th className='headerGrid'>STT</th>
                                                    <th className='headerGrid'>Tên tham số</th>
                                                    <th className='headerGrid'>Giá trị</th>
                                                </tr>
                                            </thead>
                                            <tbody className='table-detect-param-body'>
                                                {paramTracking.map(emp => (
                                                    <tr key={emp.id}>
                                                        <td className='bodyGrid'>{emp.id}</td>
                                                        <td className='bodyGrid'>{emp.name}</td>
                                                        <td className='bodyGrid'>{emp.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className='table-model-name'>Tham số mô hình theo dõi</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='imageModel'>
                            <Button className='container-content-button' onClick={handleToggleImageTracking} style={{ borderRadius: 10, marginLeft: 10, marginTop: 20, marginBottom: 20 }}>
                                {showTracking ? 'Ẩn' : 'Xem chi tiết'}
                            </Button>
                            {showTracking && (
                                <div>
                                    <div className='image-container'>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTrack2(); }}>
                                                <img src={track2} alt="Tracking 2" className='image' />
                                            </a>
                                            <div className='image-name'>Kết quả test tập dữ liệu 1 - DeepSort</div>
                                            {isOpenTrack2 && (
                                                <Lightbox
                                                    mainSrc={track2}
                                                    onCloseRequest={() => setIsOpenTrack2(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTrack3(); }}>
                                                <img src={track3} alt="Tracking 3" className='image' />
                                            </a>
                                            <div className='image-name'>Kết quả test tập dữ liệu 2 - DeepSort</div>
                                            {isOpenTrack3 && (
                                                <Lightbox
                                                    mainSrc={track3}
                                                    onCloseRequest={() => setIsOpenTrack3(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTrack4(); }}>
                                                <img src={track4} alt="Tracking 4" className='image' />
                                            </a>
                                            <div className='image-name'>Kết quả test tập dữ liệu 1 - DeepSort cải tiến</div>
                                            {isOpenTrack4 && (
                                                <Lightbox
                                                    mainSrc={track4}
                                                    onCloseRequest={() => setIsOpenTrack4(false)}
                                                />
                                            )}
                                        </div>
                                        <div className='image-block'>
                                            <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTrack5(); }}>
                                                <img src={track5} alt="Tracking 5" className='image' />
                                            </a>
                                            <div className='image-name'>Kết quả test tập dữ liệu 2 - DeepSort cải tiến</div>
                                            {isOpenTrack5 && (
                                                <Lightbox
                                                    mainSrc={track5}
                                                    onCloseRequest={() => setIsOpenTrack5(false)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            {modalBehaviourTracking &&
                <div className='container'>
                    <div className="container-title">MÔ HÌNH PHÁT HIỆN BẤT THƯỜNG HÀNH VI</div>
                    <div className="container-content">
                        <div className="container-content-name">Tên mô hình: <b>{modalBehaviourTracking[0].name}</b> </div>
                        <div className="container-content-date">Ngày cập nhật: <b>{moment(modalBehaviourTracking[0].updatedAt).format("DD/MM/YYYY")}</b> </div>
                        <div className="container-content-match">Độ chính xác: <b>{modalBehaviourTracking[0].accuracy}%</b> </div>
                        <div className="container-content-match">Ngưỡng phát hiện nằm lâu: <b>{formatValue(modalBehaviourTracking[0].lieThreshold / 100 * 30)}/30p</b> </div>
                        <div className="container-content-match">Ngưỡng phát hiện bỏ ăn: <b>{formatValue(modalBehaviourTracking[0].eatThreshold / 100 * 30)}/30p</b> </div>
                        <div className="container-content-match">Ngưỡng phát hiện kích động: <b>{formatValue(modalBehaviourTracking[0].playThreshold / 100 * 30)}/30p</b> </div>
                        <div className='container-content-buttons'>
                            <Button className='container-content-button' onClick={() => handleClickEditBehaviourTracking(modalBehaviourTracking[0])}>Chỉnh sửa ngưỡng mô hình</Button>
                        </div>
                        <div className='container-content-result'>
                            <div className='container-content-result-title'>
                                Kết quả thực nghiệm mô hình
                            </div>
                            <div className='container-content-result-content'>
                                {(dataBehaviour && dataBehaviour.length) && dataBehaviour.map((item: any, index: number) => (
                                    <div className='container-content-result-content-item' key={index}>
                                        <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxBeha1(); }}>
                                            <img src={item.image} alt="" />
                                        </a>
                                        <div className='title'>
                                            {item.content}
                                        </div>
                                        {isOpenBeha1 && (
                                            <Lightbox
                                                mainSrc={item.image}
                                                onCloseRequest={() => setIsOpenBeha1(false)}
                                            />
                                        )}
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal
                title="Chỉnh sửa ngưỡng mô hình"
                visible={isShowEditBehaviourTracking}
                onOk={handleCancelEditBehaviourTracking}
                onCancel={handleCancelEditBehaviourTracking}
                width={500}
                style={{ maxWidth: '500px' }}
                className='modal-tracking'
                footer={false}

            >
                <div className='modal-tracking-main'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={formEditBehaviourTracking}
                        onFinish={handleFinishEditBehaviourTracking}
                        className='form-edit-behaviour-tracking'
                    >
                        <Form.Item
                            label="Tên mô hình"
                            name="name"

                        // rules={[{ required: true, message: 'Vui lòng nhập tên mô hình!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Ngày cập nhật"
                            name="date"
                        // rules={[{ required: true, message: 'Vui lòng nhập ngày cập nhật!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Độ chính xác"
                            name="accuracy"
                        // rules={[{ required: true, message: 'Vui lòng nhập độ chính xác!' }]}
                        >
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Ngưỡng phát hiện nằm lâu"
                            name="lieThreshold"
                            rules={[{ required: true, message: 'Vui lòng nhập ngưỡng phát hiện nằm lâu!' }]}
                        >
                            <Input
                                step="any"
                                min={0}
                                max={30}
                                type='number'
                                onKeyPress={handleKeyPress}
                                onBlur={(e: any) => handleChangeInputThreshold(e, 'lieThreshold')}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Ngưỡng phát hiện bỏ ăn"
                            name="eatThreshold"
                            rules={[{ required: true, message: 'Vui lòng nhập ngưỡng phát hiện bỏ ăn!' }]}
                        >
                            <Input
                                step="any"
                                min={0}
                                max={30}
                                type='number'
                                onKeyPress={handleKeyPress}
                                onBlur={(e: any) => handleChangeInputThreshold(e, 'eatThreshold')}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Ngưỡng phát hiện kích động"
                            name="playThreshold"
                            rules={[{ required: true, message: 'Vui lòng nhập ngưỡng phát hiện kích động!' }]}
                        >
                            <Input
                                step="any"
                                min={0}
                                max={30}
                                type='number'
                                onKeyPress={handleKeyPress}
                                onBlur={(e: any) => handleChangeInputThreshold(e, 'playThreshold')}
                            />
                        </Form.Item>
                        <Form.Item >
                            <div className='lst-btn-submit'>
                                <Button
                                    key="back"
                                    onClick={handleCancelEditBehaviourTracking}
                                >
                                    Hủy
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className='btn-submit'
                                >
                                    Lưu
                                </Button>

                            </div>

                        </Form.Item>

                    </Form>
                </div>
            </Modal>
            {modalTemperatureTracking &&
                <div className='container'>
                    <div className="container-title">MÔ HÌNH PHÁT HIỆN BẤT THƯỜNG THÂN NHIỆT</div>
                    <div className="container-content">
                        <div className="container-content-name">Tên mô hình: <b>{modalTemperatureTracking[0].name}</b> </div>
                        <div className="container-content-date">Ngày cập nhật: <b>{moment(modalTemperatureTracking[0].updatedAt).format("DD/MM/YYYY")}</b> </div>
                        <div className="container-content-match">Độ chính xác: <b>{modalTemperatureTracking[0].accuracy}%</b> </div>
                        <div className='container-content-result'>
                            <div className='container-content-result-content'>
                                {(dataTemperature && dataTemperature.length) && dataTemperature.map((item: any, index: number) => (
                                    <div className='container-content-result-content-item' key={index}>
                                        <a href="#" target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); openLightboxTempe1(); }}>
                                            <img src={item.image} alt="" />
                                        </a>
                                        <div className='title'>
                                            {item.content}
                                        </div>
                                        {isOpenTempe1 && (
                                            <Lightbox
                                                mainSrc={item.image}
                                                onCloseRequest={() => setIsOpenTempe1(false)}
                                            />
                                        )}

                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal
                title="Dữ liệu huấn luyện"
                visible={isShowModalTracking}
                onOk={() => {
                    setIsShowModalTracking(false)
                    setLstImageTrackingLength(0)
                }}
                onCancel={() => {
                    setIsShowModalTracking(false)
                    setLstImageTrackingLength(0)
                }
                }
                width={1000}
                style={{ maxWidth: '1000px' }}
                className='modal-tracking'
                footer={false}
            >
                <div className='modal-tracking-main'>
                    <div className='modal-tracking-main-title'>MÔ HÌNH THEO DÕI LỢN</div>
                    <div style={{ textAlign: 'end', marginBottom: 10 }}>
                        <Button
                            onClick={() => {
                                setIsShowModalTracking(false)
                                setIsShowModalAddSampleTracking(true)
                            }}
                        >Thêm tập dữ liệu</Button>
                    </div>
                    <Table columns={columns} dataSource={dataTableTracking} scroll={{ y: 250 }} />
                </div>
            </Modal>
            <Modal
                title="Dữ liệu huấn luyện"
                visible={isShowModalDetect}
                onOk={() => {
                    setIsShowModalDetect(false)
                    setLstImageDetectLength(0)
                    setCurrentPage(1)
                }}
                onCancel={() => {
                    setIsShowModalDetect(false)
                    setLstImageDetectLength(0)
                    setCurrentPage(1)
                }}
                width={1000}
                style={{ maxWidth: '1000px' }}
                className='modal-tracking'
                footer={false}
            >
                <div className='modal-tracking-main'>
                    <div className='modal-tracking-main-title'>MÔ HÌNH PHÁT HIỆN LỢN</div>
                    <div style={{ textAlign: 'end', marginBottom: 10 }}>
                        <Button
                            onClick={() => {
                                setIsShowModalDetect(false)
                                setIsShowModalAddSampleDetect(true)
                            }}
                        >Thêm tập dữ liệu</Button>
                    </div>
                    <Spin
                        spinning={isLoadingDataTableDetect}
                        delay={500}
                        tip="Đang tải dữ liệu..."
                    >
                        <Table columns={columns} dataSource={dataTableDetect} scroll={{ y: 250 }} />
                    </Spin>
                </div>
            </Modal>

            <Modal
                title="Thêm mẫu phát hiện"
                visible={isShowModalAddSampleDetect}
                onOk={() => {
                    setIsShowModalAddSampleDetect(false)
                    setIsShowModalDetect(true)
                    setCurrentPage(1)

                }}
                onCancel={() => {
                    setIsShowModalAddSampleDetect(false)
                    setIsShowModalDetect(true)
                    setCurrentPage(1)

                }}
                footer={false}
                width={500}
                style={{ maxWidth: '500px' }}
                className='modal-tracking'>
                <div className='modal-tracking-main tracking'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={formDetect}
                        onFinish={handleFinishDetect}
                    >
                        <Form.Item
                            label="Tên trang trại"
                            name="farmName"
                            rules={[{ required: true, message: 'Vui lòng chọn trang trại!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Chọn trang trại"
                                optionFilterProp="children"
                                className='select-farm'
                                onChange={onChangeFarm}
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstFarm && lstFarm.map((item: any, index: any) => {
                                    return (
                                        <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        {/* {lstPen &&
                            <Form.Item
                                label="Tên chuồng"
                                name="penName"
                                rules={[{ required: true, message: 'Vui lòng chọn chuồng!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn chuồng"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstPen && lstPen.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        } */}

                        <Form.Item
                            label="Mẫu và nhãn mô hình"
                            name="modelFile"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidationFile },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileChange}
                                    />
                                    <span>Tải tài liệu lên</span>
                                </label>
                            </div>
                            <div className="selected-images file">
                                {selectedFiles.length > 0 && (
                                    renderSelectedFile()
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item >
                            <div className='lst-btn-submit'>
                                <Button key="back"
                                    onClick={() => {
                                        setIsShowModalAddSampleDetect(false)
                                        setIsShowModalDetect(true)
                                    }}
                                >
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit"
                                >
                                    Lưu
                                </Button>
                            </div>

                        </Form.Item>
                    </Form>
                </div>
            </Modal>



            <Modal
                title="Thêm mẫu theo dõi"
                visible={isShowModalAddSampleTracking}
                onOk={() => {
                    setIsShowModalAddSampleTracking(false)
                    setIsShowModalTracking(true)
                }}
                onCancel={() => {
                    setIsShowModalAddSampleTracking(false)
                    setIsShowModalTracking(true)
                }}
                width={500}
                style={{ maxWidth: '500px' }}
                className='modal-tracking'
                footer={false}

            >
                <div className='modal-tracking-main tracking'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={formTracking}
                        onFinish={handleFinishTracking}
                    >
                        <Form.Item
                            label="Tên trang trại"
                            name="farmName"
                            rules={[{ required: true, message: 'Vui lòng chọn trang trại!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Chọn trang trại"
                                optionFilterProp="children"
                                className='select-farm'
                                onChange={onChangeFarm}
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {lstFarm && lstFarm.map((item: any, index: any) => {
                                    return (
                                        <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>

                        {/* {lstPen &&
                            <Form.Item
                                label="Tên chuồng"
                                name="penName"
                                rules={[{ required: true, message: 'Vui lòng chọn chuồng!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn chuồng"
                                    optionFilterProp="children"
                                    className='select-farm'
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {lstPen && lstPen.map((item: any, index: any) => {
                                        return (
                                            <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        } */}
                        <Form.Item
                            label="Mẫu cho mô hình"
                            name="modelFile"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidationFile },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept='image/*'
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileChange}
                                    />
                                    <span>Tải tài liệu lên</span>
                                </label>
                            </div>
                            <div className="selected-images file">
                                {selectedFiles.length > 0 && (
                                    renderSelectedFile()
                                )}
                            </div>
                        </Form.Item>
                        {/* <Form.Item
                            label="Mẫu mô hình"
                            name="modelType"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidation },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileInputChange}
                                    />
                                    <span>Tải ảnh lên</span>
                                </label>
                            </div>
                            <div className="selected-images">
                                {selectedImages.length > 0 && (
                                    renderSelectedImages()
                                )}
                            </div>
                        </Form.Item> */}

                        <Form.Item >
                            <div className='lst-btn-submit'>
                                <Button key="back"
                                    onClick={() => {
                                        setIsShowModalAddSampleTracking(false)
                                        setIsShowModalTracking(true)
                                    }}
                                >
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit"
                                // onClick={() => {
                                //     setIsShowModalAddSampleTracking(false)
                                //     setIsShowModalTracking(true)
                                //     handleFileUpload()
                                // }}
                                >
                                    Lưu
                                </Button>
                            </div>

                        </Form.Item>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Thêm mẫu theo dõi"
                visible={isShowModalAddSampleTrackingById}
                onOk={() => {
                    setIsShowModalAddSampleTrackingById(false)
                    setIsShowModalTracking(true)
                }}
                onCancel={() => {
                    setIsShowModalAddSampleTrackingById(false)
                    setIsShowModalTracking(true)
                }}
                width={500}
                style={{ maxWidth: '500px' }}
                className='modal-tracking'
                footer={false}
            >
                <div className='modal-tracking-main tracking'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={formTrackingById}
                        onFinish={handleFinishTrackingById}
                    >
                        {/* <Form.Item
                            label="Mẫu mô hình"
                            name="modelType"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidation },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileInputChange}
                                    />
                                    <span>Tải ảnh lên</span>
                                </label>
                            </div>
                            <div className="selected-images">
                                {selectedImages.length > 0 && (
                                    renderSelectedImages()
                                )}
                            </div>
                        </Form.Item> */}
                        <Form.Item
                            label="Mẫu và nhãn mô hình"
                            name="modelFile"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidationFile },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        accept='image/*'
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileChange}
                                    />
                                    <span>Tải tài liệu lên</span>
                                </label>
                            </div>
                            <div className="selected-images file">
                                {selectedFiles.length > 0 && (
                                    renderSelectedFile()
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item >
                            <div className='lst-btn-submit'>
                                <Button key="back"
                                    onClick={() => {
                                        setIsShowModalAddSampleTrackingById(false)
                                        setIsShowModalTracking(true)
                                    }}
                                >
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit"
                                >
                                    Lưu
                                </Button>
                            </div>

                        </Form.Item>

                    </Form>
                </div>
            </Modal>

            <Modal
                title="Thêm mẫu phát hiện"
                visible={isShowModalAddSampleDetectById}
                onOk={() => {
                    setIsShowModalAddSampleDetectById(false)
                    setIsShowModalDetect(true)
                }}
                onCancel={() => {
                    setIsShowModalAddSampleDetectById(false)
                    setIsShowModalDetect(true)
                }}
                width={500}
                style={{ maxWidth: '500px' }}
                className='modal-tracking'
                footer={false}
            >
                <div className='modal-tracking-main tracking'>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        form={formDetectById}
                        onFinish={handleFinishDetectById}
                    >
                        <Form.Item
                            label="Mẫu và nhãn mô hình"
                            name="modelFile"
                            rules={[
                                // { required: true, message: 'Vui lòng chọn mẫu mô hình!' },
                                { validator: customValidationFile },
                            ]}
                        >
                            <div className='action-tracking'>
                                <label className="custom-file-upload">
                                    <input
                                        type="file"
                                        multiple
                                        key={inputKey}
                                        onChange={handleFileChange}
                                    />
                                    <span>Tải tài liệu lên</span>
                                </label>
                            </div>
                            <div className="selected-images file">
                                {selectedFiles.length > 0 && (
                                    renderSelectedFile()
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item >
                            <div className='lst-btn-submit'>
                                <Button key="back"
                                    onClick={() => {
                                        setIsShowModalAddSampleDetectById(false)
                                        setIsShowModalDetect(true)
                                    }}
                                >
                                    Hủy bỏ
                                </Button>
                                <Button type="primary" htmlType="submit"
                                >
                                    Lưu
                                </Button>
                            </div>

                        </Form.Item>

                    </Form>
                </div>
            </Modal>



            <Modal
                title="Chỉnh sửa mẫu phát hiện"
                visible={isShowModalEditSampleDetect}
                onOk={() => {
                    setIsShowModalEditSampleDetect(false)
                    setIsShowModalDetect(true)
                }}
                onCancel={() => {
                    setIsShowModalEditSampleDetect(false)
                    setIsShowModalDetect(true)
                }}
                width={1000}
                style={{ maxWidth: '1000px' }}
                className='modal-tracking'
                footer={[
                    <Button key="back" onClick={() => {
                        setIsShowModalEditSampleDetect(false)
                        setIsShowModalDetect(true)
                    }}>
                        Quay lại
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => {
                        setIsShowModalEditSampleDetect(false)
                        setIsShowModalDetect(true)
                    }}>
                        Thêm mẫu
                    </Button>,
                ]}
            >
                <div className='modal-tracking-main tracking'>
                    <Table
                        className='table-image'
                        columns={columnsEdit}
                        dataSource={lstImageDetect}
                        showHeader={false}
                        scroll={{ y: 350 }}
                        pagination={paginationConfigImage}
                    />
                    <div className='pagination-number'>
                        <span>
                            {numberPagination}
                        </span>
                    </div>
                    {/* {lstImageDetect &&
                        <div className='lst-image-tracking'>
                            {lstImageDetect.map((item: any, index: any) => {
                                return (
                                    <div key={index} className='item-image'>
                                        <div className='image-tracking'>
                                            <img
                                                src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id}
                                                alt="" />
                                        </div>
                                        <Button onClick={() => handleDeleteDetectImage(item)}>
                                            Xóa ảnh
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    } */}
                </div>
            </Modal>

            <Modal
                title="Chỉnh sửa mẫu theo dõi"
                visible={isShowModalEditSampleTracking}
                onOk={() => {
                    setIsShowModalEditSampleTracking(false)
                    setIsShowModalTracking(true)
                }}
                onCancel={() => {
                    setIsShowModalEditSampleTracking(false)
                    setIsShowModalTracking(true)
                }}
                width={1000}
                style={{ maxWidth: '1000px' }}
                className='modal-tracking'
                footer={[
                    <Button key="back" onClick={() => {
                        setIsShowModalEditSampleTracking(false)
                        setIsShowModalTracking(true)
                    }}>
                        Quay lại
                    </Button>,
                    // <Button key="submit" type="primary" onClick={() => {
                    //     setIsShowModalEditSampleTracking(false)
                    //     setIsShowModalTracking(true)
                    //     handleFileUpload()
                    // }}>
                    //     Thêm mẫu
                    // </Button>,
                ]}
            >
                <div className='modal-tracking-main tracking'>
                    <Table
                        className='table-image'
                        columns={columnsEdit}
                        dataSource={lstImageTracking}
                        showHeader={false}
                        scroll={{ y: 350 }}
                        pagination={paginationConfigImage}
                    />
                    <div className='pagination-number'>
                        <span>
                            {numberPagination}
                        </span>
                    </div>
                    {/* {lstImageTracking &&
                        <div className='lst-image-tracking'>
                            {lstImageTracking.map((item: any, index: any) => {
                                return (
                                    <div key={index} className='item-image'>
                                        <div className='image-tracking'>
                                            <img
                                                src={'https://sit.api.pigman.com.vn/dataset/images/' + item.id}
                                                alt="" />
                                        </div>
                                        <Button onClick={() => handleDeleteTrackingImage(item)}>
                                            Xóa ảnh
                                        </Button>
                                    </div>
                                )
                            })}
                        </div>
                    } */}
                </div>
            </Modal>

            <Modal
                title="Xóa mẫu"
                visible={isShowDeleteTracking}
                onOk={() => {
                    setIsShowDeleteTracking(false)
                    setIsShowModalEditSampleTracking(true)
                }}
                onCancel={() => {
                    setIsShowDeleteTracking(false)
                    setIsShowModalEditSampleTracking(true)
                }}
                footer={false}
            >
                <div className='modal-delete'>
                    <div className='modal-delete-title'>Bạn có chắc chắn muốn xóa mẫu này?</div>
                    <div className='modal-delete-buttons'>
                        <Button onClick={() => {
                            setIsShowDeleteTracking(false)
                            setIsShowModalEditSampleTracking(true)
                        }}>Hủy bỏ</Button>
                        <Button type='primary' onClick={() => handleDeleteTrackingImageModal()}>Xóa</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="Xóa mẫu"
                visible={isShowDeleteDetect}
                onOk={() => {
                    setIsShowDeleteDetect(false)
                    setIsShowModalEditSampleDetect(true)
                }}
                onCancel={() => {
                    setIsShowDeleteDetect(false)
                    setIsShowModalEditSampleDetect(true)
                }}
                footer={false}
            >
                <div className='modal-delete'>
                    <div className='modal-delete-title'>Bạn có chắc chắn muốn xóa mẫu này?</div>
                    <div className='modal-delete-buttons'>
                        <Button onClick={() => {
                            setIsShowDeleteDetect(false)
                            setIsShowModalEditSampleDetect(true)
                        }}>Hủy bỏ</Button>
                        <Button type='primary' onClick={() => handleDeleteDetectImageModal()}>Xóa</Button>
                    </div>
                </div>
            </Modal>

            <Modal
                title="Lịch sử huấn luyện"
                visible={isShowTrainHistory}
                onOk={() => setIsShowTrainHistory(false)}
                onCancel={() => setIsShowTrainHistory(false)}
                footer={false}
                width={1000}
                style={{ maxWidth: '1000px' }}
                className='modal-tracking'
            >
                <div className='modal-tracking-main'>
                    <Table columns={columnsTrain} dataSource={dataTableTrain} scroll={{ y: 250 }} />
                </div>
            </Modal>
            <Modal
                visible={isModalOpen}
                onCancel={closeModal}
                width={600}
                footer={false}
                style={{ maxWidth: 600 }}
            >
                <div>
                    <img
                        src={selectedImage}
                        alt="Large Image"
                        style={{ width: '600px', height: '100%' }}
                    />
                    {/* <Button onClick={closeModal}>Đóng</Button> */}
                </div>
            </Modal>

            <Modal
                visible={isModalOpenTracking}
                onCancel={closeModalTracking}
                footer={false}
                style={{ maxHeight: 600, }}
            >
                <img
                    src={selectedImage}
                    alt="Large Image"
                    style={{ width: '100%', height: '600px' }}
                />
                {/* <Button onClick={closeModal}>Đóng</Button> */}
            </Modal>

            <Modal
                visible={isModalLabelOpen}
                onCancel={closeModalLabel}
                onOk={closeModalLabel}
                // footer={true}
                className='render-label'
            // style={{ top: 80, height: '450px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', zIndex: 9999 }}
            >
                {selectedLabel.map((label: string, index) => (
                    <div key={index}>{label}</div>
                ))}
                {/* <Button onClick={closeModal}>Đóng</Button> */}
            </Modal>
        </div>
    )
}

export default ModalProfile
