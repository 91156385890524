/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useEffect } from 'react';
import Modal from '@elements/modal/modal';
import FoodConsumeProvider from '@components/food-consume/food-consume-context';
import DoneIcon from '@material-ui/icons/Done';
import userServices from '@services/user';

import FoodSupplierStep from './food-supplier-step';
import PenConsumeStep from './pen-consume-step';
import FinalStep from './final-step';

import * as farmFoodConsumeApi from '@api/farm-food-consume';
import * as penFoodConsumeApi from '@api/pen-food-consume';
import { UtilCollections } from '@utils/collections';
import { Button, message, notification } from 'antd';

import { EStepStatus } from '@type/food-consume';
import { useDispatchRoot } from '@src/src/app/redux/store';
import { setCurrentStep } from '@src/src/app/redux/controller/farm.slice';

interface IFoodConsumeStep {
    currentStep: number;
    stepComponents: Array<{
        title: string;
        desc?: string;
        required: boolean;
        component: any;
        status: EStepStatus;
    }>;
}

const StepModal: FC<{ isShowModal: any; toggleModal: any; fetchListAfterAdd: any }> = ({
    isShowModal,
    toggleModal,
    fetchListAfterAdd,
}: any) => {
    const dispatch = useDispatchRoot();
    const user = userServices.get();

    const nextStep = () =>
        setSteps({
            ...steps,
            currentStep: steps.currentStep >= totalSteps - 1 ? steps.currentStep : steps.currentStep + 1,
        });

    const prevStep = (index: number) => setSteps({ ...steps, currentStep: index });

    const jumpStep = (index: number) => () => setSteps({ ...steps, currentStep: index });

    const save = (dataFoods: any, valueInputs: any) => {
        const { farmFoodConsume, penFoodConsume } = dataFoods;
        console.log(dataFoods);

        farmFoodConsume.name = valueInputs.name;
        farmFoodConsume.date = UtilCollections.formatDateSearchBeHaviour(valueInputs.date);
        const tmp: any = []
        penFoodConsume.map((v: any) => {
            v.penFoodConsumeItems.map((v1: any) => {
                tmp.push({
                    foodId: v1.food.id,
                    quantity: v1.quantity,
                    additionalProp1: {}
                })
            })
        });

        farmFoodConsume.foodConsumeItems = tmp;
        farmFoodConsume.quantityOfPens = penFoodConsume.length;
        farmFoodConsume.userId = user.id
        delete farmFoodConsume.farmFoodConsume;
        const tmpFarmFoodConsume = {
            name: farmFoodConsume.name,
            date: farmFoodConsume.date,
            farmId: dataFoods.farmId,
            userId: farmFoodConsume.userId,
            foodConsumeItems: farmFoodConsume.foodConsumeItems,
            quantityOfPens: farmFoodConsume.quantityOfPens,
            additionalProp1: {}
        }
        console.log(tmpFarmFoodConsume);

        farmFoodConsumeApi.createFoodConsume(tmpFarmFoodConsume)
            .then((res: any) => {
                console.log(res);

                const penFoodConsumeApis = penFoodConsume.map((v: any) => {
                    delete v.penName;
                    v.name = valueInputs.name;
                    v.date = UtilCollections.formatDateSearchBeHaviour(valueInputs.date);
                    v.foodConsumeId = res.id;
                    v.penFoodConsumeItems = v.penFoodConsumeItems.map((value: any) => {
                        return {
                            foodId: value.food.id,
                            quantity: value.quantity,
                            foodSupplierId: value.foodSupplierId,
                        };
                    });
                    return penFoodConsumeApi.createPenFoodConsume(v);
                });

                Promise.all(penFoodConsumeApis)
                    .then((res) => {
                        fetchListAfterAdd();
                        notification.success({
                            message: 'Tạo thành công',
                        } as any);
                        toggleModal(false)();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((err: any) => {
                console.log(err);
                notification.error({
                    message: 'Tạo thất bại',
                    description: 'Số lượng thức ăn trong kho không đủ, vui lòng chọn lại',
                } as any);
            });
    };

    const setStepStatus = (status: EStepStatus, index: number) => {
        const stepComponents = steps.stepComponents;
        stepComponents[index].status = status;

        setSteps({ currentStep: index, stepComponents });
    };

    const [steps, setSteps] = React.useState<IFoodConsumeStep>({
        currentStep: 0,
        stepComponents: [
            {
                title: 'Chọn nhà cung cấp',
                desc: '',
                required: true,
                component: <FoodSupplierStep next={nextStep} setStepStatus={setStepStatus} />,
                status: EStepStatus.NOT_COMPLETE,
            },
            {
                title: 'Cấp thức ăn cho chuồng',
                desc: '',
                required: true,
                component: <PenConsumeStep setStepStatus={setStepStatus} />,
                status: EStepStatus.NOT_COMPLETE,
            },
            {
                title: 'Tổng quát',
                desc: '',
                required: true,
                component: <FinalStep prev={() => prevStep(1)} save={save} setStepStatus={setStepStatus} />,
                status: EStepStatus.NOT_COMPLETE,
            },
        ],
    });

    const totalSteps = steps.stepComponents.length;

    const renderAction = () => {
        const prev = (
            <Button onClick={() => prevStep(0)} className={'btn back'} >
                Lùi lại
            </Button>
        );

        const next =
            steps.stepComponents[steps.currentStep].status === EStepStatus.COMPLETE ? (
                <Button onClick={nextStep} className="btn next">
                    Tiếp
                </Button>
            ) : (
                ''
            );

        return (
            <>
                {prev} {next}
            </>
        );
    };

    useEffect(() => {
        console.log(steps);
        dispatch(setCurrentStep(steps.currentStep))
    }, [steps]);

    return (
        <div className='modal-food-consume'>
            <Modal title={'Thêm thông tin xuất kho thức ăn'} toggleModal={toggleModal} isShowModal={isShowModal}>
                <FoodConsumeProvider>
                    <div id="wrapper-steps-progress">
                        {steps.stepComponents.map((v, i) => {
                            const index = i + 1;
                            return (
                                <div
                                    key={i}
                                    className={`step-progress pointer ${i < steps.currentStep && 'success-step'}`}
                                // onClick={jumpStep(i)}
                                >
                                    <span className="on-progress">{i < steps.currentStep ? <DoneIcon /> : index}</span>
                                    <div className="progress">
                                        <span
                                            className={`progress-title ${i === steps.currentStep ? 'progress-title-active' : ''
                                                }`}>
                                            {v.title}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="step-content"> {steps.stepComponents[steps.currentStep].component} </div>

                    {steps.currentStep < totalSteps - 1 && <div className={"wrapper-action" + (steps.currentStep === 0 ? ' hide' : '')}>{renderAction()}</div>}
                </FoodConsumeProvider>
            </Modal>
        </div>
    );
};

export default StepModal;
