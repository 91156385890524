import { Button, DatePicker, Form, Input, Modal, Space, Tooltip, notification } from 'antd'
import React, { useEffect } from 'react'
import * as farmFoodConsumeApi from '@api/farm-food-consume';
import axios from 'axios';
import moment from 'moment';
import Table, { ColumnType } from 'antd/lib/table';
import farmServices from '@services/farm';

interface Props {
    toggleModal: any,
    isShowModal: any,
    foodConsumeId: any,
    refreshData: any,
}

const columns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Tên',
        dataIndex: 'namePen',
        key: 'namePen',
    },
    {
        title: 'Diện tích (m2)',
        dataIndex: 'area',
        key: 'area',
    },
    // {
    //     title: 'Số lượng',
    //     dataIndex: 'quantity',
    //     key: 'quantity',
    // },
    {
        title: 'Cân nặng',
        dataIndex: 'weight',
        key: 'weight',
    },
    {
        title: 'Xuất thức ăn',
        dataIndex: 'exportFood',
        key: 'exportFood',
    }

];

const columnsFood = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Tên',
        dataIndex: 'name',
        key: 'name',

    },
    {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Đơn vị',
        dataIndex: 'unit',
        key: 'unit',
    }
]
const EditFoodConsume = (props: Props) => {
    const [detailFoodConsume, setDetailFoodConsume] = React.useState<any>();
    const [lstPen, setLstPen] = React.useState<any[]>();
    const [lstFood, setLstFood] = React.useState<any[]>();
    const [lstWeight, setLstWeight] = React.useState<any[]>();
    const [data, setData] = React.useState<any>([]);
    const [dataFoodInventory, setDataFoodInventory] = React.useState<any[]>([]);
    const [form] = Form.useForm();
    const currentFarm: any = farmServices.getCurrentFarm() as any;
    const [foodInventory, setFoodInventory] = React.useState<any[]>();
    const [lstPenFoodConsume, setLstPenFoodConsume] = React.useState<any[]>();
    const [isDisable, setIsDisable] = React.useState<boolean>(false);
    React.useEffect(() => {
        getDetailFoodConsume();
        if (detailFoodConsume) {
            form.setFieldsValue({
                nameFoodConsume: detailFoodConsume?.name,
                dateFoodConsume: moment(detailFoodConsume?.date)
            });
        }
    }, []);
    const handleKeyPress = (e: any) => {
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode === 44 || charCode === 46 || charCode === 101) {
            e.preventDefault();
        }
    };
    const getDetailFoodConsume = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/foodConsume/${props.foodConsumeId}?size=1000`)
            .then((res) => {
                const tmp = res.data.data;
                console.log(tmp);
                getFoodInventory(tmp.foodSupplierId);
                setDetailFoodConsume(tmp);
            })
    }

    const getAllPen = async (idFarm: string) => {
        const req = {
            offset: 0,
            size: 200,
            farmId: idFarm,
            additionalProp1: {},
        }
        await axios.post(`https://sit.api.pigman.com.vn/pens/all`, req)
            .then((res) => {
                setLstPen(res.data.data.items);
            })
    }

    const getAllFoodByFoodSupplier = async (idFoodSupplier: string) => {
        await axios.get(`https://sit.api.pigman.com.vn/food/foodSupplier/${idFoodSupplier}?offset=0&size=1000`)
            .then((res) => {
                setLstFood(res.data.data.items);
            })
    }

    const getFoodInventory = async (foodSupplierId: string) => {
        await axios.get(`https://sit.api.pigman.com.vn/foodInventory?farmId=${currentFarm.id}&foodSupplierId=${foodSupplierId}&size=1000&offset=0`)
            .then((res) => {
                console.log(res.data.data.items);
                setFoodInventory(res.data.data.items);
            })
    }

    useEffect(() => {
        if (detailFoodConsume) {
            console.log(detailFoodConsume);
            getAllPen(detailFoodConsume.farm.id);
            getAllFoodByFoodSupplier(detailFoodConsume.foodSupplierId);
        }

    }, [detailFoodConsume]);

    useEffect(() => {
        console.log(lstPen);
        console.log(lstFood);
        console.log(detailFoodConsume);
        const tmp1: any[] = [];
        if (detailFoodConsume) {
            detailFoodConsume.lstPenFoodConsume?.map((item: any) => {
                item.penFoodConsumeItem?.items.map((item1: any) => {
                    console.log(item1);
                    tmp1.push({
                        idPen: item.pen.id,
                        idFood: item1.food.id,
                        quantity: item1.quantity
                    });
                })
            })
        }
        console.log(tmp1);
        setLstPenFoodConsume(tmp1);

        if (lstPen && lstPen.length > 0 && lstFood && lstFood.length > 0) {
            // Gán dữ liệu lấy được vào biến data
            const tmp = lstPen.map((item: any, index: number) => {
                return {
                    index: index + 1,
                    namePen: item.name,
                    area: item.area,
                    quantity: item.capacity,
                    weight: item.weightType.name,
                    exportFood:
                        <div className='lst-export-food'>
                            {lstFood.map((food, index) => {
                                return (
                                    <>
                                        <div className='lst-food'>
                                            <div>{food.name}</div>
                                            <Input
                                                type="number"
                                                min={1}
                                                onKeyPress={handleKeyPress}
                                                className="input-number"
                                                defaultValue={
                                                    tmp1.find((item1: any) => item1.idPen === item.id && item1.idFood === food.id)?.quantity ? tmp1.find((item1: any) => item1.idPen === item.id && item1.idFood === food.id)?.quantity : null
                                                }
                                                onChange={handleChangeQuantity(item.id, food.id)}
                                            />
                                        </div>
                                    </>
                                )
                            })}
                        </div>

                }
            });

            setData(tmp);
        }
    }, [lstPen, lstFood]);

    useEffect(() => {
        if (foodInventory && foodInventory.length > 0) {
            const tmp = foodInventory.map((item, index) => {
                return {
                    index: index + 1,
                    name: item.food?.name,
                    quantity: item.quantity,
                    unit: item.food?.foodUnit?.name
                }
            });
            setDataFoodInventory(tmp);
        }
    }, [foodInventory]);

    const handleChangeQuantity = (idPen: string, idFood: string) => (e: any) => {
        console.log(e.target.value);
        console.log(idPen);
        console.log(idFood);
        setIsDisable(false);
        const tmp = lstPenFoodConsume;

        tmp?.push({
            idPen: idPen,
            idFood: idFood,
            quantity: e.target.value ? parseInt(e.target.value) : 0
        });

        // foodInventory?.map((item: any) => {
        //     if (item.food.id === idFood) {
        //         if (item.quantity < parseInt(e.target.value)) {
        //             notification.error({
        //                 message: 'Thất bại',
        //                 description: `Số lượng thức ăn ${item.food?.name} không đủ`

        //             });
        //             setIsDisable(true);
        //             return;
        //         }
        //     }
        // });

        setLstPenFoodConsume(tmp);

    }

    const handleFinishModal = async () => {
        console.log(lstPenFoodConsume);
        const uniqueItems = [];
        const lastItemIndexMap = new Map();
        if (lstPenFoodConsume) {
            for (let i = lstPenFoodConsume.length - 1; i >= 0; i--) {
                const item = lstPenFoodConsume[i];
                const combination = `${item.idPen}-${item.idFood}`;

                if (!lastItemIndexMap.has(combination)) {
                    lastItemIndexMap.set(combination, i);
                }
            }

            for (const [combination, lastIndex] of lastItemIndexMap) {
                uniqueItems.push(lstPenFoodConsume[lastIndex]);
            }
        }

        const tmp = uniqueItems?.map((item: any) => {
            return {
                penId: item.idPen,
                foodId: item.idFood,
                quantity: item.quantity,
                additionalProp1: {}
            }
        })
        console.log(tmp);
        const filteredData = tmp.filter((item) => item.quantity !== 0 && item.quantity !== null && item.quantity !== undefined && !Number.isNaN(item.quantity));

        const req = {
            name: form.getFieldValue('nameFoodConsume') ? form.getFieldValue('nameFoodConsume') : detailFoodConsume?.name,
            date: form.getFieldValue('dateFoodConsume') ? form.getFieldValue('dateFoodConsume').format('YYYY-MM-DD') : detailFoodConsume?.date,
            lst: filteredData,
        }
        console.log(req);

        await axios.put(`https://sit.api.pigman.com.vn/foodConsume/${props.foodConsumeId}`, req)
            .then((res) => {
                console.log(res);
                notification.success({
                    message: 'Thành công',
                    description: 'Sửa đơn xuất thành công'
                });
                props.toggleModal();
                props.refreshData();
            })
            .catch((err) => {
                console.log(err);
                notification.error({
                    message: 'Sửa đơn xuất thất bại',
                    description: err.response.data.message
                });
            });
    }
    const disabledDate = (current: any) => {
        // Disable dates that are after the current date
        return current && current > moment().endOf('day');
    };
    return (

        <Modal
            className='modal-food-consume'
            title="Cập nhật thông tin xuất kho thức ăn"
            visible={props.isShowModal}
            onOk={props.toggleModal}
            onCancel={props.toggleModal}
            width={1000}
            style={{ maxWidth: 1000 }}
            footer={
                <div className='action-btn'>
                    <Button className='cancel-btn' onClick={props.toggleModal}>
                        Hủy bỏ
                    </Button>
                    <Button disabled={isDisable} className='summit-btn' type="primary" htmlType="submit" onClick={handleFinishModal}>
                        Cập nhật
                    </Button>

                </div>
            }
        >
            {detailFoodConsume &&
                <Form layout="vertical" form={form}>
                    <div className='form-content'>
                        <div className='form-content-left'>
                            <Form.Item name='nameFoodConsume' label='Tên người xuất'>
                                <Input type="text" className="form-control" defaultValue={detailFoodConsume?.name} />
                            </Form.Item>
                            <Form.Item name='dateFoodConsume' label='Ngày xuất'>
                                {detailFoodConsume && detailFoodConsume.date &&
                                    <DatePicker
                                        className="form-control"
                                        format="DD-MM-YYYY"
                                        defaultValue={moment(detailFoodConsume.date)}
                                        disabledDate={disabledDate}
                                    />
                                }
                            </Form.Item>
                        </div>
                        <div className='form-content-right'>
                            <b>Danh sách thức ăn tồn kho</b>
                            <Table columns={columnsFood} dataSource={dataFoodInventory} />
                        </div>
                    </div>
                    <Form.Item>
                        <div>
                            <b>Danh sách chi tiết thức ăn</b>
                            <Table columns={columns} dataSource={data} />
                        </div>
                    </Form.Item>

                </Form>
            }
        </Modal >

    )
}

export default EditFoodConsume
