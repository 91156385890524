/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import Modal from '@elements/modal/modal';
import { penConsumeFoodDetail, penConsumePharmaceuticalDetail } from './step/food-step-columns';
import { Table } from 'antd';

import * as farmFoodConsumeApi from '@api/farm-food-consume';

import { UtilCollections } from '@utils/collections';

const DetailFoodConsume: FC<any> = ({ foodConsumeId, isShowModal, toggleModal }: any) => {
    const [detailFoodConsume, setDetailFoodConsume] = React.useState<any>();

    React.useEffect(() => {
        (async () => {
            const data = (await farmFoodConsumeApi.getDetailPharmaceutical(foodConsumeId)) as any;
            console.log({ data });

            setDetailFoodConsume(data);
        })();
    }, []);

    return (
        <Modal title="Chi tiết thông tin xuất kho thuốc/Vacxin" isShowModal={isShowModal} toggleModal={toggleModal} className={'detail-food-consume'}>
            {detailFoodConsume ? (
                <div>
                    <div id="detail-info">
                        <div className="info">
                            <span className="info-title"> Tên người xuất</span>

                            <span className="info-content"> {detailFoodConsume.name} </span>
                        </div>

                        <div className="info">
                            <span className="info-title">Ngày xuất </span>

                            <span className="info-content">
                                {UtilCollections.convertDateToDMY(
                                    UtilCollections.formatClientDate(detailFoodConsume.date.split('T')[0]),
                                )}
                            </span>
                        </div>

                        {/* <div className="info">
                            <span className="info-title"> Được tạo bởi </span>

                            <span className="info-content"> {detailFoodConsume.user.fullName} </span>
                        </div> */}

                        <div className="info">
                            <span className="info-title"> Số chuồng được phát thức ăn </span>

                            <span className="info-content"> {detailFoodConsume.lstPenPharmaceuticalConsume.length} </span>
                        </div>
                    </div>

                    <div id="pen-food-consume-detail">
                        <h3>Chi tiết phát thức ăn cho từng chuồng</h3>

                        <Table
                            dataSource={detailFoodConsume.lstPenPharmaceuticalConsume}
                            columns={penConsumePharmaceuticalDetail}
                            pagination={false}
                            bordered
                        />
                    </div>
                </div>
            ) : (
                <span />
            )}
        </Modal>
    );
};

export default DetailFoodConsume;
