import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import './style.profile.scss';
import { Button, Select, Spin, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import * as XLSX from 'xlsx';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên trang trại',
        dataIndex: 'farmName',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'penName',
    },
    {
        title: 'ID lợn phát hiện bởi AI',
        dataIndex: 'pigAIId',
    },
    {
        title: 'Tình trạng sức khỏe',
        dataIndex: 'healthStatus',
    },
];
const Option = Select.Option;
const PigList: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [lstHealth, setLstHealth] = useState<any>([]);
    const [healthId, setHealthId] = useState<any>(null);
    const [loadingButton, setLoadingButton] = useState(false);
    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();

    useEffect(() => {
        getAllHealth();
        fetchData(1);
    }, [healthId]);
    const getAllHealth = async () => {
        await axios.get(`https://sit.api.pigman.com.vn/admin/getAllHealthStatus/getAllHealthStatus`)
            .then((response) => {
                console.log(response.data.data);
                setLstHealth(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const fetchData = async (page: any) => {
        setLoading(true);
        let url = ''
        if (healthId) {
            url = `https://sit.api.pigman.com.vn/admin/allPig/getAllPigFromAdmin?size=${pageSize}&offset=${pageSize * (page - 1)}&healthStatus=${healthId}`
        }
        else {
            url = `https://sit.api.pigman.com.vn/admin/allPig/getAllPigFromAdmin?size=${pageSize}&offset=${pageSize * (page - 1)}`
        }
        await axios
            .get(url)
            .then((response) => {
                console.log(response.data.data[0].items);
                let count = pageSize * (page - 1);
                setTotalRecords(response.data.data[1].total)
                setData(
                    response.data.data[0].items.map((obj: any) => {
                        // Tính số thứ tự theo trang
                        count += 1;
                        return {
                            key: count, // STT (cột đầu tiên)
                            farmName: obj.farm?.name || 'Không xác định', // Tên trang trại 
                            penName: obj.pen?.name || 'Không xác định',
                            pigAIId: obj.pigAIId || 'Không xác định',
                            healthStatus: obj.healthstatus.name
                        };
                    }),
                );
                setLoading(false);

            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }


    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);

        // Gán giá trị trang hiện tại và số bản ghi trên một trang ngay lập tức
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);

        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
            fetchData(page);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalRecords,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };
    const handleChangeFarmList = (value: any) => {
        setHealthId(value);
    }
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter));
    const exportToExcel = async () => {
        setLoadingButton(true);

        await axios.get(`https://sit.api.pigman.com.vn/admin/allPig/getAllPigFromAdmin?size=100000&offset=0`)
            .then((response) => {
                console.log(response.data.data[0].items);
                let count = 0;
                const data = response.data.data[0].items.map((obj: any) => {
                    // Tính số thứ tự theo trang
                    count += 1;
                    return {
                        key: count, // STT (cột đầu tiên)
                        farmName: obj.farm?.name || 'Không xác định', // Tên trang trại 
                        penName: obj.pen?.name || 'Không xác định',
                        pigAIId: obj.pigAIId || 'Không xác định',
                        healthStatus: obj.healthstatus.name
                    };
                });
                // Map over sortData to create a new array with the desired column names
                const dataWithVietnameseKeys = data.map((item: any) => ({
                    'STT': item.key,
                    'Tên trang trại': item.farmName,
                    'Tên chuồng': item.penName,
                    'ID lợn phát hiện bởi AI': item.pigAIId,
                    'Tình trạng sức khỏe': item.healthStatus,
                }));

                const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
                ws['!cols'] = [
                    { width: 5 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                    { width: 30 },
                ];
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, "Danh sach ca the Lon.xlsx");
                setLoadingButton(false);
            })


    };
    return (
        <>
            <div className="farmList-body">
                <div className="button-farmList">
                    <p className="farmList-h2">Cá thể lợn</p>
                    {/* <div className="search-and-sort">
                        <Search placeholder="Tìm kiếm" onSearch={onSearch} enterButton allowClear />
                    </div> */}
                    <div className="search-and-sort">
                        <Select
                            className='select-behaviour-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                            onChange={handleChangeFarmList}
                            placeholder="Lọc theo hành vi"
                        >
                            <Option key={0} value={''} title={'Tất cả hành vi'}>{'Tất cả hành vi'}</Option>
                            {lstHealth?.map((item: any, index: any) => (
                                <Option key={index} value={item.id} title={item.name}>{item.name}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className="table-farmList">
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        <div className='button-excel'>
                            <Button loading={loadingButton} disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>

                        </div>
                    </div>
                </Spin>
            </div >
        </>
    );
};

export default PigList;
