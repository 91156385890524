import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import './not-found.scss';
import Video from '@assets/404-video.mp4';

const NotFound: FC = () => {
    const location = useLocation();
    const [content, setContent] = useState<string>('404 Page not found!');
    const history = useHistory();
    useEffect(() => {
        const ref = window.location.href;
        console.log(ref);

        if (!ref.includes('active=')) return;
        const index = ref.indexOf('active=')
        const activation = ref.substring(index + 'active='.length);
        console.log(activation);
        const buff = Buffer.from(activation, 'base64');
        const activateUrl = buff.toString('ascii');
        console.log(activateUrl);
        fetch(activateUrl).then(
            (res) => res.json()
        ).then(
            (data) => {
                console.log(data)
                if (data.statusCode === 'OK')
                    setContent('Tài khoản được kích hoạt thành công.\n Đang chuyển hướng về Trang chủ ..');
                else
                    setContent('Tài khoản đã được kích hoạt trước đó.\n Đang chuyển hướng về Trang chủ ..')
                setTimeout(() => {
                    history.push('/');
                }, 3000);
            }
        ).catch(
            (err) => {
                console.log(err);
                setContent('Tài khoản chưa được kích hoạt. \n Đang chuyển hướng về Trang chủ ..');
                setTimeout(() => {
                    history.push('/');
                }, 3000);
            }
        )
    }, [location])
    console.log();
    return (
        <div id='not-found'>
            <video autoPlay muted loop id="myVideo">
                <source src={Video} type="video/mp4" />
            </video>
            <p id='not-found__text'>{content}</p>
        </div>
    )
};

export default NotFound;