import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './style.farmList.scss';
import { Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên trang trại',
        dataIndex: 'farmName',
    },
    {
        title: 'Địa chỉ',
        dataIndex: 'address',
    },
    {
        title: 'Ngày tạo',
        dataIndex: 'createdDate',
    },
    {
        title: 'Chủ trang trại',
        dataIndex: 'scale',
    },
    {
        title: 'Người quản lý',
        dataIndex: 'manager',
    },
    // {
    //     title: 'Chủ trang trại',
    //     dateIndex: 'id',
    // },
    // {
    //     title: 'Nhiệt độ',
    //     dataIndex: 'temperature',
    // },
    // {
    //     title: 'Độ ẩm',
    //     dataIndex: 'humidity',
    // },
    // {
    //     title: 'Loại trang trại',
    //     dataIndex: 'farmType',
    // },
    {
        title: 'Tổng số chuồng',
        dataIndex: 'totalCage',
    },
    {
        title: 'Tổng số lợn',
        dataIndex: 'totalPig',
    }

];

const FarmList: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [dataUser, setDataUser] = useState([]);

    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();

    useEffect(() => {
        getDataUser();
        fetchData();
    }, []);

    const fetchData = () => {
        axios
            .get(`https://sit.api.pigman.com.vn/admin/user/farm/${id.userID}`)
            .then((response) => {
                console.log(response.data.data);
                let count = 0;
                setData(
                    response.data.data.map((farm: any) => {
                        console.log(farm.ownerEmail);

                        count += 1;
                        return {
                            key: count, // STT (cột đầu tiên)
                            farmName: farm.name, // Tên trang trại 
                            address: farm.address,
                            temperature: farm.lowerTemperature,
                            humidity: farm.lowerHumidity,
                            createdDate: farm.createdAt ? moment(farm.createdAt).format('HH:MM:SS DD/MM/YYYY') : 'Không xác định',
                            scale: farm.ownerEmail ? farm.ownerEmail : 'Không xác định',
                            manager: farm.managerEmail ? farm.managerEmail : 'Không xác định',
                            farmType: farm.farmType,
                            totalCage: farm.pens,
                            totalPig: farm.totalPigsAI,
                        };
                    }),
                );
            })

            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }

    const getDataUser = () => {
        axios.get('https://sit.api.pigman.com.vn/admin/users').then((response) => {
            console.log(response.data.data);
            setDataUser(response.data.data);

        })
    }

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter.toLowerCase()));
    const user: any = dataUser.filter((item: any) => item.id === id.userID)[0];
    console.log(user)
    return (
        <>
            <div className="farmList-body">
                <div className="button-farmList">
                    <p className="farmList-h2">Quản lý Trang trại của {user?.fullName}</p>
                    {/* <div className="search-and-sort">
                        <Search placeholder="Tìm kiếm" onSearch={onSearch} enterButton allowClear />
                    </div> */}
                    <Search
                        className='search-and-sort'
                        placeholder="Tìm kiếm tên trang trại"
                        onChange={(event) => setFilter(event.target.value.toString().toLocaleLowerCase())}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </div>
                <div className="table-farmList">
                    <Table
                        dataSource={sortData}
                        columns={columns}
                        rowClassName={getRowClassName}
                        pagination={paginationConfig}
                        style={{ minHeight: '800px' }}
                    />
                </div>
            </div>
        </>
    );
};

export default FarmList;
