/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Cookies from 'universal-cookie';
import { ILoginUser } from '@components/auth/auth.types';

const keyStorageUser = 'userStorageCookies';
const cookies = new Cookies();

export default {
    get(): ILoginUser {
        return cookies.get(keyStorageUser);
    },
    set(user: ILoginUser) {
        cookies.set(keyStorageUser, user, { path: '/' })
    },
    logout(): any {
        cookies.remove(keyStorageUser, { path: '/' })
    }
}