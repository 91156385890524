/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { DeleteTwoTone } from '@ant-design/icons';
import { DatePicker } from 'antd';

import { UtilCollections } from '@utils/collections';
import moment from 'moment';

enum EOrderType {
    PUT_ON_ORDERED,
    REMOVE_FROM_ORDERED,
}

const initialColumns = () => [
    // {
    //     title: 'STT',
    //     dataIndex: 'index',
    //     key: 'index',
    //     render: function renderIndex(value: any, item: any, index: any) {
    //         return ++index;
    //     },
    // },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(v: any) {
            return <span> {v.food ? v.food.name : v.name} </span>;
        },
    },
    {
        title: 'Loại',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span>{v.food ? v.food.foodType?.name : v.foodType?.name}</span>;
        },
    },
];
const initialColumnsPharmaceutical = () => [
    // {
    //     title: 'STT',
    //     dataIndex: 'index',
    //     key: 'index',
    //     render: function renderIndex(value: any, item: any, index: any) {
    //         return ++index;
    //     },
    // },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(v: any) {
            return <span> {v.pharmaceutical ? v.pharmaceutical.name : v.name} </span>;
        },
    },
    {
        title: 'Loại',
        key: 'index',
        render: function renderFoodType(v: any) {
            console.log(v);

            return <span>{v.pharmaceutical ? v.pharmaceutical.pharmaceuticalType?.name : v.pharmaceuticalType?.name}</span>;
        },
    },
];

export const choosingColumns = (onChangeInputData: any, doOrder: any, errors: Record<string, string[]>) => [
    ...initialColumns(),
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            const id = v.food ? v.food.id : v.id;
            const errorsKey = Object.keys(errors);
            const isError =
                errorsKey[errorsKey.indexOf(id)] === id && errors.hasOwnProperty(id) && errors[id].includes('quantity');
            return (
                <>
                    <div className="ordered-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{v.food?.name}</span> &#160;
                        <div className="ordered-input__change-order">
                            <input
                                type="number"
                                key={v.food ? v.food.id : v.id}
                                min={1}
                                defaultValue={v.quantity}
                                onChange={onChangeInputData(id, 'quantity')}
                                className={`${isError && 'error-data-input'}`}
                            />

                            <span>/</span>
                            <span> {v.foodUnit?.name}</span>
                        </div>
                    </div>

                    <span className="error-data">{isError && 'Phải là số'}</span>
                </>
            );
        },
    },
    // {
    //     title: 'Đơn giá',
    //     key: 'index',
    //     render: function renderFoodPrice(v: any) {
    //         const id = v.food ? v.food.id : v.id;
    //         const errorsKey = Object.keys(errors);
    //         const isError =
    //             errorsKey[errorsKey.indexOf(id)] === id && errors.hasOwnProperty(id) && errors[id].includes('price');

    //         return (
    //             <>
    //                 <div className="ordered-input">
    //                     <input
    //                         type="text"
    //                         onChange={onChangeInputData(id, 'price')}
    //                         className={`${isError && 'error-data-input'}`}
    //                     />
    //                 </div>
    //                 <span className="error-data">{isError && 'Phải là số'}</span>
    //             </>
    //         );
    //     },
    // },
    // {
    //     title: 'Ngày hết hạn',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderExpirationDate(v: any) {
    //         return (
    //             <div className="ordered-input">
    //                 <DatePicker
    //                     name="date"
    //                     id="date"
    //                     placeholder={'Chọn ngày'}
    //                     onChange={(date, dateString) => {
    //                         onChangeInputData(v.food ? v.food.id : v.id, 'expirationDate', dateString)();
    //                     }}
    //                     format={'YYYY-MM-DD'}
    //                     defaultValue={moment(new Date(), 'YYYY-MM-DD')}
    //                 />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Tổng giá',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderSubTotal(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.quantity * v.price || 0)} </span>;
    //     },
    // },
    {
        title: '',
        key: 'index',
        width: '5%',
        render: function renderFoodAction(v: any) {
            return (
                <span onClick={doOrder(v.food ? v.food.id : v.id, EOrderType.REMOVE_FROM_ORDERED)} className="pointer">
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                </span>
            );
        },
    },
];
export const choosingColumnsPharmaceutical = (onChangeInputData: any, doOrder: any, errors: Record<string, string[]>) => [
    ...initialColumnsPharmaceutical(),
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            const id = v.pharmaceutical
                ? v.pharmaceutical
                    .id : v.id;
            const errorsKey = Object.keys(errors);
            const isError =
                errorsKey[errorsKey.indexOf(id)] === id && errors.hasOwnProperty(id) && errors[id].includes('quantity');
            return (
                <>
                    <div className="ordered-input" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{v.pharmaceutical
                            ?.name}</span> &#160;
                        <div className="ordered-input__change-order">
                            <input
                                type="number"
                                min={1}
                                defaultValue={v.quantity}
                                key={v.id}

                                onChange={onChangeInputData(id, 'quantity')}
                                className={`${isError && 'error-data-input'}`}
                            />

                            <span>/</span>
                            <span> {v.pharmaceuticalUnit?.name}</span>
                        </div>
                    </div>

                    <span className="error-data">{isError && 'Phải là số'}</span>
                </>
            );
        },
    },
    // {
    //     title: 'Đơn giá',
    //     key: 'index',
    //     render: function renderFoodPrice(v: any) {
    //         const id = v.food ? v.food.id : v.id;
    //         const errorsKey = Object.keys(errors);
    //         const isError =
    //             errorsKey[errorsKey.indexOf(id)] === id && errors.hasOwnProperty(id) && errors[id].includes('price');

    //         return (
    //             <>
    //                 <div className="ordered-input">
    //                     <input
    //                         type="text"
    //                         onChange={onChangeInputData(id, 'price')}
    //                         className={`${isError && 'error-data-input'}`}
    //                     />
    //                 </div>
    //                 <span className="error-data">{isError && 'Phải là số'}</span>
    //             </>
    //         );
    //     },
    // },
    // {
    //     title: 'Ngày hết hạn',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderExpirationDate(v: any) {
    //         return (
    //             <div className="ordered-input">
    //                 <DatePicker
    //                     name="date"
    //                     id="date"
    //                     placeholder={'Chọn ngày'}
    //                     onChange={(date, dateString) => {
    //                         onChangeInputData(v.food ? v.food.id : v.id, 'expirationDate', dateString)();
    //                     }}
    //                     format={'YYYY-MM-DD'}
    //                     defaultValue={moment(new Date(), 'YYYY-MM-DD')}
    //                 />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Tổng giá',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderSubTotal(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.quantity * v.price || 0)} </span>;
    //     },
    // },
    {
        title: '',
        key: 'index',
        width: '5%',
        render: function renderFoodAction(v: any) {
            return (
                <span onClick={doOrder(v.food ? v.food.id : v.id, EOrderType.REMOVE_FROM_ORDERED)} className="pointer">
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                </span>
            );
        },
    },
];
export const updateColumns = (onChangeInputUpdateData: any, removeOrder: any) => [
    ...initialColumns(),
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any, tmp: any) {
            return (
                <div className="ordered-input">
                    <input
                        key={v.id}
                        type="number"
                        min={'1'}
                        defaultValue={v.quantity}
                        onChange={onChangeInputUpdateData(v, 'quantity')}
                    />
                    /{v.food ? v.food.foodUnit.name : v.foodUnit.name}
                </div>
            );
        },
    },
    // {
    //     title: 'Đơn giá',
    //     key: 'index',
    //     render: function renderFoodPrice(v: any, item: any, index: number) {
    //         return (
    //             <div className="ordered-input">
    //                 <input type="text" onChange={onChangeInputUpdateData(v, 'price')} defaultValue={v.price} />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Ngày hết hạn',
    //     key: 'index',
    //     render: function renderExpirationDate(v: any, item: any, index: number) {
    //         return (
    //             <div className="ordered-input">
    //                 <DatePicker
    //                     name="date"
    //                     id="date"
    //                     placeholder={'Chọn ngày'}
    //                     onChange={(date, dateString) => {
    //                         onChangeInputUpdateData(v.food ? v.food.id : v.id, 'expirationDate', dateString)();
    //                     }}
    //                     defaultValue={moment(v.expirationDate !== '' ? v.expirationDate : new Date(), 'YYYY-MM-DD')}
    //                     format={'YYYY-MM-DD'}
    //                 />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Tổng giá',
    //     key: 'index',
    //     render: function renderSubTotal(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.quantity * v.price || 0)} </span>;
    //     },
    // },
    {
        title: '',
        key: 'index',
        render: function renderFoodAction(v: any) {
            return (
                <span onClick={removeOrder(v.id)} className="pointer">
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                </span>
            );
        },
    },
];
export const updateColumnsPharmaceutical = (onChangeInputUpdateData: any, removeOrder: any) => [
    ...initialColumnsPharmaceutical(),
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any, item: any, index: number) {
            return (
                <div className="ordered-input">
                    <input
                        key={v.id}
                        type="number"
                        min={'1'}
                        defaultValue={v.quantity}
                        onChange={onChangeInputUpdateData(v, 'quantity')}
                    />
                    /{v.pharmaceutical && v.pharmaceutical
                        .pharmaceuticalUnit
                        .name}
                </div>
            );
        },
    },
    // {
    //     title: 'Đơn giá',
    //     key: 'index',
    //     render: function renderFoodPrice(v: any, item: any, index: number) {
    //         return (
    //             <div className="ordered-input">
    //                 <input type="text" onChange={onChangeInputUpdateData(v, 'price')} defaultValue={v.price} />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Ngày hết hạn',
    //     key: 'index',
    //     render: function renderExpirationDate(v: any, item: any, index: number) {
    //         return (
    //             <div className="ordered-input">
    //                 <DatePicker
    //                     name="date"
    //                     id="date"
    //                     placeholder={'Chọn ngày'}
    //                     onChange={(date, dateString) => {
    //                         onChangeInputUpdateData(v.food ? v.food.id : v.id, 'expirationDate', dateString)();
    //                     }}
    //                     defaultValue={moment(v.expirationDate !== '' ? v.expirationDate : new Date(), 'YYYY-MM-DD')}
    //                     format={'YYYY-MM-DD'}
    //                 />
    //             </div>
    //         );
    //     },
    // },
    // {
    //     title: 'Tổng giá',
    //     key: 'index',
    //     render: function renderSubTotal(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.quantity * v.price || 0)} </span>;
    //     },
    // },
    {
        title: '',
        key: 'index',
        render: function renderFoodAction(v: any) {
            return (
                <span onClick={removeOrder(v.id)} className="pointer">
                    <DeleteTwoTone twoToneColor="#eb2f96" />
                </span>
            );
        },
    },
];
export const detailColumns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(v: any) {
            return <span> {v.food ? v.food.name : v.name} </span>;
        },
    },
    {
        title: 'Loại',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span>{v.food ? v.food.foodType.name : v.foodType.name}</span>;
        },
    },
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            return (
                <div >
                    {v.quantity}
                </div>
            );
        },
    },
    {
        title: 'Đơn vị',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            return (
                <div >
                    {v.food ? v.food.foodUnit.name : v.foodUnit.name}
                </div>
            );
        },
    },
];
export const detailColumnsPharmaceutical = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        render: function renderIndex(value: any, item: any, index: any) {
            return ++index;
        },
    },
    {
        title: 'Tên',
        key: 'index',
        render: function renderFoodName(v: any) {
            return <span> {v.food ? v.pharmaceutical.name : v.pharmaceutical.name} </span>;
        },
    },
    {
        title: 'Loại',
        key: 'index',
        render: function renderFoodType(v: any) {
            return <span>{v.food ? v.food.pharmaceutical.pharmaceuticalType.name : v.pharmaceutical.pharmaceuticalType.name}</span>;
        },
    },
    {
        title: 'Số lượng',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            return (
                <div >
                    {v.quantity}
                </div>
            );
        },
    },
    {
        title: 'Đơn vị',
        key: 'index',
        render: function renderFoodUnit(v: any) {
            return (
                <div >
                    {v.food ? v.food.pharmaceutical.pharmaceuticalUnit.name : v.pharmaceutical.pharmaceuticalUnit.name}
                </div>
            );
        },
    },
    // {
    //     title: 'Đơn giá',
    //     key: 'index',
    //     render: function renderFoodPrice(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.price)} </span>;
    //     },
    // },
    // {
    //     title: 'Ngày hết hạn',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderExpirationDate(v: any) {
    //         return <span>{v.expirationDate ? v.expirationDate.split('T')[0] : ''}</span>;
    //     },
    // },
    // {
    //     title: 'Tổng giá',
    //     key: 'index',
    //     width: '20%',
    //     render: function renderSubTotal(v: any) {
    //         return <span> {UtilCollections.VNDCurrencyFormat(v.subTotal)} </span>;
    //     },
    // },
];
