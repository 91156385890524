import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import farmServices from '@services/farm';
import { Breadcrumb, Button, DatePicker, Select, Spin, Table } from 'antd';
import notification from 'antd/lib/notification';
import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './style.report.scss';
import { Context } from '@components/context/context';
const { RangePicker } = DatePicker;
import { CSVLink } from 'react-csv';
import path from 'path';
import userServices from '@services/user';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    startTime: string;
    endTime: string;
    description: string;
    status: string;
    temperatureTag: {
        tagId: number;
        id: string;
    }
    pen: {
        name: string;
        id: string;
    }
    disease: {
        name: string;
        id: string;
    }
    id: string;
}

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'so_thu_tu',
        key: 'STT',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'ten_chuong',
        key: 'ten_chuong',
    },
    {
        title: 'Mã thẻ nhiệt của lợn',
        dataIndex: 'ma_lon',
        key: 'ma_lon',
    },
    {
        title: 'Loại bệnh',
        dataIndex: 'ten_benh_lơn',
        key: 'ten_benh_lơn',
    },
    {
        title: 'Thời gian mắc bệnh',
        dataIndex: 'thoi_gian_mac_benh',
        key: 'thoi_gian_mac_benh',
    },

];


const ReportSickPig = () => {
    const { Option } = Select;

    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const [lstDiease, setLstDiease] = useState<any[]>([]); // Danh sách bệnh
    const farm = farmServices.getCurrentFarm() as any;
    const currentFarm = farmServices.getCurrentFarm() as any;

    const [data, setData] = useState<any[]>([]); // Dữ liệu bệnh lợn
    const [penSelected, setPenSelected] = useState<any>(null); // Chuồng đang được chọn
    const [diseaseSelected, setDiseaseSelected] = useState<any>(null); // Bệnh đang được chọn
    const [startTime, setStartTime] = useState<any>(null); // Thời gian bắt đầu
    const [endTime, setEndTime] = useState<any>(null); // Thời gian kết thúc
    const [dataStatic, setDataStatic] = useState<any[]>([]); // Dữ liệu thống kê
    const [dataExcel, setDataExcel] = useState<any[]>([]); // Dữ liệu xuất excel
    const context = React.useContext(Context);
    const user = userServices.get();


    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // lấy dữ liệu từ api
    const fetchData = async (name: string) => {
        axios.get('https://sit.api.pigman.com.vn/report/sickPig?farmId=' + farm.id + '&size=1000')
            .then((res) => {
                console.log(res.data.data.items);
                setData(res.data.data.items);
            })
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {

        fetchData('');
        loadLstPen();
        loadLstSickPig();
    }, [context.changeLocation.isChangeLocation]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện lấy dữ liệu chuồng
    const loadLstPen = async () => {
        axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${currentFarm.id}?size=1000`)
            .then(res => {
                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    tmp.push({
                        id: item.id,
                        name: item.name
                    })
                })
                setLstPen(tmp);
            })
    }

    // Hàm thực hiện lấy dữ liệu bệnh
    const loadLstSickPig = async () => {
        axios.get(`https://sit.api.pigman.com.vn/disease?offset=0&size=1000`)
            .then(res => {
                if (res.data.statusCode === "OK") {

                    setLstDiease(res.data.data.items);
                }
                // else {
                //     console.log("error");
                //     notification.error({
                //         message: 'Khống có lợn bệnh trong dự liệu',
                //         // description: res.data.message,
                //     });

                // }
            })
    }

    // Hàm thực hiện thay đổi giá trị chuồng
    const handleChangePenValue = (value: any) => {
        setPenSelected(value);
    }

    // Hàm thực hiện thay đổi giá trị bệnh
    const handleChangeDieaseType = (value: any) => {
        setDiseaseSelected(value);
    }

    // Hàm thực hiện thay đổi giá trị ngày
    const handleChangeDate = (value: any) => {
        if (value === null) {
            setStartTime(null);
            setEndTime(null);
            return;
        }
        setStartTime(value[0]._d);
        setEndTime(value[1]._d);
    }


    // Hàm thực hiện khi thay đổi các dữ liệu lọc
    useEffect(() => {
        const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
        const tmpEndTime = moment(endTime).format('YYYY-MM-DD');

        let tmp = ''
        if (penSelected)
            tmp += `&penId=${penSelected}`
        if (diseaseSelected)
            tmp += `&diseaseId=${diseaseSelected}`
        if (startTime)
            tmp += `&startTime=${tmpStartTime}`
        if (endTime)
            tmp += `&endTime=${tmpEndTime}`
        axios.get(`https://sit.api.pigman.com.vn/report/sickPig?farmId=${currentFarm.id}` + tmp + '&size=1000')
            .then(res => {
                setData(res.data.data.items);
            })
    }, [penSelected, diseaseSelected, startTime, endTime])

    // Hàm thực hiện khi thay đổi dữ liệu
    useEffect(() => {
        if (data.length > 0) {
            // Gán dữ liệu lấy được vào biến data
            const tmpData = data.map((item: DataItem, index: number) => {
                const startTime = moment(item.startTime).format('DD/MM/YYYY');
                const endTime = moment(item.endTime).format('DD/MM/YYYY');
                return {
                    so_thu_tu: index + 1,
                    ten_chuong: item.pen?.name || 'Chưa xác định',
                    ma_lon: item.temperatureTag?.tagId || 'Chưa xác định',
                    ten_benh_lơn: item.disease?.name || 'Chưa xác định',
                    thoi_gian_mac_benh: startTime + " - " + endTime,
                }
            });
            // Gán dữ liệu vào biến dataTagPigs
            setDataStatic(tmpData);

            const headerExcel = [
                'STT', 'Tên chuồng', 'Mã thẻ của lợn', 'Loại bệnh', 'Thời gian mắc bệnh'
            ]

            const bodyExcel = data.map((item: DataItem, index: number) => {
                return [
                    index + 1,
                    item.pen?.name || 'Chưa xác định',
                    item.temperatureTag?.tagId || 'Chưa xác định',
                    item.disease?.name || 'Chưa xác định',
                    moment(item.startTime).format('DD/MM/YYYY') + " - " + moment(item.endTime).format('DD/MM/YYYY')]
            });

            setDataExcel([headerExcel, ...bodyExcel]);
        }
        else {
            setDataStatic([]);
        }

    }, [data])



    const exportToExcel = async () => {
        try {
            const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
            const tmpEndTime = moment(endTime).format('YYYY-MM-DD');

            let tmp = ''
            if (penSelected)
                tmp += `&penId=${penSelected}`
            if (diseaseSelected)
                tmp += `&diseaseId=${diseaseSelected}`
            if (startTime)
                tmp += `&startTime=${tmpStartTime}`
            if (endTime)
                tmp += `&endTime=${tmpEndTime}`

            const base64String = await axios.get(`https://sit.api.pigman.com.vn/report/reportFile?userId=${user.id}&farmId=${currentFarm.id}&reportType=sickPig${tmp}&size=1000`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                })

            // use fs to convert the buffer to a file
            console.log(base64String);
            const binaryData = atob(base64String);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                view[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            // Chuyển đổi currentFarm.name từ có dấu thành không dấu
            const str = currentFarm.name;
            const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            saveAs(blob, `Bao cao lon benh_${str1}.xlsx`);


        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            <div className='report-pig-main'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Báo cáo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn bị bệnh</Breadcrumb.Item>

                </Breadcrumb>
                <div className='button-report-pig'>
                    <div className='search-report-pig' style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                        <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} onChange={handleChangeDate} />
                        <Select
                            className='select-report-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={handleChangeDieaseType}
                            placeholder="Loại bệnh"
                        >
                            {lstDiease?.map((item, index) => (
                                <Option key={index} value={item.id} title={item.name}>{item.name}</Option>
                            ))}
                        </Select>
                        <Select
                            className='select-report-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={handleChangePenValue}
                            placeholder="Chọn tên chuồng"
                        >
                            {lstPen?.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-report-pig'>
                        <Table
                            dataSource={dataStatic}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                    </div>
                </Spin>
                <div className='button-export'>
                    {/* <CSVLink data={dataExcel} headers={['Danh sách lợn bệnh']} filename={'báo cáo lợn bệnh.csv'}>
                        <Button type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </CSVLink> */}
                    <Button disabled={(dataStatic && dataStatic.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                </div>
            </div >
        </>
    )
}

export default ReportSickPig