import { DeleteOutlined, EditOutlined, FlagOutlined } from '@ant-design/icons';
import { Breadcrumb, Form, Select, Space, Spin, Table, Tooltip, DatePicker, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import notification from 'antd/lib/notification';
import { SliderMarks } from 'antd/lib/slider';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './style.report.scss';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
import { saveAs } from 'file-saver';
import farmServices from '@services/farm';
import moment from 'moment';
import { Context } from '@components/context/context';
import { CSVLink } from 'react-csv';
import userServices from '@services/user';

// Kiểu dữ liệu của bệnh lợn
interface DataItem {
    runningRate: number,
    standingRate: number,
    liedownRate: number,
    eatingRate: number,
    pigAIId: string,
    startTime: string,
    endTime: string,
    pen: {
        name: string,
        id: string
    },
    id: string
}

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'so_thu_tu',
        key: 'so_thu_tu',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'ten_chuong',
        key: 'ten_chuong',
    },
    {
        title: 'ID của lợn',
        dataIndex: 'ma_lon',
        key: 'ma_lon',
    },
    {
        title: 'Thời gian bất thường',
        dataIndex: 'thoi_gian_bat_thuong',
        key: 'thoi_gian_bat_thuong',
    },
    {
        title: 'Biểu hiện bất thường',
        dataIndex: 'bieu_hien_bat_thuong',
        key: 'bieu_hien_bat_thuong',
    },

];


// Dữ liệu chuồng bệnh
const pens = [
    {
        value: '1',
        label: 'Chuồng 1'
    },
    {
        value: '2',
        label: 'Chuồng 2'
    },
    {
        value: '3',
        label: 'Chuồng 3'
    },
    {
        value: '4',
        label: 'Chuồng 4'
    }
]
const ReportUnusualPig = () => {
    const { Option } = Select;

    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const farm = farmServices.getCurrentFarm() as any;

    const [lstPen, setLstPen] = useState<any[]>([]); // Danh sách chuồng
    const [penSelected, setPenSelected] = useState<any>(null); // Chuồng đang được chọn
    const [startTime, setStartTime] = useState<any>(null); // Thời gian bắt đầu
    const [endTime, setEndTime] = useState<any>(null); // Thời gian kết thúc
    const [data, setData] = useState<DataItem[]>([]); // Dữ liệu bệnh lợn
    const [dataUnusual, setDataUnusual] = useState<any[]>([]); // Dữ liệu bệnh lợn
    const context = React.useContext(Context);
    const [dataExcel, setDataExcel] = useState<any[]>([]); // Dữ liệu xuất excel
    const user = userServices.get();
    const currentFarm = farmServices.getCurrentFarm() as any;

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // lấy dữ liệu từ api
    const fetchData = async () => {
        setLoading(true);
        await axios.get(`https://sit.api.pigman.com.vn/report/behaviour?farmId=` + farm.id + '&size=10000')
            .then(res => {
                console.log(res.data.data.items);
                setData(res.data.data.items);
            })
    };

    // Hàm thực hiện lấy dữ liệu lần đầu khi trang được load
    useEffect(() => {
        fetchData();
        loadLstPen();
    }, [context.changeLocation.isChangeLocation]);

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: data.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };




    // Hàm thực hiện lấy dữ liệu chuồng
    const loadLstPen = async () => {
        axios.get(`https://sit.api.pigman.com.vn/pens/allPens/${farm.id}?size=1000`)
            .then(res => {
                const tmp: { id: any; name: any; }[] = []
                console.log(res);
                res.data.data.items.forEach((item: any) => {
                    tmp.push({
                        id: item.id,
                        name: item.name
                    })
                })
                setLstPen(tmp);
            })
    }

    // Hàm thực hiện thay đổi giá trị chuồng
    const handleChangePenValue = (value: any) => {
        setPenSelected(value);
    }

    // Hàm thực hiện thay đổi giá trị ngày
    const handleChangeDate = (value: any) => {
        if (value === null) {
            setStartTime(null);
            setEndTime(null);
            return;
        }
        setStartTime(value[0]._d);
        setEndTime(value[1]._d);
    }



    const exportToExcel = async () => {
        try {
            const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
            const tmpEndTime = moment(endTime).format('YYYY-MM-DD');
            let tmp = ''
            if (penSelected)
                tmp += `&penId=${penSelected}`
            if (startTime)
                tmp += `&startTime=${tmpStartTime}`
            if (endTime)
                tmp += `&endTime=${tmpEndTime}`

            const base64String = await axios.get(`https://sit.api.pigman.com.vn/report/reportFile?userId=${user.id}&farmId=${farm.id}&reportType=behaviour${tmp}&size=1000`)
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                })

            // use fs to convert the buffer to a file
            console.log(base64String);
            const binaryData = atob(base64String);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                view[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

            // Chuyển đổi currentFarm.name từ có dấu thành không dấu
            const str = currentFarm.name;
            const str1 = str.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

            saveAs(blob, `Bao cao lon bat thuong_${str1}.xlsx`);
        } catch (error) {
            console.log(error);
        }
    };

    // Hàm thực hiện khi thay đổi các dữ liệu lọc
    useEffect(() => {
        const tmpStartTime = moment(startTime).format('YYYY-MM-DD');
        const tmpEndTime = moment(endTime).format('YYYY-MM-DD');

        let tmp = ''
        if (penSelected)
            tmp += `&penId=${penSelected}`
        if (startTime)
            tmp += `&startTime=${tmpStartTime}`
        if (endTime)
            tmp += `&endTime=${tmpEndTime}`
        axios.get(`https://sit.api.pigman.com.vn/report/behaviour?farmId=${farm.id}` + tmp + '&size=1000')
            .then(res => {
                setData(res.data.data.items);
            })
    }, [penSelected, startTime, endTime])
    // Hàm thực hiện khi thay đổi dữ liệu
    useEffect(() => {
        if (data.length > 0) {
            // Gán dữ liệu lấy được vào biến data
            const tmpData = data.map((item: DataItem, index: number) => {
                const startTimes = new Date(item.startTime).setHours(new Date(item.startTime).getHours() - 7)
                // Chuyển start time về dạng ngày tháng năm giờ phút giây
                const startTime = moment(startTimes).format('DD/MM/YYYY HH:mm:ss');
                const endTimes = new Date(item.endTime).setHours(new Date(item.endTime).getHours() - 7)
                // Chuyển end time về dạng ngày tháng năm giờ phút giây
                const endTime = moment(endTimes).format('DD/MM/YYYY HH:mm:ss');
                const percentRunning = Math.round(item.runningRate);
                const percentStanding = Math.round(item.standingRate);
                const percenLiedown = Math.round(item.liedownRate);
                const percentEating = Math.round(item.eatingRate);

                return {
                    so_thu_tu: index + 1,
                    ten_chuong: item.pen?.name || 'Chưa xác định',
                    ma_lon: item.pigAIId,
                    thoi_gian_bat_thuong: startTime + " - " + endTime,
                    bieu_hien_bat_thuong:
                        <div>
                            Đi: <b>{percentRunning}%</b> Đứng: <b> {percentStanding}%</b> Nằm: <b> {percenLiedown}%</b> Ăn: <b> {percentEating}%</b>
                        </div>,
                }
            });
            // Gán dữ liệu vào biến dataTagPigs
            setDataUnusual(tmpData);
            setLoading(false);
            const headerExcel = [
                'STT', 'Tên chuồng', 'Mã lợn của lợn', 'Thời gian bất thường', 'Biểu hiện bất thường'
            ]

            const bodyExcel = data.map((item: DataItem, index: number) => {
                return [
                    index + 1,
                    item.pen?.name || 'Chưa xác định',
                    item.pigAIId,
                    moment(item.startTime).format('DD/MM/YYYY') + " - " + moment(item.endTime).format('DD/MM/YYYY'),
                    'Đi: ' + Math.round(item.runningRate) + '% Đứng: ' + Math.round(item.standingRate) + '% Nằm: ' + Math.round(item.liedownRate) + '% Ăn: ' + Math.round(item.eatingRate) + '%',
                ]
            });

            setDataExcel([headerExcel, ...bodyExcel]);
        }
        else {
            setDataUnusual([]);
            setLoading(false);
        }

    }, [data])

    return (
        <>
            <div className='report-pig-main unusual-pig'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        Báo cáo
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn bất thường</Breadcrumb.Item>
                    <Breadcrumb.Item>Danh sách lợn bất thường hành vi</Breadcrumb.Item>
                </Breadcrumb>
                <div className='button-report-pig'>
                    {/* <Search placeholder="Tìm kiếm bệnh lợn theo tên" onSearch={onSearch} enterButton allowClear /> */}
                    {/* <div className='number-report-pig'>Số con: {data.length}</div> */}
                    <div className='search-report-pig'>
                        <RangePicker placeholder={['Ngày bắt đầu', 'Ngày kết thúc']} onChange={handleChangeDate} />
                        <Select
                            className='select-report-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={handleChangePenValue}
                            placeholder="Chọn tên chuồng"
                        >
                            {lstPen?.map((item, index) => (
                                <Option key={index} value={item.id}>{item.name}</Option>
                            ))}
                        </Select>
                        {/* <Button >Tìm kiếm</Button> */}
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className='table-report-pig'>
                        <Table
                            dataSource={dataUnusual}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}

                        />
                    </div>
                </Spin>
                <div className='button-export'>
                    {/* <CSVLink data={dataExcel} headers={['Danh sách lợn bất thường']} filename={'báo cáo lợn bất thường.csv'}>
                        <Button type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>
                    </CSVLink> */}
                    <Button disabled={(dataUnusual && dataUnusual.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined />}> Xuất báo cáo</Button>

                </div>
            </div>
        </>
    )
}

export default ReportUnusualPig