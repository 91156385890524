import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import './style.farmList.scss';
import { Button, Select, Spin, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import { async } from 'rxjs';
import { set } from 'date-fns';
import * as XLSX from 'xlsx';

// Các cột của bảng
const columns = [
    {
        title: 'STT',
        dataIndex: 'key',
    },
    {
        title: 'Tên trang trại',
        dataIndex: 'farmName',
    },
    {
        title: 'Tên chuồng',
        dataIndex: 'penName',
    },
    {
        title: 'Thời gian',
        dataIndex: 'createdDate',
    },
    {
        title: 'Tag',
        dataIndex: 'tagName',
    },
    {
        title: 'ID',
        dataIndex: 'tagId',
    },
    {
        title: 'Nhà sản xuất',
        dataIndex: 'manufacturer',
    },
    {
        title: 'Pin (V)',
        dataIndex: 'battery',
    },
    {
        title: 'Nhiệt độ (°C)',
        dataIndex: 'temperature',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
    }

];
const Option = Select.Option;
const padTo2Digits = (num: any) => {
    return num.toString().padStart(2, '0');
}

const formatDate = (date: any) => {
    return (
        [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}
const TemperatureStatusList: FC = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [filter, setFilter] = useState(''); // dùng để tìm kiếm
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [farmList, setFarmList] = useState([]);
    const [dropdownOptions, setDropdownOptions] = useState([{ value: '', label: 'Tất cả trang trại' }]);
    const [farmId, setFarmId] = useState<any>(null);
    const [lstFarm, setLstFarm] = useState<any>([]);
    const [loadingButton, setLoadingButton] = useState(false);
    // routers/route-names.tsx có: profile_farmList: '/profile/quan-ly-trang-trai/:userID'
    // Phần sau dấu hai chấm là tên biến (có thể thay đổi được), ở đây được đặt là "userID",
    // thế nên useParams() trả về một object có field "userID" với value là ID của user
    const id: any = useParams();
    useEffect(() => {
        getAllFarm();
        setLoading(true);
        fetchData(1);
    }, [farmId]);

    const covertTime = (time: any) => {
        const dateObject = new Date(time);
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const hours = dateObject.getHours().toString().padStart(2, '0');
        const minutes = dateObject.getMinutes().toString().padStart(2, '0');
        const seconds = dateObject.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
    }
    const fetchData = (page: any) => {
        let url = ''
        if (farmId) {
            url = `https://sit.api.pigman.com.vn/temperatureStatus/all?size=${pageSize}&offset=${pageSize * (page - 1)}&farmId=${farmId}`
        }
        else {
            url = `https://sit.api.pigman.com.vn/temperatureStatus/all?size=${pageSize}&offset=${pageSize * (page - 1)}`
        }
        axios
            .get(url)
            .then((response) => {
                console.log(response.data.data);
                let count = pageSize * (page - 1);
                setTotalRecords(response.data.data[1].total)
                setData(
                    response.data.data[0].items.map((obj: any) => {
                        count += 1;
                        const tmptimestamp = formatDate(new Date(obj.timestamp));

                        return {
                            key: count, // STT (cột đầu tiên)
                            farmName: obj.farmName || 'Không xác định', // Tên trang trại 
                            penName: obj.penName || 'Không xác định',
                            temperature: obj.temperature || '',
                            createdDate: obj.timestamp ? tmptimestamp : 'Không xác định',
                            status: obj.status || '',
                            battery: obj.temperatureTag?.batteryLevel?.toFixed(2) || 'Không xác định',
                            manufacturer: obj.temperatureTag?.manufacturer || 'Không xác định',
                            tagId: obj.temperatureTag?.tagId || 'Không xác định',
                            tagName: obj.temperatureTag?.name || 'Không xác định',
                        };
                    }),
                );
            })
            .then(() => {
                setLoading(false);
            })
            // catch lỗi khi người dùng nhấn "quản lý trang trại" thẳng luôn vì khi đó URL sẽ là "/profile/quan-ly-trang-trai/:userID", không có ID của user
            .catch(() => setData([]));
    }


    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);

        // Gán giá trị trang hiện tại và số bản ghi trên một trang ngay lập tức
        setCurrentPage(page);
        pageSize && setPageSize(pageSize);

        // Đẩy lên đầu trang
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
            setLoading(false);
            fetchData(page);
        }, 1000);
    };

    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: totalRecords,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: unknown, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    const onSearch = () => {
        console.log('searching');
    };
    const getAllFarm = async () => {

        await axios.get(`https://sit.api.pigman.com.vn/admin/getAllFarm/getAllFarmFromAdmin`)
            .then((response) => {
                console.log(response.data.data);
                setLstFarm(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    // Sort lại data theo filter (tên trang trại) với dù filter có là chữ hoa hay chữ thường
    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.farmName.toLowerCase().includes(filter.toLowerCase()));
    const handleChangeFarmList = (value: any) => {
        setFarmId(value);
    }
    const exportToExcel = async () => {
        setLoadingButton(true);
        await axios.get(`https://sit.api.pigman.com.vn/temperatureStatus/all?size=20000`)
            .then((response) => {
                console.log(response.data.data[0].items);
                let count = 0;
                setTotalRecords(response.data.data[1].total)
                const data = response.data.data[0].items.map((obj: any) => {
                    count += 1;
                    return {
                        key: count, // STT (cột đầu tiên)
                        farmName: obj.farmName || 'Không xác định', // Tên trang trại 
                        penName: obj.penName || 'Không xác định',
                        temperature: obj.temperature || '',
                        createdDate: obj.timestamp ? moment(new Date(obj.timestamp)).format('HH:MM:SS DD/MM/YYYY') : 'Không xác định',
                        status: obj.status || '',
                        battery: obj.temperatureTag.batteryLevel.toFixed(2) || '',
                        manufacturer: obj.temperatureTag.manufacturer || '',
                        tagId: obj.temperatureTag.tagId || '',
                    };
                });
                // Map over sortData to create a new array with the desired column names
                const dataWithVietnameseKeys = data.map((item: any) => ({
                    'STT': item.key,
                    'Tên trang trại': item.farmName,
                    'Tên chuồng': item.penName,
                    'Thời gian': item.createdDate,
                    'ID thẻ': item.tagId,
                    'Nhà sản xuất': item.manufacturer,
                    'Pin (V)': item.battery,
                    'Nhiệt độ (°C)': item.temperature,
                    'Trạng thái': item.status,
                }));

                const ws = XLSX.utils.json_to_sheet(dataWithVietnameseKeys);
                ws['!cols'] = [
                    { width: 10 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },
                    { width: 25 },

                ];
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                XLSX.writeFile(wb, "Danh sach than nhiet cua Lon.xlsx");
                setLoadingButton(false);
            })

    };
    return (
        <>
            <div className="farmList-body">
                <div className="button-farmList">
                    <p className="farmList-h2">Bản ghi thân nhiệt</p>
                    <div className="search-and-sort">
                        {/* <Search placeholder="Tìm kiếm theo trang trại" onSearch={onSearch}
                            onChange={(event) => setFilter(event.target.value)}
                            enterButton
                            allowClear
                        /> */}
                        <Select
                            className='select-behaviour-pig'
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input: any, option: any) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            suffixIcon={<CaretDownOutlined rev={undefined} />}
                            onChange={handleChangeFarmList}
                            placeholder="Lọc theo trang trại"
                        >
                            <Option key={0} value={''} title={'Tất cả trang trại'}>{'Tất cả trang trại'}</Option>
                            {lstFarm?.map((item: any, index: any) => (
                                <Option key={index} value={item.id} title={item.name}>{item.name}</Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                    <div className="table-farmList">
                        <Table
                            dataSource={sortData}
                            columns={columns}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                        />
                        <div className='button-excel'>
                            <Button loading={loadingButton} disabled={(sortData && sortData.length > 0) ? false : true} onClick={exportToExcel} type="primary" icon={<DownloadOutlined rev={undefined} />}> Xuất báo cáo</Button>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default TemperatureStatusList;
